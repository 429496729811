import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getData, postData } from "src/webService/webService";
import Iconify from "../iconify";
import CLIENTAPI from "src/API_Routes/ClientAPI/ClientAPI";
import InwardAndOutward from "../InwardAndOutward/inwardandoutward";
import STOCKREPORTAPI from "../../API_Routes/StockReportAPI/StockReportAPI";
import useAllClients from "../CommonFunction/GetallClients";
import jsPDF from "jspdf";
import StockReportPdf from "../templates/stockReportspdf";
import stockReportLogo from "../logo/inventory.png";

const StockReportTable = () => {
  const accessToken = sessionStorage.getItem("Token");
  const [searchTerm, setSearchTerm] = useState("");

  const [limit, setLimit] = useState(5);
  const [TotalItems, setTotalItems] = useState();
  const [companydetails, setCompanyDetails] = useState();
  const [clientId, setClientId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [listInwardOutward, setListInwardOutward] = useState(false);

  const [partName, setPartName] = useState("");
  const [partNo, setPartNo] = useState("");
  const [process, setProcess] = useState("");

  const [stockreportTable, setStockReportTable] = useState();

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const AllClientsList = async (page) => {
    if (startDate && !endDate) {
      toast.error("End date is required when start date is selected");
      return;
    }
    setReset(false);
    const params = {
      page: page,
      limit: limit,
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      companyName: companyName ? companyName : "",
      branch: branch || "",
      process: process ? process : "",
      partName: partName ? partName : "",
      partNo: partNo ? partNo : "",
    };
    try {
      const res = await getData(
        STOCKREPORTAPI.ALLSTOCKREPORTAPI,
        params,
        accessToken
      );
      if (res.status === 200) {
        setStockReportTable(res?.data?.[0].paginatedResults);
        setTotalPages(res?.data?.[0]?.totalPages);
        if (res?.data?.[0]?.totalCount) {
          setTotalItems(res?.data?.[0]?.totalCount);
        } else {
          setTotalItems(0);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {});
    }
  };

  useEffect(() => {
    AllClientsList(currentPage);
  }, [currentPage, limit]);

  const HandleGetClientId = (clientId) => {
    console.log("Client Id", clientId);
    setClientId(clientId);
  };

  const [specialClientsForPDF, setSpecialClientsForPDF] = useState();
  const [branch, setBranch] = useState();

  const ClientList = async (clientId) => {
    console.log("Client Id", clientId);
    try {
      // const res = await getData(
      //   `clients/client/${clientId}`,
      //   null,
      //   accessToken
      // );
      const body = {
        clientid: clientId,
        startDate: startDate ? startDate : "",
        endDate: endDate ? `${endDate}T23:59:59.999Z` : "",
        companyName: companyName ? companyName : "",
        branch: branch || "",
        process: process ? process : "",
        partName: partName ? partName : "",
        partNo: partNo ? partNo : "",
      };
      console.log(body, "body");
      const res = await postData(
        `clients/StockReport/client`,
        body,
        accessToken
      );

      if (res.status === 200) {
        if (
          startDate ||
          endDate ||
          companyName ||
          selectedcompanyDetails?.branch ||
          process ||
          partName ||
          partNo !== ""
        ) {
          setSpecialClients([res.data.StockReport]);
          setSpecialClientsForPDF(res.data.StockReport)
        } else {

          setSpecialClients(res.data.StockObj);
        }

        // setSpecialClients(res.data.StockObj);
        const selectclientdata = stockreportTable
          .flat()
          .find((item) => item._id === clientId);
        console.log(selectclientdata, "selectclientdata");
        setCompanyDetails(selectclientdata);
        setTotalPages(res.data);
        console.log(res.data);
        console.log(specialclients, "specialclients");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An Error occured. Please Try ");
    }
  };



  const handleStockReport = () => {
    setListInwardOutward(!listInwardOutward);
  };

  useEffect(() => {
    if (!listInwardOutward) {
      AllClientsList(currentPage);
    }
  }, [listInwardOutward]);

  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  console.log(stockreportTable, "stockreportTable");

  //For Search Filter
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reset, setReset] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [company, setCompany] = useState();
  const [selectedcompanyDetails, setselectedCompanyDetails] = useState();
  const [specialclients, setSpecialClients] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  console.log(companyName, "companyName");

  //For Search Filter

  //   search Filter
  const { clientDetails, totalItems, fetchAllClients } = useAllClients();
  useEffect(() => {
    if (showFilter) {
      fetchAllClients();
    }
  }, [showFilter]);

  const removeData = () => {
    setStartDate();
    setEndDate();
    setCompanyName();
    setCompany();
    setReset(true);
    setPartName("");
    setPartNo("");
    setProcess("");
    setBranch("")
  };

  // Function to handle company selection
  // const handleCompanyChanges = (event) => {
  //   const companyName = event.target.value;
  //   const company = clientDetails?.find((c) => c._id === companyName);
  //   setselectedCompanyDetails(company);
  //   setCompanyName(company.companyname);
  // };

  const handleCompanyChanges = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompany = clientDetails?.find((company) => company._id === selectedCompanyId);
    setselectedCompanyDetails(selectedCompany);
    setCompanyName(selectedCompany.companyname);
    setCompany(selectedCompany._id)
    setBranch(selectedCompany?.branch)
  };


  const handleSearch = () => {
    setCurrentPage(1);
    AllClientsList(1);
  };

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
      AllClientsList(1);
    }
  }, [reset]);

  const handleSelectionChange = (event) => {
    const selectedPartName = event.target.value;
    const selectedProduct = selectedcompanyDetails.product.find(
      (product) => product.partName === selectedPartName
    );

    if (selectedProduct) {
      setPartName(selectedPartName);
      setPartNo(selectedProduct.partNo);
      setProcess(selectedProduct.productName);
    }
  };

  console.log(selectedcompanyDetails, "selectedcompanyDetails");
  const isButtonDisabled =
    !partName || !partNo || !process || !companyName || !startDate || !endDate;

  const [openDialog, setOpenDialog] = useState(null);
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handlePrint = () => {
    const printContent = reportTemplateRef.current;
    const originalContent = document.body.innerHTML;

    // Set the document's body to only include the printContent
    document.body.innerHTML = printContent.innerHTML;

    // Trigger the print dialog
    window.print();

    // Restore the original document content
    document.body.innerHTML = originalContent;

    // Reattach the React component to the DOM
    window.location.reload();
  };

  const reportTemplateRef = useRef(null);
  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "landscape",
    });
    doc.setFont("sans-serif", "normal");
    const content = reportTemplateRef.current;
    const scale = 0.8;
    doc.html(content, {
      html2canvas: {
        scale: scale,
        width: 695,
      },
      callback: function (doc) {
        doc.save("StockReport.pdf");
      },
      x: 15,
      y: 15,
      width: 1075,
    });
  };

  return (
    <Paper className="card-body">
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Stock Report for {companyName ? companyName : ""}
        </DialogTitle>
        <DialogContent>
          <div ref={reportTemplateRef} style={{ width: "81%" }}>
            <StockReportPdf
              companyName={companyName}
              partName={partName}
              partNo={partNo}
              process={process}
              startDate={startDate}
              endDate={endDate}
              specialclients={specialClientsForPDF}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            className="ml-8 btn-secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            size="small"
            className="ml-8 btn-primary"
            onClick={() => {
              handlePrint();
            }}
            variant="contained"
          >
            Print
          </Button>

          {/* <Button
            size="small"
            className="ml-8 btn-primary"
            onClick={() => {
              handleGeneratePdf();
              handleClose();
            }}
            variant="contained"
          >
            Generate PDF
          </Button> */}
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" />
      {listInwardOutward ? (
        <>
          <InwardAndOutward
            handleStockReport={handleStockReport}
            clientId={clientId}
            companydetails={companydetails}
            specialclients={specialclients}
          />
        </>
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between align-items-center pt-3 pb-4">
            <div className="d-flex">
              <img
                src={stockReportLogo}
                width="4%"
                className="mr-2"
                alt="stock-icon"
              />
              <p className="fw-bold mb-0 color-dark mt-1">
                Total Stock Reports : {TotalItems}
              </p>
            </div>
            <div>
              {showFilter && (
                <span
                  className="me-2 danger"
                  style={{ color: "red", fontSize: "13px", cursor: "pointer" }}
                  onClick={(e) => {
                    toggleFilter();
                    removeData();
                  }}
                >
                  Clear Filter
                </span>
              )}
              <IconButton
                className="p-12 ftrbkgd rounded"
                onClick={(e) => {
                  toggleFilter();
                  removeData();
                }}
              >
                {showFilter ? (
                  <Iconify icon="ri:filter-off-fill" />
                ) : (
                  <Iconify icon="material-symbols:filter-alt-sharp" />
                )}
              </IconButton>
            </div>
          </div>

          {showFilter && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                <div style={{ minWidth: 500, margin: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        className="w-100"
                        type="date"
                        label="Start Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        className="w-100"
                        type="date"
                        label="End Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: startDate,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>

                <Grid item xs={3}>
                  <FormControl
                    sx={{ margin: 1, width: "250px" }}
                    size="large"
                    className="select"
                  >
                    <InputLabel id="company-name-label">
                      Company Name
                    </InputLabel>
                    <Select
                      labelId="company-name-label"
                      id="company-name-select"
                      label="Company Name"
                      value={company}
                      onChange={handleCompanyChanges}
                    >
                      {clientDetails &&
                        clientDetails.map((company) => (
                          <MenuItem key={company._id} value={company._id}>
                            {company.companyname}
                          </MenuItem>
                        ))}
                    </Select>

                  </FormControl>
                </Grid>
                <Grid>
                  <FormControl
                    sx={{ width: "280px" }}
                    style={{ marginRight: "4px" }}
                    size="large"
                    className="select"
                  >
                    <InputLabel id="product-details">
                      Part Name / Part No / Process
                    </InputLabel>
                    <Select
                      labelId="product-details"
                      id="product-details"
                      label="Part Name / Part No / Process"
                      fullWidth
                      variant="outlined"
                      name="partName"
                      className="w-100"
                      value={partName}
                      onChange={handleSelectionChange}
                    >
                      {selectedcompanyDetails?.product?.map((product, i) => (
                        <MenuItem key={i} value={product?.partName}>
                          {product?.partName} / {product?.partNo} /{" "}
                          {product?.productName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <div>
                  <Button
                    style={{ padding: "14px 40px !important" }}
                    variant="outlined"
                    className="ml-4 btn-secondary"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </>
          )}
          <TableContainer style={{ height: "366px", overflow: "scroll" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table-cl color-dark">S.No</TableCell>
                  <TableCell className="table-cl color-dark">
                    Company Name
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Email ID
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    MobileNumber
                  </TableCell>
                  <TableCell className="table-cl color-dark">GST No</TableCell>
                  <TableCell className="table-cl color-dark" align="center">
                    Download Stock Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockreportTable &&
                  stockreportTable.map((row, index) => (
                    <TableRow
                      key={index}
                      className="padding-8"
                      style={{
                        transition: "background-color 0.3s",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f5f5f5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "inherit")
                      }
                    >
                      <TableCell
                        onClick={() => {
                          HandleGetClientId(row?._id);
                          // handleStockReport();
                          // ClientList(row?._id);
                        }}
                      >
                        {calculateIndex(index)}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          HandleGetClientId(row?._id);
                          // handleStockReport();
                          // ClientList(row?._id);
                        }}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                      >
                        {row?.companyname}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          HandleGetClientId(row?._id);
                          // handleStockReport();
                          // ClientList(row?._id);
                        }}
                      >
                        {row?.email || "-"}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          HandleGetClientId(row?._id);
                          // handleStockReport();
                          // ClientList(row?._id);
                        }}
                      >
                        {row?.phoneNumber || "-"}
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          HandleGetClientId(row?.[0]?._id);
                          // handleStockReport();
                          // ClientList(row?._id);
                        }}
                      >
                        {row?.gstNo}
                      </TableCell>
                      {/* <TableCell align="center">
                        <IconButton size="large" color="inherit">
                          <Iconify
                            icon={"material-symbols-light:download-sharp"}
                          />
                        </IconButton>
                      </TableCell> */}
                      <TableCell align="center">
                        <Tooltip
                          title={
                            isButtonDisabled
                              ? "Please select the  Start Date,End Date ,Part Name and Company Name in the filter"
                              : ""
                          }
                          placement="top"
                        >
                          <span>
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => {
                                ClientList(row?._id);
                                handleOpen();
                              }}
                              disabled={isButtonDisabled}
                            >
                              <Iconify icon="material-symbols-light:download-sharp" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}

                {!stockreportTable && (
                  <TableRow>
                    <TableCell colSpan={7} className="text-center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "5%" }}
            >
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={TotalItems}>All</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
};
export default StockReportTable;
