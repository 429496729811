import React from 'react';

const Invoice = (data) => {
    console.log(data.data, "data");

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = `${dd}/${mm}/${yyyy}`;


    return (
        <div>
            <style>
                {`
                table, th, td {
                    border: 0.3px solid #272727;
                    border-collapse: collapse;
                    padding: 6px !important;
                    font-size: 8px !important;
                }
                th{
                    text-align: center
                }
                .f-14{
                    font-size: 14px !important;
                }
                .bt {
                    border-top: none !important;
                }
                .bb {
                    border-bottom: none !important;
                }
                .bl {
                    border-left: none !important;     
                }
                .br {
                    border-right: none !important;
                }
                .bold{
                    font-weight: 700;
                }
                .w-20{
                    width: 20%;
                }
                .w-10{
                    width: 10%;
                }
                `}
            </style>
            <div style={{ width: "100%" }}>
                <h1 style={{ fontSize: '16px', textAlign: 'center' }}>TAX INVOICE</h1>

                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td rowSpan="4" style={{ width: '50.5%' }} className='text-start'>
                                <strong>EXPORTER</strong><br />
                                <strong>ULTRA SURFACE FINISHERS</strong>
                                <br />
                                NO:3, WELFARE SCHOOL STREET,
                                <br />
                                AYANAMBAKKAM,
                                <br />
                                CHENNAI - 600095
                                <br />
                                PH: 9884565830,9884043474
                                <br />
                                E-MAIL: usfchennai@gmail.com
                                <br />
                                GSTIN: 33AJWPP0888Q1Z9
                            </td>
                            <td>Invoice No<br /> <strong>{data.data?.[0]?.invoiceNo}</strong></td>
                            <td>Dated <br /> <strong>{data?.data?.[0]?.date.split("T")[0]}</strong></td>
                        </tr>
                        <tr>
                            <td>Delivery Note</td>
                            <td>Mode/Terms of Payment</td>
                        </tr>
                        <tr>
                            <td>Supplier's Ref</td>
                            <td>Other Reference(s)</td>
                        </tr>
                        <tr>
                            <td>Order No. {data.data?.[0]?.orderNo || ""}</td>
                            <td>Dated</td>
                        </tr>

                        <tr>
                            <td rowSpan="3" className='text-start'>
                                <strong>CONSIGNEE:</strong><br />
                                <strong>{data.data?.[0]?.companyName}</strong>
                                <br />
                                {data.data?.[0]?.doorNo}
                                <br />
                                {data.data?.[0]?.streetName}
                                <br />
                                {data.data?.[0]?.city} - {data.data?.[0]?.pinCode}
                                <br />
                                GST No. {data.data?.[0]?.gstNo}
                                <br />
                            </td>
                            <td>Despatch Doc No.</td>
                            <td>Delivery Note Date</td>
                        </tr>
                        <tr>
                            <td>Terms of Payment</td>
                            <td>Destination</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Terms of Delivery</td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ width: '100%', marginTop: '12px' }}>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>USF DC NO.</th>
                            <th>DATE</th>
                            <th>COMPONENTS</th>
                            <th>CUSTOMER DC NO.</th>
                            <th>QTY (NO.S/KGS)</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data?.[0]?.invoiceData?.map((data, index) => (
                            <tr>
                                <td className='text-center bb bt'>{index + 1}</td>
                                <td className='text-center bb bt'>{data?.usfDCNo || ''}</td>
                                <td className='text-center bb bt'>{data?.usfDcDate?.split('T')[0] || ''}</td>
                                <td className='bb bt' style={{ width: "20%", whiteSpace: "pre-line" }} >{data?.partName || ''} - {data?.partNo || ''} - {data?.description || ''}</td>
                                <td className='text-center bb bt'>{data?.customerDCNo || ''}</td>
                                <td className='text-center bb bt'>{data?.invoiceQuantity || ''}</td>
                                <td className='text-center bb bt'>{data?.rate || ''}</td>
                                <td className='text-end bb bt'>{data?.amount || ''}</td>
                            </tr>
                        ))}

                        <tr style={{height: "100px"}}>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='text-center bb bt'><strong>{data.data?.[0]?.totalQuantity}</strong></td>
                            <td className='bb bt'></td>
                            <td className='text-end bb bt'><strong>{data.data?.[0]?.totalAmount}</strong></td>
                        </tr>
                        <tr>
                            <td className='bb bt'></td>
                            <td className='text-end bb bt'>CGST Outward 6%</td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='text-end bb bt'>{data.data?.[0]?.cgst}</td>
                        </tr>
                        <tr>
                            <td className='bb bt'></td>
                            <td className='text-end bb bt'>SGST Outward 6%</td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='bb bt'></td>
                            <td className='text-end bb bt'>{data.data?.[0]?.sgst}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='text-end'><strong>Total</strong></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><strong>₹. {data.data?.[0]?.grandTotal}</strong></td>
                        </tr>
                        <tr>
                            <td colSpan="8">
                                Tax Amount (in words):
                                <br />
                                <strong>{data.data?.[0]?.grandTotalInWords?.toUpperCase() || ""} ONLY</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ width: '100%', marginTop: '2px' }}>
                    <thead>
                        <tr>
                            <th className="bt" rowSpan="2" >HSN/SAC</th>
                            <th className="bt" rowSpan="2">Taxable value</th>
                            <th className="bt" colSpan="2">Central Tax</th>
                            <th className="bt" colSpan="2">State Tax</th>
                            <th className="bt" rowSpan="2">Total Tax Amount</th>
                        </tr>
                        <tr>
                            <th className="bt">Rate</th>
                            <th className="bt">Amount</th>
                            <th className="bt">Rate</th>
                            <th className="bt">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data?.[0]?.invoiceData?.map((data, index) => (
                            <tr key={index}>
                                <td className='text-center bb bt'>998898</td>
                                <td className='text-center bb bt'>{data?.amount || ''}</td>
                                <td className='text-center bb bt'>6%</td>
                                <td className='text-center bb bt'>{data?.cgst}</td>
                                <td className='text-center bb bt'>6%</td>
                                <td className='text-center bb bt'>{data?.sgst}</td>
                                <td className='text-end bb bt'>{data?.totalgst}</td>
                            </tr>
                        ))}
                        <tr>
                            <th>Total</th>
                            <td className='text-center'><strong>{data.data?.[0]?.totalAmount}</strong></td>
                            <td></td>
                            <td className='text-center'><strong>{data.data?.[0]?.cgst}</strong></td>
                            <td></td>
                            <td className='text-center'><strong>{data.data?.[0]?.sgst}</strong></td>
                            <td className='text-end'><strong>{data.data?.[0]?.grandTotalGst}</strong></td>
                        </tr>

                        <tr>
                            <td colSpan="7" className='bb'>
                                Tax Amount (in words):
                                <strong style={{marginLeft: "6px"}}>{data.data?.[0]?.grandTotalGstWords?.toUpperCase()} ONLY</strong>
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="3" style={{ width: '50%' }} className='bt br bb'>
                                <strong>Company's GST No.</strong> : 33AJWPP0888Q1Z9
                            </td>
                            <td colSpan="4" className='bt bl'>
                                <strong>Company's Bank Details</strong>
                                <br />
                                <strong>Bank Name :</strong> INDIAN OVERSEAS BANK<br /> (NOLAMBUR BRANCH)
                                <br />
                                <strong>A/c No. :</strong> 213902000100020
                                <br />
                                <strong>Branch & IFS Code :</strong> IOBA0002139
                            </td>
                        </tr>

                        <tr>
                            <td colSpan="3" className='bt'>
                                <strong>Declaration</strong>
                                <br />
                                We declare that this invoice shows the actual price of the goods<br /> described and that all particulars are true and correct.
                            </td>
                            <td colSpan="4" style={{ height: '56px' }}>
                                <strong>
                                    <p>FOR ULTRA SURFACE FINISHERS</p>
                                    
                                    <br />
                                    Authorised Signatory
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p className='f-13 text-center mt-2 text-bold'>This is a computer Generated Invoice</p>
            </div>
        </div>
    );
};

export default Invoice;
