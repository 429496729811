import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Checkbox,
  IconButton,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Iconify from "../iconify";
import { getData } from "src/webService/webService";
const InwardAndOutward = ({
  handleStockReport,
  clientId,
  companydetails,
  specialclients,
}) => {
  console.log(companydetails, "companydetails");
  const [limit, setLimit] = useState(5);
  const accessToken = sessionStorage.getItem("Token");
  const [totalPages, setTotalPages] = useState(1);
  const [AllBill, setAllBill] = useState([]);
  const [billId, setBillId] = useState();
  const [outwardList, setOutwardList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);

  console.log(AllBill, "allbill");
  console.log(specialclients, 'specialclients');

  // const clientId = useSelector((state) => state?.ALLCLIENTLIST?.specificClientsList?._id)

  // const AllOutwardList = async (page) => {
  //   const params = {
  //     page: page,
  //     limit: null,
  //   };
  //   try {
  //     const res = await getData(
  //       `outward/client/${clientId}`,
  //       params,
  //       accessToken
  //     );
  //     if (res.status === 200) {
  //       // setAllBill(res.data);
  //       setOutwardList(res.data.results);
  //       setTotalPages(res.data);
  //       console.log(res.data);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // const AllBillList = async (page) => {
  //   const params = {
  //     page: page,
  //     limit: null,
  //   };
  //   try {
  //     const res = await getData(`bill/client/${clientId}`, params, accessToken);
  //     if (res.status === 200) {
  //       setAllBill(res.data.results);
  //       setTotalPages(res.data);
  //       console.log(res.data);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  useEffect(() => {
    // AllOutwardList(1);
    // AllBillList(1);
    if (specialclients) {
      setAllBill(specialclients?.inwards);
      setOutwardList(specialclients?.outwards);
      setInvoiceList(specialclients?.invoices);
    }
  }, [specialclients]);

  console.log(outwardList, "outwardList");


  const formatDate = (dateString) => {
    return dateString?.substring(0, 10); // Extracts 'YYYY-MM-DD'
  };

  const [mappedTable, setMappedTable] = useState([]);
  const [unmappedInward, setUnmappedInward] = useState([]);
  const [unmappedOutward, setUnmappedOutward] = useState([]);
  useEffect(() => {
    if (AllBill?.length > 0 && outwardList?.length > 0) {
      const sortedInward = [...AllBill].sort((a, b) =>
        a.dcNo?.localeCompare(b.dcNo)
      );
      const sortedOutward = [...outwardList].sort((a, b) =>
        a.dcNo?.localeCompare(b.dcNo)
      );

      const mapped = [];
      const unmappedIn = [];
      const matchedOutIndices = new Set();

      sortedInward.forEach((inItem) => {
        let isMapped = false;

        sortedOutward.forEach((outItem, outIndex) => {
          if (inItem.dcNo === outItem.dcNo) {
            if (inItem.inwardTable && outItem.inwardTable) {
              inItem.inwardTable.forEach((inwardPart) => {
                outItem.inwardTable.forEach((outwardPart) => {
                  if (inwardPart.partNo === outwardPart.partNo) {
                    mapped.push({
                      partNo: inwardPart.partNo,
                      process: inwardPart.process,
                      dcDate: formatDate(inItem.dcDate),
                      quantity: inwardPart.quantity,
                      inwardDate: formatDate(inwardPart.scpoDate),
                      outwardDate: formatDate(outwardPart.scpoDate),
                    });
                    isMapped = true;
                    matchedOutIndices.add(outIndex);
                  }
                });
              });
            }
          }
        });

        if (!isMapped) {
          unmappedIn.push(inItem);
        }
      });

      const unmappedOut = sortedOutward.filter(
        (_, index) => !matchedOutIndices.has(index)
      );

      setMappedTable(mapped);
      setUnmappedInward(unmappedIn);
      setUnmappedOutward(unmappedOut);
    }
  }, [AllBill, outwardList]);

  // More Info
  const [open, setOpen] = useState(false);
  const [inwardoutWardName, setInwardOutwardName] = useState();
  const [inwardoutWardDetails, setInwardOutwardDetails] = useState();
  const handleviewInwardOutward = (data, name) => {
    console.log("name", name);
    console.log(data, "data");
    setOpen(true);
    setInwardOutwardName(name);
    setInwardOutwardDetails(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Invoice More Info
  const [openInvoice, setOpenInvoice] = useState(false);
  const [InvoiceMoreInfo, setInvoiceMoreInfo] = useState();
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };
  const handleviewInvoice = (data) => {
    setOpenInvoice(true);
    setInvoiceMoreInfo(data);
  };

  const [searchDCNo, setSearchDCNo] = useState("");

  const filteredData = AllBill?.filter((item) =>
    item.inwardDeliveryNoteChallan?.dcNo
      ?.toLowerCase()
      ?.includes(searchDCNo?.toLowerCase())
  );

  const [outsearchDCNo, setOutSearchDCNo] = useState("");
  console.log(outwardList, "outwardList90909");

  const filteredOutData = outwardList?.filter((item) =>
    item.outwardDeliveryNoteChallan?.[0]?.dcNo
      .toLowerCase()
      .includes(outsearchDCNo.toLowerCase())
  );

  return (
    <Paper>
      <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
        <DialogTitle>{inwardoutWardName} Details</DialogTitle>
        <DialogContent>
          {inwardoutWardDetails?.inwardTable && (
            <TableContainer>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cl color-dark p-3">
                      S.No
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Part Name
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Part No
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Process
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Initial Qty
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Pending Qty
                    </TableCell>
                    <TableCell className="table-cl color-dark">Units</TableCell>
                    <TableCell className="table-cl color-dark">
                      SAC No
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inwardoutWardDetails &&
                    inwardoutWardDetails?.inwardTable &&
                    inwardoutWardDetails?.inwardTable?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="p-3">
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={index + 1}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.partName}
                            name="partName"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            className="w-100"
                            name="partNo"
                            value={row?.partNo}
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.process}
                            name="process"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.quantity}
                            name="quantity"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.initialQuantity}
                            name="initialQuantity"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.units}
                            name="units"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.sacNo}
                            name="sacNo"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {inwardoutWardDetails?.outwardTable && (
            <TableContainer>
              <Table className="table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cl color-dark p-3">
                      S.No
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Part Name
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Part No
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Process
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Initial Qty
                    </TableCell>
                    <TableCell className="table-cl color-dark">
                      Outward Qty
                    </TableCell>
                    <TableCell className="table-cl color-dark">Units</TableCell>
                    <TableCell className="table-cl color-dark">
                      SAC No
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                  {inwardoutWardDetails &&
                    inwardoutWardDetails &&
                    inwardoutWardDetails?.outwardTable?.map(
                      (row, index) => (
                        <TableRow key={index}>
                          <TableCell className="p-3">
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={index + 1}
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={row?.partName}
                              name="partName"
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              className="w-100"
                              name="partNo"
                              value={row?.partNo}
                              size="medium"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={row?.description}
                              name="description"
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={row?.quantity}
                              name="quantity"
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={row?.outwardQuantity}
                              name="outwardQuantity"
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={row?.units}
                              name="units"
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              variant="outlined"
                              value={"998898"}
                              name="sacNo"
                              InputProps={{ readOnly: true }}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody> */}
                <TableBody>
                  {inwardoutWardDetails && (
                    (
                      inwardoutWardDetails?.outwardTableResult.length > 0
                        ? inwardoutWardDetails?.outwardTableResult
                        : inwardoutWardDetails?.outwardTable
                    )?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="p-3">
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={index + 1}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.partName}
                            name="partName"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            className="w-100"
                            name="partNo"
                            value={row?.partNo}
                            size="medium"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.description}
                            name="description"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.quantity}
                            name="quantity"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.outwardQuantity}
                            name="outwardQuantity"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={row?.units}
                            name="units"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={"998898"}
                            name="sacNo"
                            InputProps={{ readOnly: true }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>

              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="btn-secondary"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      {/* InvoiceMoreInfo */}
      <Dialog open={openInvoice} onClose={handleCloseInvoice} maxWidth={"xl"}>
        <DialogTitle>Invoice Details</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell className="table-cl color-dark p-3">
                    S.No
                  </TableCell>
                  <TableCell className="table-cl color-dark p-3">
                    USF DC No
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Part Name
                  </TableCell>
                  <TableCell className="table-cl color-dark">Part No</TableCell>
                  <TableCell className="table-cl color-dark">Process</TableCell>
                  <TableCell className="table-cl color-dark">
                    Invoice Qty
                  </TableCell>
                  <TableCell className="table-cl color-dark">Rate</TableCell>
                  <TableCell className="table-cl color-dark">SAC No</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {InvoiceMoreInfo?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="p-3">
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={index + 1}
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={row?.usfDCNo}
                        name="usfDCNo"
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={row?.partName}
                        name="partName"
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        className="w-100"
                        name="partNo"
                        value={row?.partNo}
                        size="medium"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={row?.description}
                        name="description"
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={row?.invoiceQuantity}
                        name="invoiceQuantity"
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={row?.rate}
                        name="rate"
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={"998898"}
                        name="sacNo"
                        InputProps={{ readOnly: true }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="btn-secondary"
            onClick={handleCloseInvoice}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      {/* <ToastContainer position="top-right" /> */}
      <>
        <div className="card p-4">
          <div className="component-title">
            <h5>Inward / Outward / Invoice History</h5>
          </div>

          <Grid container spacing={4} className="mb-3">
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="Company Name"
                value={companydetails?.companyname}
                label="Company Name"
                variant="outlined"
                sx={{ borderRadius: "15px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="emailId"
                value={companydetails?.email}
                label="Email Id"
                variant="outlined"
                sx={{ borderRadius: "15px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="mobileNo"
                value={companydetails?.phoneNumber}
                label="Mobile Number"
                variant="outlined"
                sx={{ borderRadius: "15px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                name="gstNo"
                value={companydetails?.gstNo}
                label="GST No"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <div>
            <div style={{ width: "100%" }} className="invoice-card">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <p className="fw-bold mb-0">
                  Total Inward:{" "}
                  {specialclients?.length === 0 ? "0" : specialclients?.length}
                </p>
                <TextField
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                  label="Search DC No"
                  variant="outlined"
                  size="small"
                  className="col-3 mb-0"
                  margin="normal"
                  value={searchDCNo}
                  onChange={(e) => setSearchDCNo(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={"ic:baseline-search"} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <h4 className="mb-0">Inward</h4> */}
              </div>

              <TableContainer style={{ height: "300px", overflowY: "scroll" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cl color-dark" align="center">
                        S.No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        Dc No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        Date
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Initial Qty
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Pending Outward Qty
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        More Info
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {specialclients &&
                      specialclients?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell>
                            {row?.inwards[0]?.inwardDeliveryNoteChallan?.dcNo}
                          </TableCell>
                          <TableCell>
                            {formatDate(row?.inwards[0]?.inwardDeliveryNoteChallan?.dcDate)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.inwards[0]?.inwardDeliveryNoteChallan?.grandQuantity}
                          </TableCell>
                          <TableCell align="center">
                            {row?.inwards[0]?.inwardDeliveryNoteChallan?.totalValue}
                          </TableCell>
                          <TableCell align="center">
                            <Iconify
                              className=""
                              icon={"ph:info-light"}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleviewInwardOutward(
                                  row?.inwards[0]?.inwardDeliveryNoteChallan,
                                  "Inward"
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    {(!specialclients || specialclients.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div style={{ width: "100%" }} className="my-5 invoice-card">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <p className="fw-bold mb-0">
                  Total Outward:{" "}
                  {specialclients && specialclients.length > 0
                    ? specialclients.flatMap((client) => client.outwards).length
                    : "0"}
                </p>
                <TextField
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                  label="Search DC No"
                  variant="outlined"
                  size="small"
                  className="col-3 mb-0"
                  margin="normal"
                  value={outsearchDCNo}
                  onChange={(e) => setOutSearchDCNo(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={"ic:baseline-search"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <TableContainer
                style={{ height: "300px", overflowY: "scroll" }}
                className="mt-0"
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cl color-dark" align="center">
                        S.No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        Dc No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        Date
                      </TableCell>
                      {/* <TableCell className="table-cl color-dark">
                        Challan No
                      </TableCell> */}
                      <TableCell className="table-cl color-dark">
                        Vehicle No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        USF DC No
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Outward Qty
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Pending Invoice Qty
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        More Info
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {specialclients && specialclients.length > 0 ? (
                      specialclients.flatMap((client) =>
                        client.outwards.map((row, index) => (
                          <TableRow key={`${client._id}-${index}`}>
                            <TableCell>{row?.outwardDeliveryNoteChallan?.dcNo}</TableCell>
                            <TableCell>
                              {row?.outwardDeliveryNoteChallan?.date?.split("T")[0]}
                            </TableCell>
                            <TableCell>{row?.outwardDeliveryNoteChallan?.vehicleNo}</TableCell>
                            <TableCell align="center">
                              {row?.outwardDeliveryNoteChallan?.yourDcNo}
                            </TableCell>
                            <TableCell align="center">
                              {row?.outwardDeliveryNoteChallan?.valueOfGoods}
                            </TableCell>
                            <TableCell align="center">
                              {row?.outwardDeliveryNoteChallan?.remainingValueOfGoods}
                            </TableCell>
                            <TableCell align="center">
                              <Iconify
                                className=""
                                icon={"ph:info-light"}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleviewInwardOutward(
                                    row?.outwardDeliveryNoteChallan,
                                    "Outward"
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ).map((row, index) => (
                        <TableRow key={row.key}>
                          <TableCell align="center">{index + 1}</TableCell>
                          {row.props.children}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                  {/* <TableBody>
                    {specialclients && specialclients.length > 0 ? (
                      specialclients.flatMap((client) =>
                        client.outwards.map((row, index) => {
                          const hasOutwardTableResult =
                            row?.outwardDeliveryNoteChallan?.outwardTableResult?.length > 0;
                          const dataSource = hasOutwardTableResult
                            ? row?.outwardDeliveryNoteChallan
                            : row[0]?.outwardDeliveryNoteChallan;

                          return (
                            <TableRow key={`${client._id}-${index}`}>
                              <TableCell>{dataSource?.dcNo}</TableCell>
                              <TableCell>
                                {dataSource?.date?.split("T")[0]}
                              </TableCell>
                              <TableCell>{dataSource?.vehicleNo}</TableCell>
                              <TableCell align="center">
                                {dataSource?.yourDcNo}
                              </TableCell>
                              <TableCell align="center">
                                {dataSource?.valueOfGoods}
                              </TableCell>
                              <TableCell align="center">
                                {dataSource?.remainingValueOfGoods}
                              </TableCell>
                              <TableCell align="center">
                                <Iconify
                                  className=""
                                  icon={"ph:info-light"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleviewInwardOutward(dataSource, "Outward")
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ).map((row, index) => (
                        <TableRow key={row.key}>
                          <TableCell align="center">{index + 1}</TableCell>
                          {row.props.children}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </div>

            <div style={{ width: "100%" }} className="my-5 invoice-card">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <p className="fw-bold mb-0">
                  Total Invoice: {invoiceList?.length}{" "}
                </p>
                {/* <TextField
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                  label="Search DC No"
                  variant="outlined"
                  size="small"
                  className="col-3 mb-0"
                  margin="normal"
                  value={outsearchDCNo}
                  onChange={(e) => setOutSearchDCNo(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={"ic:baseline-search"} />
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </div>
              <TableContainer
                style={{ height: "300px", overflowY: "scroll" }}
                className="mt-0"
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-cl color-dark" align="center">
                        S.No
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Invoice No
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Total Quantity
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Total Amount
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Type of Invoice
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        More Info
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceList &&
                      invoiceList?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row?.invoiceNo}</TableCell>
                          <TableCell align="center">
                            {row?.totalQuantity}
                          </TableCell>
                          <TableCell align="center">
                            {row?.totalAmount}
                          </TableCell>
                          <TableCell align="center">
                            {row?.outwardId?.length !== 1 ? (
                              <span
                                class="badge bg-primary text-light text-center"
                                style={{ width: "55px" }}
                              >
                                Multiple
                              </span>
                            ) : (
                              <span
                                class="badge bg-info text-dark text-center"
                                style={{ width: "55px" }}
                              >
                                Single
                              </span>
                            )}
                          </TableCell>

                          <TableCell align="center">
                            <Iconify
                              className=""
                              icon={"ph:info-light"}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleviewInvoice(row?.invoiceData)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}

                    {(!invoiceList || invoiceList.length == 0) && (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <Button
              variant="outlined"
              onClick={handleStockReport}
              className="btn-secondary"
            >
              BACK
            </Button>
          </div>
        </div>
      </>
    </Paper>
  );
};
export default InwardAndOutward;
