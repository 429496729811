import React, { useEffect, useState } from 'react';
import { TextField, Grid, Table, TableHead, TableRow, TableCell, TableBody, Button, MenuItem, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import html2pdf from 'html2pdf.js';
import Deliverynotechallantemplate from '../templates/deliverynotechallan';

import Invoicetemplate from '../templates/invoicetemplate';
import AddInvoice from '../Invoice/AddInvoice';
import { toast } from 'react-toastify';
import { getData } from 'src/webService/webService';
import INVOICEAPI from 'src/API_Routes/InvoiceAPI/InvoiceAPI';

// Function to calculate total amount
const calculateTotalAmount = (rows) => {
    return rows.reduce((total, row) => total + parseFloat(row.Amount || 0), 0);
};

const calculateTotalQuantity = (rows) => {
    return rows.reduce((total, row) => total + parseFloat(row.quantity || 0), 0);
};

// Function to calculate taxes and grand total
const calculateTaxesAndGrandTotal = (totalAmount) => {
    const cgstRate = 0.06; // 6%
    const sgstRate = 0.06; // 6%
    const igstRate = 0.12; // 12%
    const cgst = totalAmount * cgstRate;
    const sgst = totalAmount * sgstRate;
    const igst = totalAmount * igstRate;
    const grandTotal = totalAmount + cgst + sgst + igst;

    return {
        cgst,
        sgst,
        igst,
        grandTotal
    };
};

function Invoice({ HandleOutward, basicClientDetail, outwardTable, outwardList, specificOutwardList, action, billId }) {
    const accessToken = sessionStorage.getItem("Token");
    const [rows, setRows] = useState(outwardTable || []);
    const [addInvoiceOpen, setAddInvoiceOpen] = useState(false);
    const [companyname, setCompanyName] = useState(basicClientDetail?.companyname);
    const [doorno, setDoorNo] = useState(basicClientDetail?.doorNo);
    const [streetname, setStreetName] = useState(basicClientDetail?.streetName);
    const [city, setCity] = useState(basicClientDetail?.city);
    const [pincode, setPincode] = useState(basicClientDetail?.pinCode);
    const [vendorcode, setVendorCode] = useState();
    const [gstinno, setGstinNo] = useState(basicClientDetail?.gstNo);
    const [orderno, setOrderNo] = useState(outwardList?.yourOrderNo);
    const [invoice, setInvoice] = useState();
    const [date, setDate] = useState(outwardList?.date?.split('T')[0]);
    const [saccode, setSacCode] = useState('998898');
    const totalQuantity = calculateTotalQuantity(rows);
    const totalAmount = calculateTotalAmount(rows);
    const [count, setCount] = useState();
    const [invoiceNo, setInvoiceNo] = useState();
    const { cgst, sgst, igst, grandTotal } = calculateTaxesAndGrandTotal(totalAmount);
    const [outwardLists, setOutwardLists] = useState({
        usfDCNo: outwardList[0]?.yourDcNo,
        usfDcDate: outwardList[0]?.date?.split('T')[0],
        customerDCNo: outwardList[0]?.dcNo,

    });

    // console.log(mergedRows, "mergred row");


    const convertsToPDFAndDownload = async (htmlContent, fileName) => {
        try {
            const element = document.createElement('div');
            element.innerHTML = htmlContent;
            const opt = {
                margin: [8, 12],
                filename: fileName,
                image: { type: 'jpeg', quality: 0.98 },
            };
            await html2pdf().from(element).set(opt).save();
        } catch (error) {
            console.error('Error during PDF download:', error);
        }
    };

    const data = {
        companyname,
        doorno,
        streetname,
        city,
        pincode,
        gstinno,
        orderno,
        date,
        vendorcode,
        invoice,
    };

    const handleAddInvoice = () => {
        setAddInvoiceOpen(true)
    };

    const handleCloseInvoiceOpen = () => {
        setAddInvoiceOpen(false);
    };

    const mergedRows = rows.map(row => ({
        ...row,
        ...outwardLists
    }));


   
    const AllInvoiceList = async (page) => {
        try {
            const params = {
                page: 1,
                limit: 5,
            };
            const res = await getData(INVOICEAPI.GETALLINVOICE, params, accessToken);
            if (res.status === 200) {
                if (res?.data?.[0]?.totalCount?.totalItems) {
                    setCount(res.data?.[0]?.totalCount?.totalItems);
                } else {
                    setCount(1);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };



    useEffect(() => {
        AllInvoiceList();
    }, []);

    useEffect(() => {
        if (count) {
            console.log(count, "count");
            if (count < 1) {
                setInvoiceNo(1)
            }
            else {
                setInvoiceNo(count + 1);
            }
        }
    }, [count]);

    return (
        <div className='p-5 pt-0'>
            <div>
                <div className="app">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" >Company</Typography>
                            <p className="mb-0" >{companyname}</p>
                            <Typography variant="caption" >{doorno}, {streetname}, {city}, {pincode}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" >GSTIN No</Typography>
                            <p className="mb-0" >{gstinno}</p>
                            <Typography variant="caption" >SAC Code</Typography>
                            <p className="mb-0" >{saccode}</p>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" >Order No</Typography>
                            <p className="mb-0" >{(outwardList[0]?.yourOrderNo) ? outwardList[0]?.yourOrderNo : 'Not Set'}</p>
                            <Typography variant="caption" >Order Date</Typography>
                            <p className="mb-0" >{(outwardList[0]?.date) ? outwardList[0]?.date?.split('T')[0] : 'Not Set'}</p>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Table className="ml-0">
                <TableHead>
                    <TableRow>
                        <TableCell className='table-cl'>S.NO</TableCell>
                        <TableCell className='table-cl'>USF DCNO</TableCell>
                        <TableCell className='table-cl'>Date</TableCell>
                        <TableCell className='table-cl'>Part Name</TableCell>
                        <TableCell className='table-cl'>Part No</TableCell>
                        <TableCell className='table-cl'>Components</TableCell>
                        <TableCell className='table-cl'>Customer DCNO</TableCell>
                        <TableCell className='table-cl'>Quantity</TableCell>
                        <TableCell className='table-cl'>Outward Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mergedRows.filter((row) => row.pendingQuantity > 0).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{outwardList[0]?.yourDcNo}</TableCell>
                            <TableCell>{outwardList[0]?.date?.split('T')[0]}</TableCell>
                            <TableCell>{row.partName}</TableCell>
                            <TableCell>{row.partNo}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{outwardList[0].dcNo}</TableCell>
                            <TableCell>{row.quantity}</TableCell>
                            <TableCell>{row.outwardQuantity}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* <MenuItem
                onClick={() => {
                    setOpen(null);
                    convertsToPDFAndDownload(Deliverynotechallantemplate(outwardList, outwardTable, basicClientDetail), "Invoice.pdf")
                }}
            ></MenuItem> */}

            <div className='d-flex justify-content-end mt-3'>
                <div>
                    <Button variant="outlined" onClick={HandleOutward} className='btn-secondary'>
                        CANCEL
                    </Button>
                    <Button variant="contained" className='ml-8 btn-primary' onClick={handleAddInvoice}>
                        ADD INVOICE
                    </Button>
                    {/* <Button variant="contained" className='ml-8 btn-primary' onClick={() => convertsToPDFAndDownload(Invoicetemplate(data, mergedRows, totalQuantity, totalAmount, cgst, sgst, igst, grandTotal), "Invoice.pdf")}>
                        DOWNLOAD
                    </Button> */}
                </div>
            </div>

            <Dialog fullWidth open={addInvoiceOpen} onClose={() => setAddInvoiceOpen(false)} maxWidth={"md"}>
                <DialogTitle>New Invoice</DialogTitle>
                <DialogContent>
                    <AddInvoice
                        basicClientDetail={basicClientDetail}
                        outwardTable={mergedRows}
                        outwardList={outwardList}
                        invData={data}
                        billId={billId}
                        handleCloseInvoiceOpen={handleCloseInvoiceOpen}
                        HandleOutward={HandleOutward}
                        invoiceNo={invoiceNo}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Invoice;