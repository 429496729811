import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  MenuItem,
} from "@mui/material";
import "../Outward/addoutward.css";
import "../AllClients/AllClients.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getData, patchData, postData } from "src/webService/webService";
import { useSelector } from "react-redux";
import { setSpecificClientsDetails } from "src/redux/Slices/AllClientSlices";
import { setALLINWARDLIST } from "src/redux/Slices/AllInwardSlices";
import INWARDAPI from "src/API_Routes/InwardAPI/InwardAPI";
import OUTWARDAPI from "../../API_Routes/OutwardAPI/OutwardAPI";

function AddOutwardchallan({
  handleOutward,
  action,

  limit,
  currentPage,
  setTotalItems,
}) {
  // console.log(dCCount, "dCCount");
  const [dCCount, setDCCount] = useState(null);
  const SPECIFICCLIENTDETAILS = useSelector(
    (state) => state?.ALLCLIENTSLIST.specificClientsList
  );
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const [yourDcNo, setyourDcNo] = useState();
  const [date, setDate] = useState();
  const [valueofGoods, setValueOfGoods] = useState();
  const [assessmentYear, setAssessmentYear] = useState();
  const [yourOrderNo, setyourOrderNo] = useState();
  // const [challanNo, setChallanNo] = useState();
  const [vehicleNo, setVehicleNo] = useState();
  const SPECIFICOUTWARDLIST = useSelector(
    (state) =>
      state?.ALLINWARDLIST?.specificInwardList?.outwardDeliveryNoteChallan
  );
  const SPECIFICOUTWARD = useSelector(
    (state) => state?.ALLINWARDLIST?.specificInwardList
  );
  const INWARDTABLELIST = useSelector(
    (state) =>
      state?.ALLINWARDLIST?.specificInwardList?.inwardDeliveryNoteChallan
        ?.inwardTable
  );
  const [rows, setRows] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalReducedValue, setTotalReducedValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  console.log(SPECIFICOUTWARD, "SPECIFICOUTWARD");

  useEffect(() => {
    if (INWARDTABLELIST) {
      setRows(
        INWARDTABLELIST.map((row) => ({
          ...row,
          outwardQuantity: 0, // Set outwardQuantity to 0
          reducedValue: row.initialQuantity, // initial reduced value is the total quantity
        }))
      );
    }
  }, [INWARDTABLELIST]);

  useEffect(() => {
    const newGrandTotal = calculateGrandTotal(rows);
    setGrandTotal(newGrandTotal);
    setTotalReducedValue(calculateTotalReducedValue(rows));
    setTotalAmount(calculateTotalAmount(rows));
  }, [rows]);

  const calculateGrandTotal = (rows) => {
    return rows.reduce(
      (sum, row) => sum + parseFloat(row.outwardQuantity || 0),
      0
    );
  };

  const calculateTotalReducedValue = (rows) => {
    return rows.reduce(
      (sum, row) => sum + parseFloat(row.reducedValue || 0),
      0
    );
  };

  const calculateTotalAmount = (rows) => {
    return rows.reduce((sum, row) => sum + parseFloat(row.totalAmount || 0), 0);
  };

  // const handleInputChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const parsedValue = parseFloat(value);

  //   const updatedRows = rows.map((row, i) => {
  //     if (i === index) {
  //       const outwardQuantity =
  //         parsedValue > row.initialQuantity ? row.initialQuantity : parsedValue;
  //       if (parsedValue > row.initialQuantity) {
  //         toast.warning(
  //           `Outward initialQuantity cannot exceed total initialQuantity of ${row.initialQuantity}`
  //         );
  //       }
  //       return {
  //         ...row,
  //         [name]: outwardQuantity,
  //         reducedValue: row.initialQuantity - outwardQuantity,
  //       };
  //     }
  //     return row;
  //   });
  //   setRows(updatedRows);
  // };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);

    const updatedRows = rows.map((row, i) => {
      if (i === index) {
        // Update the outwardQuantity or rate based on the input name
        const updatedRow = {
          ...row,
          [name]: parsedValue,
        };

        // Calculate the new totalAmount
        const outwardQuantity =
          name === "outwardQuantity" ? parsedValue : row.outwardQuantity;
        const rate = name === "rate" ? parsedValue : row.rate;
        const totalAmount = outwardQuantity * rate;

        // Ensure outwardQuantity does not exceed initialQuantity
        const finalOutwardQuantity =
          outwardQuantity > row.initialQuantity
            ? row.initialQuantity
            : outwardQuantity;

        if (outwardQuantity > row.initialQuantity) {
          toast.warning(
            `Outward Quantity cannot exceed total initial Quantity of ${row.initialQuantity}`
          );
        }

        return {
          ...updatedRow,
          outwardQuantity: finalOutwardQuantity,
          // reducedValue: row.initialQuantity - finalOutwardQuantity,
          reducedValue: finalOutwardQuantity
            ? row.initialQuantity - finalOutwardQuantity
            : row.initialQuantity,
          totalAmount: totalAmount,
        };
      }
      return row;
    });

    setRows(updatedRows);
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const specificBillerData = async () => {
    try {
      const res = await getData(
        `clients/client/${SPECIFICOUTWARD.clientId}`,
        null,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setSpecificClientsDetails(res.data));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    specificBillerData();
  }, []);

  const AllInwardList = async () => {
    const params = {
      page: currentPage,
      limit: limit,
    };
    try {
      const res = await getData(INWARDAPI.GETALLBILL, params, accessToken);
      if (res.status === 200) {
        if (res?.data?.[0]?.totalCount?.totalItems) {
          setTotalItems(res.data[0].totalCount.totalItems);
        } else {
          setTotalItems(0);
        }
        dispatch(setALLINWARDLIST(res.data));
        // setTotalPages(res.data[0].totalPages);
        console.log(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const currentYear = new Date().getFullYear();
  const [dcYear1, setDcYear1] = useState(`${currentYear}-01-01`);
  const [dcYear2, setDcYear2] = useState(`${currentYear + 1}-01-01`);

  // Generate a list of years (e.g., from current year to next year)
  const generateYearOptions = () => {
    const years = [];
    for (let year = currentYear; year <= currentYear + 1; year++) {
      years.push(year);
    }
    return years;
  };

  const handleYearChange1 = (e) => {
    const selectedYear = e.target.value;
    const formattedDate = `${selectedYear}-01-01`;
    setDcYear1(formattedDate);
  };

  const handleYearChange2 = (e) => {
    const selectedYear = e.target.value;
    const formattedDate = `${selectedYear}-01-01`;
    setDcYear2(formattedDate);
  };

  const InwardPatch = async () => {
    const InwardparamsArray = {
      clientId: SPECIFICCLIENTDETAILS?._id,
      inwardDeliveryNoteChallan: {
        companyName: SPECIFICCLIENTDETAILS?.companyname,
        branch: SPECIFICCLIENTDETAILS?.branch,
        dcNo: SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.dcNo,
        dcDate: SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.dcDate,
        assesmentStartYear: SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.dcYear1,
        assesmentEndYear: SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.dcYear2,
        grandQuantity:
          SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.grandQuantity,
        totalValue: totalReducedValue,
        remarks: "Good",
        inwardTable: rows.map((row) => ({
          partName: row.partName,
          partNo: row.partNo,
          process: row.process,
          units: row.units,
          sacNo: "998898",
          quantity: row.quantity,
          initialQuantity: row.reducedValue,
        })),
      },
    };

    await patchData(
      `bill/${SPECIFICOUTWARD._id}/client/${SPECIFICCLIENTDETAILS?._id}`,
      InwardparamsArray,
      accessToken
    )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "inwardapipatch");
          AllInwardList();
          handleOutward();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleOutwards = async () => {
    if (!date) {
      toast.error("Billing Date is required");
      return;
    }

    const filteredRows = rows.filter((row) => row.outwardQuantity > 0);
    if (filteredRows.length === 0) {
      toast.error("Please fill the Outward Qty");
      return;
    }

    const missingRates = filteredRows.filter((row) => !row.rate);
    const isValueOfGoodsMissing = !valueofGoods;

    if (missingRates.length > 0 && isValueOfGoodsMissing) {
      toast.error(
        "Please provide either a rate for the Outward Quantity or the Value of Goods"
      );
      return;
    }

    const paramsArray = {
      billId: SPECIFICOUTWARD?._id,
      clientId: SPECIFICCLIENTDETAILS?._id,
      outwardDeliveryNoteChallan: [
        {
          companyName: SPECIFICCLIENTDETAILS?.companyname,
          branch: SPECIFICCLIENTDETAILS?.branch,
          dcNo: SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.dcNo,
          date: date?.split("T")[0],
          vehicleNo: vehicleNo,
          assessmentStartYear: dcYear1,
          assessmentEndYear: dcYear2,
          yourOrderNo: yourOrderNo,
          yourDcNo: yourDcNo,
          valueOfGoods: grandTotal,
          remainingValueOfGoods: grandTotal,
          totalAmount: totalAmount.toFixed(2) || valueofGoods,
          outwardTable: rows
            ?.filter((row) => row.outwardQuantity !== 0)
            ?.map((row) => ({
              partName: row.partName,
              partNo: row.partNo,
              description: row.process,
              quantity: row.quantity,
              outwardQuantity: row.outwardQuantity,
              pendingQuantity: row.outwardQuantity,
              reducedValue: row.reducedValue,
              totalWt: row.totalWt,
              units: row.units,
              rate: row.rate,
              totalAmount: row.totalAmount,
            })),
        },
      ],
    };

    console.log(paramsArray, "paramsArray");

    await postData(
      `outward/bill/${SPECIFICOUTWARD?._id}`,
      paramsArray,
      accessToken
    )
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          InwardPatch();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const AllOutwardList = async () => {
    const params = {
      page: 1,
      limit: 5,
    };
    try {
      const res = await getData(OUTWARDAPI.ALLGETOUTWARD, params, accessToken);
      if (res.status === 200) {
        if (res?.data?.[0]?.totalCount?.totalItems) {
          setDCCount(res.data?.[0]?.totalCount?.totalItems);
        } else {
          setDCCount(1);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // console.log(dCCount, "dcCount");

  useEffect(() => {
    AllOutwardList();
  }, []);

  useEffect(() => {
    if (dCCount) {
      console.log(dCCount, "dCCount");
      if (dCCount < 1) {
        setyourDcNo(1);
      } else {
        setyourDcNo(dCCount + 1);
      }
    }
  }, [dCCount]);

  console.log(yourDcNo, "yourDCNo");

  return (
    <div className="card p-4">
      <div className="component-title">
        <h5>Add Outward</h5>
      </div>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="Company Name"
              value={SPECIFICCLIENTDETAILS?.companyname}
              label="Company Name"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ borderRadius: "15px" }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="emailId"
              value={SPECIFICCLIENTDETAILS?.email}
              label="Email Id"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ borderRadius: "15px" }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="mobileNo"
              value={SPECIFICCLIENTDETAILS?.phoneNumber}
              label="Mobile Number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              sx={{ borderRadius: "15px" }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="gstNo"
              value={SPECIFICCLIENTDETAILS?.gstNo}
              label="GST No"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="app">
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="branch"
              value={SPECIFICCLIENTDETAILS?.branch}
              label="Branch"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="yourDcNo"
              value={yourDcNo}
              type="text"
              onChange={(event) => setyourDcNo(event.target.value)}
              label="USF DC No"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              className="w-100"
              select
              label="AssesmentStartYear"
              value={dcYear1.substring(0, 4)} // Display only the year part
              onChange={handleYearChange1}
              size="medium"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ readOnly: true }}
            >
              {generateYearOptions().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              className="w-100"
              select
              label="AssesmentEndYear"
              value={dcYear2.substring(0, 4)} // Display only the year part
              onChange={handleYearChange2}
              size="medium"
              InputProps={{ readOnly: true }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {generateYearOptions().map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={4} className="app">
          {/* <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name="challanNo"
              type="number"
              value={SPECIFICOUTWARD?.inwardDeliveryNoteChallan?.dcNo}
              onChange={(event) => setChallanNo(event.target.value)}
              label="Customer DC No"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              name="vehicleNo"
              value={vehicleNo}
              onChange={(event) => setVehicleNo(event.target.value)}
              label="Vehicle No"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              className="w-100"
              type="date"
              name="date"
              label="DC Date"
              value={date}
              min={getTodayDate()}
              size="medium"
              onChange={(event) => setDate(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: getTodayDate(),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              name="yourOrderNo"
              type="number"
              value={yourOrderNo}
              onChange={(event) => setyourOrderNo(event.target.value)}
              label="Purchase Order No"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="table-cl color-dark">S.NO</TableCell>
            <TableCell className="table-cl color-dark">Part Name</TableCell>
            <TableCell className="table-cl color-dark">Part No</TableCell>
            <TableCell className="table-cl color-dark">Process</TableCell>
            <TableCell className="table-cl color-dark">Initial Qty</TableCell>
            <TableCell className="table-cl color-dark">Remaining Qty</TableCell>
            <TableCell className="table-cl color-dark">Outward Qty</TableCell>
            <TableCell className="table-cl color-dark">Pending Qty</TableCell>
            <TableCell className="table-cl color-dark">Rate</TableCell>
            <TableCell className="table-cl color-dark">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={index + 1}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="partName"
                    value={row.partName}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="partNo"
                    value={row.partNo}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    value={row.process}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="quantity"
                    value={row.quantity}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="initialQuantity"
                    value={row.initialQuantity}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name="outwardQuantity"
                    type="number"
                    value={row.outwardQuantity}
                    onChange={(e) => handleInputChange(index, e)}
                    inputProps={{
                      max: row.initialQuantity,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="reducedValue"
                    value={row.reducedValue}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="rate"
                    type="number"
                    value={row.rate}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="totalAmount"
                    value={row.totalAmount || 0}
                    InputProps={{ readOnly: true }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div className="d-flex jusity-content-end align-items-center my-2">
        <p className="mb-0 me-3 col-2" style={{ whiteSpace: "nowrap" }}>
          Value of Goods
        </p>
        <TextField
          className="col-2"
          variant="outlined"
          type="number"
          value={valueofGoods}
          onChange={(e) => setValueOfGoods(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-end">
        <div>
          <Table className="mt-0">
            <TableRow>
              <TableCell colSpan={6} align="right">
                Outward Grand Total
              </TableCell>
              <TableCell>{grandTotal}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6} align="right">
                Total Remaining Qty
              </TableCell>
              <TableCell>{totalReducedValue}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={6} align="right">
                Total Amount
              </TableCell>
              <TableCell>{totalAmount.toFixed(2)}</TableCell>
            </TableRow>
          </Table>
          <div className="mt-3">
            <Button
              variant="outlined"
              onClick={handleOutward}
              className="btn-secondary"
            >
              BACK
            </Button>
            <Button
              variant="contained"
              onClick={handleOutwards}
              className="ml-8 btn-primary"
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOutwardchallan;
