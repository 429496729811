import { createSlice } from "@reduxjs/toolkit";

const ALLSTOCKREPORTLIST = createSlice({
  name: "ALLSTOCKREPORTLIST",
  initialState: {
    AllStockReportList: "",
    specificStockReportList: ""
  },
  reducers: {
    setALLSTOCKREPORTLIST: (state, action) => {
      state.AllStockReportList = action.payload;
    },
    setSpecificStockReportDetails: (state, action) => {
      state.specificStockReportList = action.payload;
    },
  },
});

export const { setALLSTOCKREPORTLIST, setSpecificStockReportDetails } = ALLSTOCKREPORTLIST.actions;
export default ALLSTOCKREPORTLIST.reducer;