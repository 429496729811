import React, { useEffect, useState } from "react";
import "../AllClients/AllClients.css";
import {
  Table,
  TableBody,
  FormControl,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Checkbox,
  InputLabel,
  Select,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import Iconify from "../iconify";
import AddInwardchallan from "./Addinward";
import INWARDAPI from "src/API_Routes/InwardAPI/InwardAPI";
import { toast, ToastContainer } from "react-toastify";
import { saveAs } from "file-saver";
import { getData, deleteData } from "src/webService/webService";
import {
  setALLINWARDLIST,
  setSpecificInwardDetails,
} from "src/redux/Slices/AllInwardSlices";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { setSpecificClientsDetails } from "src/redux/Slices/AllClientSlices";
import { id } from "date-fns/locale";
import Outward from "../Outward/Outward";
import AddOutwardchallan from "../Outward/addoutward";
import AddFilter from "../filter/inwardfilter";
import OUTWARDAPI from "src/API_Routes/OutwardAPI/OutwardAPI";
import { set } from "lodash";
import { showConfirmationDialog } from "src/utils/sweetalertUtils";
import useAllClients from "../CommonFunction/GetallClients";
import inwardLogo from "../logo/inbound (1).png";

const AddInward = () => {
  const [open, setOpen] = useState(null);
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [action, setAction] = useState("");
  const [addInward, setAddInward] = useState(true);
  const [limit, setLimit] = useState(5);
  const accessToken = sessionStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [billId, setBillId] = useState();
  const [clientId, setClientId] = useState();
  const [outWard, setOutWard] = useState();
  const [dCCount, setDCCount] = useState();
  const [TotalItems, setTotalItems] = useState();

  //For Search Filter
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [companyName, setCompanyName] = useState();
  const [branch, setBranch] = useState();
  const [company, setCompany] = useState();
  const [reset, setReset] = useState(false);
  //For Search Filter

  const ALLINWARDLIST = useSelector(
    (state) => state?.ALLINWARDLIST?.AllInwardList[0]?.paginatedResults
  );
  const dispatch = useDispatch();
  const [specificOutwards, setSpecificOutward] = useState([]);
  const [isSpecificOutwardsEmpty, setIsSpecificOutwardsEmpty] = useState(true);

  console.log(specificOutwards, "specificOutwards");
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  console.log(ALLINWARDLIST, "ALLINWARDLIST");

  useEffect(() => {
    setIsSpecificOutwardsEmpty(specificOutwards.length === 0);
  }, [specificOutwards]);

  // Function to fetch data
  const AllInwardList = async (page) => {
    if (startDate && !endDate) {
      toast.error("End date is required when start date is selected");
      return;
    }
    setReset(false);
    const params = {
      page: page,
      limit: limit,
      companyName: companyName ? companyName : "",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      branch: branch ? branch : "",
    };
    try {
      const res = await getData(INWARDAPI.GETALLBILL, params, accessToken);
      if (res.status === 200) {
        dispatch(setALLINWARDLIST(res.data));
        setTotalPages(res.data[0].totalPages);
        if (res?.data?.[0]?.totalCount?.totalItems) {
          setTotalItems(res.data[0].totalCount.totalItems);
        } else {
          setTotalItems(0);
        }

        console.log(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle deletion
  const deleteClient = async () => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      await deleteData(`bill/${billId}/client/${clientId}`, accessToken)
        .then((res) => {
          setOpen(null);
          AllInwardList(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
        });
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };

  // Handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};
    ALLINWARDLIST.forEach((row) => {
      updatedIsChecked[row?._id] = checked;
    });
    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  // Handle opening menu
  const handleOpenMenu = (event, billId, clientId) => {
    setOpen(event.currentTarget);
    setBillId(billId);
    setClientId(clientId);
  };

  const specificClientBill = async (billId, clientId) => {
    try {
      const res = await getData(
        `bill/${billId}/client/${clientId}`,
        null,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setSpecificInwardDetails(res.data));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const specificClientDetails = async (clientId) => {
    try {
      const res = await getData(
        `clients/client/${clientId}`,
        null,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setSpecificClientsDetails(res.data));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const specificOutward = async (billId) => {
    try {
      const res = await getData(`outward/bill/${billId}`, null, accessToken);
      if (res.status === 200) {
        dispatch(setSpecificClientsDetails(res.data));
        setSpecificOutward(res.data.results);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle closing menu
  const handleCloseMenu = () => {
    setOpen(null);
  };

  // Handle limit change
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
    unselectAllCheckboxes();
  };

  // Handle previous page navigation
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle next page navigation
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // const handleOutward = () => {
  //     setOutWard(!outWard)
  // }

  const handleOutward = () => {
    setTimeout(() => {
      setOutWard((prevOutWard) => !prevOutWard);
      unselectAllCheckboxes();
    }, 500); // Delay of 1000 milliseconds (1 second)
  };

  // Handle add inward action
  const HandleAddInward = () => {
    setAddInward(!addInward);
    unselectAllCheckboxes();
  };

  useEffect(() => {
    AllInwardList(currentPage);
  }, [currentPage, limit]);

  const formatDate = (isoString) => {
    return isoString.split("T")[0];
  };

  const handleExport = () => {
    const hasSelected = Object.values(isChecked).some((checked) => checked);

    if (!hasSelected) {
      toast.error("Select Inward Check Box");
      return;
    }

    const headers = [
      "Company Name",
      "DC No",
      "DC Date",
      "Initial Qty",
      "Pending Outward Qty",
      "Outward Qty",
      "Part Name",
      "Part No",
      "Process",
      "SAC No",
      "Units",
    ].filter(Boolean);

    // Filter checked items
    const checkedItems = ALLINWARDLIST.filter((item) => isChecked[item._id]);

    let data = [];

    checkedItems.forEach((item) => {
      const commonFields = [
        item?.inwardDeliveryNoteChallan?.companyName,
        item?.inwardDeliveryNoteChallan?.dcNo,
        item?.inwardDeliveryNoteChallan?.dcDate
          ? formatDate(item?.inwardDeliveryNoteChallan?.dcDate)
          : "",
      ];

      item.inwardDeliveryNoteChallan.inwardTable?.forEach(
        (inwardTableEntry) => {
          const Pendingqua =
            inwardTableEntry?.quantity - inwardTableEntry.initialQuantity;
          const specificFields = [
            inwardTableEntry?.quantity,
            inwardTableEntry.initialQuantity,
            Pendingqua,
            inwardTableEntry?.partName,
            inwardTableEntry?.partNo,
            inwardTableEntry?.process,
            "998898",
            inwardTableEntry?.units,
          ];

          data?.push(commonFields.concat(specificFields));
        }
      );
    });
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inward List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "Inward_List_data.xlsx"
    );
    unselectAllCheckboxes();
  };

  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  //   search Filter
  const { clientDetails, totalItems, fetchAllClients } = useAllClients();
  useEffect(() => {
    if (showFilter) {
      fetchAllClients();
    }
  }, [showFilter]);

  useEffect(() => {
    if (showFilter) {
      unselectAllCheckboxes();
    } else {
      unselectAllCheckboxes();
    }
  }, [showFilter]);

  // Function to handle company selection
  const handleCompanyChanges = (event) => {
    const companyName = event.target.value;
    const company = clientDetails?.find((c) => c._id === companyName);
    setCompanyName(company.companyname);
    setCompany(company._id);
    setBranch(company?.branch);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    AllInwardList(1);
  };

  const removeData = () => {
    setCompany();
    setCompanyName();
    setStartDate();
    setEndDate();
    setReset(true);
    setBranch();
  };

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
      AllInwardList(1);
    }
  }, [reset]);

  const unselectAllCheckboxes = () => {
    const updatedIsChecked = {};
    ALLINWARDLIST?.forEach((row) => {
      updatedIsChecked[row._id] = false;
    });
    setIsChecked(updatedIsChecked);
    setSelectAllChecked(false);
  };

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />
      {outWard ? (
        <>
          <AddOutwardchallan
            handleOutward={handleOutward}
            currentPage={currentPage}
            limit={limit}
            setTotalItems={setTotalItems}
          />
        </>
      ) : (
        <>
          {addInward ? (
            <>
              <div className="pt-3 pb-4 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src={inwardLogo} width="6%" className="mr-2" />
                  <p className="fw-bold mb-0 color-dark mt-2">
                    Total Inward : {TotalItems}
                  </p>
                </div>
                <div>
                  {showFilter && (
                    <span
                      className="me-2 danger"
                      style={{
                        color: "red",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        toggleFilter();
                        removeData();
                      }}
                    >
                      Clear Filter
                    </span>
                  )}
                  <IconButton
                    className="p-12 ftrbkgd rounded"
                    onClick={(e) => {
                      toggleFilter();
                      removeData();
                    }}
                  >
                    {showFilter ? (
                      <Iconify
                        icon="ri:filter-off-fill"
                        className=" color-dark"
                      />
                    ) : (
                      <>
                        <Iconify
                          icon="material-symbols:filter-alt-sharp"
                          className=" color-dark"
                        />
                      </>
                    )}
                  </IconButton>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ width: "143px" }}
                    onClick={handleExport}
                    className="ml-8 btn-secondary"
                  >
                    EXPORT
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    className="ml-8 btn-primary"
                    onClick={() => {
                      setOpen(null);
                      setAction("Add");
                      HandleAddInward();
                    }}
                  >
                    + ADD INWARD
                  </Button>
                </div>
              </div>
              <div>
                {showFilter && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ minWidth: 500, margin: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              className="w-100"
                              type="date"
                              label="DC Start Date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              className="w-100"
                              type="date"
                              label="DC End Date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: startDate,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>

                      <Grid item xs={3}>
                        <FormControl
                          sx={{ margin: 1, minWidth: 278 }}
                          size="large"
                          className="select"
                        >
                          <InputLabel id="company-name-label">
                            Company Name
                          </InputLabel>
                          <Select
                            labelId="company-name-label"
                            id="company-name-select"
                            label="Company Name"
                            value={company}
                            onChange={handleCompanyChanges}
                          >
                            {clientDetails &&
                              clientDetails?.map((company) => (
                                <MenuItem
                                  key={company._id}
                                  value={company._id}
                                >
                                  {company.companyname}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <div>
                        <Button
                          variant="outlined"
                          className="ml-8 btn-secondary"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <TableContainer style={{ height: "361px", overflowY: "scroll" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="table-cl"
                        style={{ padding: "8px 16px" }}
                      >
                        <Checkbox
                          className="color-white"
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        S.No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        Company Name
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        DC No
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        DC Date
                      </TableCell>
                      {/* <TableCell className='table-cl color-dark'>Process</TableCell> */}
                      <TableCell className="table-cl color-dark" align="center">
                        Initial Qty
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Pending Outward Qty
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Status
                      </TableCell>
                      <TableCell className="table-cl color-dark" align="center">
                        Add Outward
                      </TableCell>
                      <TableCell className="table-cl color-dark">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ALLINWARDLIST &&
                      ALLINWARDLIST.map((row, index) => (
                        <TableRow key={index} className="padding-8">
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isChecked[row._id] || false}
                              onChange={(event) =>
                                handleCheckboxChange(event, row._id)
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {calculateIndex(index)}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px" }}>
                            {row?.inwardDeliveryNoteChallan?.companyName}
                          </TableCell>
                          <TableCell>
                            {row?.inwardDeliveryNoteChallan?.dcNo}
                          </TableCell>
                          <TableCell>
                            {row?.inwardDeliveryNoteChallan?.dcDate
                              ?.split("T")[0]
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")}
                          </TableCell>
                          {/* <TableCell>{row?.inwardDeliveryNoteChallan?.abbrevation}</TableCell> */}
                          <TableCell align="center">
                            {row?.inwardDeliveryNoteChallan?.grandQuantity}
                          </TableCell>
                          <TableCell align="center">
                            {row?.inwardDeliveryNoteChallan?.totalValue}
                          </TableCell>
                          <TableCell align="center">
                            {row?.inwardDeliveryNoteChallan?.totalValue ===
                              0 ? (
                              <span class="badge text-bg-success f-13">
                                Completed
                              </span>
                            ) : row?.inwardDeliveryNoteChallan?.grandQuantity >
                              row?.inwardDeliveryNoteChallan?.totalValue ? (
                              <span class="badge text-bg-warning f-13">
                                In process
                              </span>
                            ) : (
                              <span class="badge text-bg-danger f-13">
                                Yet to start
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {row?.inwardDeliveryNoteChallan?.totalValue ===
                              0 ? (
                              <Tooltip title="Inward completed">
                                <Iconify
                                  icon={"material-symbols:check-circle"}
                                  color="green"
                                />
                              </Tooltip>
                            ) : (
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => {
                                  specificClientBill(row._id, row.clientId);
                                  specificClientDetails(row._id);
                                  handleOutward();
                                }}
                              >
                                <Iconify icon={"material-symbols:add"} />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                handleOpenMenu(event, row._id, row.clientId);
                                specificOutward(row._id);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}

                    {(!ALLINWARDLIST || ALLINWARDLIST.length == 0) && (
                      <TableRow>
                        <TableCell colSpan={8} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={TotalItems}>All</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
              <div className="d-flex">
                <span class="badge text-bg-success f-13">
                  Completed
                </span>
                <p className="f-12 mb-0 mx-4">Outward Completed</p>

                <span class="badge text-bg-warning f-13">
                  In process
                </span>
                <p className="f-12 mb-0 mx-4">Outward in process</p>

                <span class="badge text-bg-danger f-13">
                Yet to start
                </span>
                <p className="f-12 mb-0 mx-4">Outward Yet to start</p>
              </div>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 170,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                {isSpecificOutwardsEmpty ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        setOpen(null);
                        HandleAddInward();
                        specificClientBill(billId, clientId);
                        specificClientDetails(clientId);
                        setAction("Edit");
                      }}
                    >
                      <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                      Edit Inward
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpen(null);
                        setAction("View");
                        HandleAddInward();
                        specificClientBill(billId, clientId);
                        specificClientDetails(clientId);
                      }}
                    >
                      <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                      View Inward
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        setOpen(null);
                        specificClientBill(billId, clientId);
                        specificClientDetails(clientId);
                        handleOutward();
                      }}
                    >
                      <Iconify icon={"ic:sharp-plus"} sx={{ mr: 2 }} />
                      Add Outward
                    </MenuItem> */}
                    <MenuItem
                      sx={{ color: "error.main" }}
                      onClick={() => {
                        setOpen(null);
                        deleteClient();
                      }}
                    >
                      <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                      Delete Inward
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        setOpen(null);
                        setAction("View");
                        HandleAddInward();
                        specificClientBill(billId, clientId);
                        specificClientDetails(clientId);
                      }}
                    >
                      <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                      View Inward
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        setOpen(null);
                        specificClientBill(billId, clientId);
                        specificClientDetails(clientId);
                        handleOutward();
                      }}
                    >
                      <Iconify icon={"ic:sharp-plus"} sx={{ mr: 2 }} />
                      Add Outward
                    </MenuItem> */}
                  </>
                )}
              </Popover>
            </>
          ) : (
            <AddInwardchallan
              HandleAddInward={HandleAddInward}
              action={action}
              currentPage={currentPage}
              limit={limit}
              setTotalItems={setTotalItems}
            />
          )}
        </>
      )}
    </Paper>
  );
};

export default AddInward;
