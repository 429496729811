import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box } from "@mui/material";
// components
import { useChart } from "../../../components/chart";

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}) {
  // const chartOptions = useChart({
  //   plotOptions: { bar: { columnWidth: "16%" } },
  //   fill: { type: chartData.map((i) => i.fill) },
  //   labels: chartLabels,
  //   xaxis: { type: "datetime" },
  //   tooltip: {
  //     shared: true,
  //     intersect: false,
  //     y: {
  //       formatter: (y) => {
  //         if (typeof y !== "undefined") {
  //           return `${y.toFixed(0)}`;
  //         }
  //         return y;
  //       },
  //     },
  //   },
  // });
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels, // Labels should now be in 'YYYY-MM-DD' format
    xaxis: {
      type: "datetime",
      tickAmount: 12, // Ensure 12 ticks, one for each month
      labels: {
        format: "MMM 'yy", // Display format for the axis labels
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "MMM dd", // Format tooltip date correctly
      },
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
      <ReactApexChart
        type="line"
        series={chartData}
        options={chartOptions}
        height={364}
      />
    </Box>
  );
}
