import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { getData } from "../../webService/webService";
import DASHBOARDAPI from "../../API_Routes/DashboardAPI/DashboardAPI";
import { Card, MenuItem, TextField } from "@mui/material";

const ColumnChart = ({ AllClient, years }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const accessToken = sessionStorage.getItem("Token");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [monthlyInvoiceData, setMonthlyInvoiceData] = useState([]);
  const [monthlyAmountData, setMonthlyAmountData] = useState([]);
  const [firstapi, setFirstAPI] = useState(true);

  const fetchData = async (selectedYear, company) => {
    const body = {
      year: selectedYear,
      companyName: company,
    };
    try {
      setFirstAPI(false);
      const res = await getData(DASHBOARDAPI.INVOICECOUNT, body, accessToken);
      if (res.status === 200) {
        if (res.data.length !== 0) {
          setTotalInvoice(res.data[0].total);
          // Initialize arrays for the data
          const formattedInvoiceData = new Array(12).fill(0);
          const formattedAmountData = new Array(12).fill(0);
          res.data[0].months.forEach((monthData) => {
            formattedInvoiceData[monthData.month - 1] = monthData.count;
            formattedAmountData[monthData.month - 1] = monthData.totalAmount;
          });
          setMonthlyInvoiceData(formattedInvoiceData);
          setMonthlyAmountData(formattedAmountData);
          setTotalAmount(res.data[0].totalAmount);
        } else {
          setTotalInvoice(0);
          setMonthlyInvoiceData([]);
          setMonthlyAmountData([]);
          setTotalAmount(0);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
    fetchData(selectedYear, selectedCompany);
  };

  const handleCompanyChange = (event) => {
    const selectedCompanyName = event.target.value;
    setSelectedCompany(selectedCompanyName);
    fetchData(year, selectedCompanyName);
  };

  useEffect(() => {
    if (year && selectedCompany && firstapi) {
      fetchData(year, selectedCompany);
    }
  }, [year, selectedCompany]);

  useEffect(() => {
    setSelectedCompany(AllClient?.[0]?.companyname);
  }, [AllClient]);

  const menuStyles = {
    marginTop: 10,
    maxHeight: 200,
  };
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels on the bars
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      title: {
        text: "Invoice Count / Amount",
      },
      labels: {
        formatter: (val) => `${val}`,
      },
    },
    fill: {
      opacity: 1,
      colors: ["#008FFB", "#534894"],
    },
    tooltip: {
      enabled: true,
      shared: true, // Display both series in the tooltip
      intersect: false, // Tooltip will be shown on hover
      y: {
        formatter: (val) => `${val}`,
        title: {
          formatter: (seriesName) =>
            seriesName === "Total Invoice"
              ? "Total Invoice: "
              : "Total Amount: ",
        },
      },
    },
    legend: {
      show: false, // Hide legend if you only want tooltip to show data
    },
  };

  const chartSeries = [
    {
      name: "Total Invoice",
      data: monthlyInvoiceData,
    },
    {
      name: "Total Amount",
      data: monthlyAmountData,
    },
  ];

  return (
    <Card className="p-4 columchart">
      <h5 className="fw-bold">Invoice</h5>
      <div className="d-flex flex-wrap justify-content-between align-items-center my-3">
        <div className="d-flex flex-wrap">
          <p className="mb-0 fw-bold me-5">Total Invoice: {totalInvoice}</p>
          <p className="mb-0 fw-bold">Total Price: {totalAmount}</p>
        </div>
        <div className="d-flex flex-wrap align-item-center">
          <TextField
            select
            label="Company Name"
            value={selectedCompany}
            onChange={handleCompanyChange}
            className="me-3"
            size="small"
            style={{ width: "200px" }}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: menuStyles,
                },
              },
            }}
          >
            {AllClient &&
              AllClient.map((company) => (
                <MenuItem key={company._id} value={company.companyname}>
                  {company.companyname}
                </MenuItem>
              ))}
          </TextField>

          <TextField
            select
            label="Year"
            value={year}
            style={{ width: "130px" }}
            onChange={handleYearChange}
            size="small"
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: menuStyles,
                },
              },
            }}
          >
            {years.map((yearOption) => (
              <MenuItem key={yearOption} value={yearOption}>
                {yearOption}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </Card>
  );
};

export default ColumnChart;
