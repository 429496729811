const { func } = require("prop-types")

const Deliverynotechallantemplate = (outwardList, outwardTable, basicClientDetail) => {

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;

    const Tablerows = outwardTable?.map((row, index) => `
    <tr>   
        <td>${index + 1}</td>
        <td>${row?.partNo} ${row?.description}</td>
        <td>${row?.outwardQuantity}</td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
   
       
       `)?.join()


    return `
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<style>
    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
        padding: 10px;
        font-size: 12px
    }
</style>

<body>
    <table style="width: 100%;">
        <tr>
            <td rowspan="2" style="text-align: center;">
                <strong>ULTRA SERVICE FINISHERS</strong><br>New No.3, Welfare School Street,<br>Ayanambakkam,
                Chennai-600095<br>Ph.:9884043474 Mob.:9884565380<br>GSTIN No.:33AJWPP0088Q1Z9
            </td>
            <td rowspan="2" style="text-align: center;">
                <strong>DELIVERY NOTE CHALLAN</strong><br>JOB WORK / NOT FOR SALE / ONLY LABOUR WORKS
            </td>
            <td style="text-align: center;">
                CHALLAN NO : ${outwardList?.challanNo}<br>DATE:${formattedToday}
            </td>
        </tr>
        <tr>
            <td style="text-align: center;">ASSESSMENT YEAR<br/>${outwardList?.assessmentStartYear?.split('-')[0]} - ${outwardList?.assessmentEndYear?.split('-')[0]}</td>
        </tr>
        <tr>
            <td rowspan="4">
                CONSIGNEE<br/>
                ${basicClientDetail?.companyname},
                ${basicClientDetail?.doorNo},
                ${basicClientDetail?.streetName},
                ${basicClientDetail?.city},
                ${basicClientDetail?.pinCode}
            </td>
            <td>
                <span>
                    YOUR ORDER NO: ${outwardList?.yourOrderNo}
                </span>
            </td>
            <td>
                PURPOSE OF TRANSPORT<br>
                FOR LABOUR WORK / PROCESSING
            </td>
        </tr>
        <tr>
            <td>YOUR DC. NO: ${outwardList?.yourDcNo}</td>
            <td rowspan="2">VALUE OF GOODS<br/></td>
        </tr>
        <tr>
            <td>DATE: ${outwardList?.date?.split('T')[0]}</td>
        </tr>
        <tr>
            <td colspan="2">GSTIN: ${basicClientDetail?.gstNo}</td>
        </tr>
    </table>
    <table style="width: 100%; margin-top: 1%;">
        <tr>
            <td>S.NO.</td>
            <td>DESCRIPTION</td>
            <td>QTY.</td>
            <td>TOTAL WT.</td>
            <td>RATE UNIT/KG</td>
            <td>TOTAL AMOUNT</td>
        </tr>
        <tbody>
            ${Tablerows}
        </tbody>
        <tr>
            <td style="text-align: center;" colspan="6">
                E.& O.E., (PARTY'S MATERIAL BEING RETURNED AFTER PROCESSING / LABOUR ONLY)
            </td>
        </tr>
        <tr>
            <td colspan="2">MODE OF TRANSPORT</td>
            <td rowspan="2" colspan="2">RECEIVER'S SIGNATURE AND STAMP WITH DATE</td>
            <td  rowspan="2" colspan="2">FOR ULTRA SURFACE FINISHERS</td>
        </tr>
        <tr>
            <td colspan="2">VEHICLE NO: ${outwardList?.vehicleNo}</td>
        </tr>
    </table>
</body>

</html>`
}

export default Deliverynotechallantemplate;