import React, { useEffect, useState } from "react";
const StockReportPdf = ({
  companyName,
  partName,
  partNo,
  process,
  startDate,
  endDate,
  specialclients,
}) => {
  const [specialclientsArray, setSpecialClients] = useState([]);
  useEffect(() => {
    if (specialclients) {
      setSpecialClients([specialclients]);
    }
  }, [specialclients]);


  // let totalInwardQty = 0;
  // let totalInvoiceQty = 0;
  function reformatDate(inputDate) {
    const [year, month, day] = inputDate.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  console.log(specialclientsArray, "specialclientsArray");

  const calculateTotalInwardQty = (inwards) => {
    return inwards.reduce((total, inward) => {
      return total + (inward.selectedInward?.quantity || 0);
    }, 0);
  };

  // Function to calculate total invoice quantity
  const calculateTotalInvoiceQty = (invoices) => {
    return invoices.reduce((total, invoice) => {
      return total + (invoice.selectedInvoice?.invoiceQuantity || 0);
    }, 0);
  };


  const totalInwardQty = specialclientsArray.length > 0
    ? calculateTotalInwardQty(specialclientsArray[0].inwards)
    : 0;

  const totalInvoiceQty = specialclientsArray.length > 0
    ? calculateTotalInvoiceQty(specialclientsArray[0].invoices)
    : 0;

  // const combinedData = specialclientsArray[0]?.invoice.map(invoiceItem => {
  //   debugger
  //   const matchedInwardItem = inward?.find(inwardItem =>
  //     inwardItem.selectedInward._id === invoiceItem.selectedInvoice.outwardId
  //   );

  //   return {
  //     invoiceDate: invoiceItem.invoiceDoc.date,
  //     invoiceNo: invoiceItem.invoiceDoc.invoiceNo,
  //     selectedInvoice: invoiceItem.selectedInvoice,
  //     dcNo: matchedInwardItem?.inwardDoc.inwardDeliveryNoteChallan.dcNo,
  //     dcDate: matchedInwardItem?.inwardDoc.inwardDeliveryNoteChallan.dcDate,
  //     selectedInward: matchedInwardItem?.selectedInward
  //   };
  // });



  return (
    <>
      <div className="w-100">
        <style>
          {`
          
           table {  
        width: 100%; 
        table-layout: fixed;
      }  

      th, td {  
        border: 0.3px solid #272727;  
        border-collapse: collapse;  
        padding: 4px !important; 
        font-size: 8px !important; 
       white-space:wrap;  
      }  
       .no-break {
        page-break-inside: avoid;
      }

      td {  
        vertical-align: middle;  
      }  
          .bt {
            border-top: none !important;
          }

          .bb {
            border-bottom: none !important;
          }

          .bl {
            border-left: none !important;
          }

          .br {
            border-right: none !important;
          }
        `}
        </style>
        <table>
          <tr>
            <td colspan="13" className="text-center fw-bold text-uppercase">
              {companyName}
            </td>
          </tr>
          <tr colspan="13" className="w-100">
            <td colspan="6" className="w-50 bt bb br">
              Supplier Name : Ultra Surface Finishers
            </td>
            <td colspan="7" className="w-50 bt bb">
              STOCK :{reformatDate(startDate)} To {reformatDate(endDate)}
            </td>
          </tr>
          <tr className="w-100">
            <td colspan="6" className="w-50 br">
              Part Name
            </td>
            <td colspan="7" className="w-50">
              {partName}
            </td>
          </tr>
          <tr className="w-100">
            <td colspan="6" className="w-50 bt bb br">
              Process
            </td>
            <td colspan="7" className="w-50 bt bb">
              {process}
            </td>
          </tr>
          <tr className="w-100">
            <td colspan="6" className="w-50  br">
              Part No
            </td>
            <td colspan="7" className="w-50">
              {partNo}
            </td>
          </tr>
          <tr className="w-100">
            <td colspan="6" className="w-50 text-center bt bb br">
              Material Received From {companyName}
            </td>
            <td colspan="7" className="w-50 text-center bt bb">
              Material Despatched By Ultra Surface Finishers
            </td>
          </tr>
        </table>
        <table style={{ width: "100%" }} className="no-break">
          <thead>
            <tr className="no-break">
              <th style={{ width: "7.5%" }} className="br">
                Inward Date
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Inward DC No
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Part No
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Part Name
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Qty in No's
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Cumulative Qty in No's
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Invoice Date
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Outward Date
              </th>
              <th style={{ width: "7.5%" }} className="br">
                USF DC No
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Part Name
              </th>
              <th style={{ width: "7.5%" }} className="br">
                INV No
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Invoice Description
              </th>
              <th style={{ width: "7.5%" }} className="br">
                Qty in No
              </th>
              <th style={{ width: "7.5%" }}>Balance Qty</th>
            </tr>
          </thead>

          <tbody>
            {/* Find the maximum length between inwards and invoices */}
            {specialclientsArray.length > 0 &&
              Array.from(
                {
                  length: Math.max(
                    specialclientsArray[0].inwards.length,
                    specialclientsArray[0].invoices.length
                  ),
                },
                (_, index) => {
                  // Get the inward and invoice at the current index
                  const inward = specialclientsArray[0].inwards[index];
                  const invoice = specialclientsArray[0].invoices[index];

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        {/* Inward Data */}
                        <td style={{ width: "7.5%" }} className="bt br">
                          {inward?.inwardDoc?.inwardDeliveryNoteChallan?.dcDate
                            ? new Date(inward.inwardDoc.inwardDeliveryNoteChallan.dcDate).toLocaleDateString()
                            : ""}
                        </td>
                        <td
                          style={{
                            width: "7.5%",
                            whiteSpace: "wrap",
                            wordBreak: "break-all",
                          }}
                          className="bt br"
                        >
                          {inward?.inwardDoc?.inwardDeliveryNoteChallan?.dcNo || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {inward?.selectedInward?.partNo || ""}
                        </td>
                        <td
                          style={{
                            width: "7.5%",
                            whiteSpace: "wrap",
                            wordBreak: "break-all",
                          }}
                          className="bt br"
                        >
                          {inward?.selectedInward?.partName || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {inward?.selectedInward?.quantity || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {inward?.selectedInward?.quantity || ""}
                        </td>

                        {/* Invoice Data */}
                        <td style={{ width: "7.5%" }} className="bt br">
                          {invoice?.invoiceDoc?.date
                            ? new Date(invoice.invoiceDoc.date).toLocaleDateString()
                            : ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {invoice?.selectedInvoice?.usfDcDate
                            ? new Date(invoice.selectedInvoice.usfDcDate).toLocaleDateString()
                            : ""}
                        </td>
                        <td
                          style={{
                            width: "7.5%",
                            whiteSpace: "wrap",
                            wordBreak: "break-all",
                          }}
                          className="bt br"
                        >
                          {invoice?.selectedInvoice?.customerDCNo || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {invoice?.selectedInvoice?.partName || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {invoice?.invoiceDoc?.invoiceNo || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {invoice?.selectedInvoice?.description || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt br">
                          {invoice?.selectedInvoice?.invoiceQuantity || ""}
                        </td>
                        <td style={{ width: "7.5%" }} className="bt">
                          -
                          {/* {invoice?.selectedInvoice?.pendingQuantity || ""} */}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}

            {/* Total Row */}
            <tr>
              <td
                colSpan="5"
                className="bt br"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Total Inward Qty in No
              </td>
              <td
                colSpan="1"
                className="bt br"
                style={{ width: "7.5%", fontWeight: "bold" }}
              >
                {totalInwardQty || 0}
              </td>
              <td
                colSpan="6"
                className="bt br"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Total Invoice Qty in No
              </td>
              <td
                colSpan="1"
                className="bt br"
                style={{ width: "7.5%", fontWeight: "bold" }}
              >
                {totalInvoiceQty || 0}
              </td>
              <td className="bt"></td>
            </tr>
          </tbody>

        </table>

        <p className="my-4" style={{ fontSize: "8px" }}>
          Regards
        </p>

        <p className="text-uppercase"></p>
        <p className="text-uppercase" style={{ fontSize: "8px" }}>
          ultra Surface finisherss
        </p>
      </div>
    </>
  );
};
export default StockReportPdf;
