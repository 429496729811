import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../AllClients/AllClients.css";
import { patchData, postData } from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import BILLERAPI from "src/API_Routes/BillerAPI/BillerAPI";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { max } from "lodash";

function AddBiller({ HandleAddClient, action, AllBillerList, setTotalItems }) {
  const accessToken = sessionStorage.getItem("Token");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [role, setRole] = useState("Biller");
  const [address, setAddress] = useState("");
  const [billerDetails, SetBillerDetails] = useState();
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [errorMail, setErrorMail] = useState(false);
  const [helperTextMail, setHelperTextMail] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [helperTextPassword, setHelperTextPassword] = useState("");
  const specificBillerData = useSelector(
    (state) => state?.ALLBILLERLIST?.specificBillerList
  );

  //PhoneNumber validation
  const validatePhoneNumber = (number) => {
    if (number === "") {
      return true; // No error if the input is empty
    }
    const phoneNumberPattern = /^[0-9]{10}$/;
    return phoneNumberPattern.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setMobileNumber(value);
      if (validatePhoneNumber(value)) {
        setError(false);
        setHelperText("");
      } else {
        setError(true);
        setHelperText("Please enter a valid 10-digit mobile number");
      }
    }
  };

  //Mail Validation
  const validateEmail = (email) => {
    if (email === "") {
      return true; // No error if the input is empty
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (validateEmail(value)) {
      setErrorMail(false);
      setHelperTextMail("");
    } else {
      setErrorMail(true);
      setHelperTextMail("Please enter a valid email address");
    }
    setEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstName || firstName.trim() === "") {
      toast.error("First Name is required");
      return;
    }
    if (!lastName || lastName.trim() === "") {
      toast.error("Last Name is required");
      return;
    }
    if (!email || email.trim() === "") {
      toast.error("Email is required");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Please Enter A Valid Email Address");
      return;
    }

    if (!mobileNumber) {
      toast.error("Mobile Number is required");
      return;
    } else if (!validatePhoneNumber(mobileNumber)) {
      toast.error("Please Enter A Valid 10-digit Mobile Number");
      return;
    }
    if (!address || address.trim() === "") {
      toast.error("Address is required");
      return;
    }
    if ((!password && action === "Add") || password.trim() === "") {
      toast.error("Password is required");
      return;
    }
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

    if (!regex.test(password)) {
      toast.error(
        "Password must be at least 8 characters long, contain at least one number, one uppercase letter, one lowercase letter, and one special character."
      );
      return;
    }
    const params = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email,
      phoneNumber: mobileNumber,
      address: address,
      password: password,
      role: role,
    };

    if (action === "Edit") {
      patchData(`users/user/${specificBillerData._id}`, params, accessToken)
        .then((res) => {
          SetBillerDetails(res.data);
          HandleAddClient();
          console.log(billerDetails);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postData(BILLERAPI.ADDBILLER, params, accessToken)
        .then((res) => {
          SetBillerDetails(res.data);
          HandleAddClient();
          console.log(billerDetails);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    if (action === "Edit" || action === "View") {
      if (specificBillerData) {
        setFirstName(specificBillerData?.firstName);
        setLastName(specificBillerData?.lastName);
        setEmail(specificBillerData?.email);
        setMobileNumber(specificBillerData?.phoneNumber);
        setAddress(specificBillerData?.address);
        // setPassword(specificBillerData?.)
        setRole(specificBillerData?.role);
      }
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setMobileNumber("");
      setAddress("");
      setRole("Biller");
    }
  }, [action, specificBillerData]);

  return (
    <>
      {/* <ToastContainer position='top-right' /> */}
      <div className="p-5 card">
        <div className="component-title">
          <h5>{action} User</h5>
        </div>
        <div className="mb-5">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                className="w-100"
                id="outlined-basic"
                value={firstName}
                InputProps={{
                  readOnly: action === "View",
                }}
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name *"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-100"
                id="outlined-basic"
                InputProps={{
                  readOnly: action === "View",
                }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name *"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-100"
                id="outlined-basic"
                InputProps={{
                  readOnly: action === "View",
                }}
                value={email}
                onChange={handleEmailChange}
                label="Email Address *"
                variant="outlined"
                error={errorMail}
                helperText={helperTextMail}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-100"
                id="outlined-basic"
                type="number"
                value={mobileNumber}
                InputProps={{
                  readOnly: action === "View",
                }}
                onChange={handlePhoneNumberChange}
                label="Mobile No *"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                error={error}
                helperText={helperText}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="w-100"
                id="outlined-basic"
                InputProps={{
                  readOnly: action === "View",
                }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                label="Address *"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {action !== "View" && (
              <Grid item xs={4}>
                <TextField
                  className="w-100"
                  id="outlined-basic"
                  value={password}
                  onChange={(e) => {
                    const newPassword = e.target.value;
                    setPassword(newPassword);
                    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

                    if (newPassword === "") {
                      // If the TextField is empty, clear the error state and helper text
                      setErrorPassword(false);
                      setHelperTextPassword("");
                    } else if (!regex.test(newPassword)) {
                      // Set error state and helper text for invalid password
                      setErrorPassword(true);
                      setHelperTextPassword("Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.");
                    } else {
                      // Clear error state and helper text for valid password
                      setErrorPassword(false);
                      setHelperTextPassword("");
                    }
                  }}
                  label={action === "Add" ? "Password *" : "Password *"}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errorPassword}
                  helperText={helperTextPassword}
                />
              </Grid>

            )}
            {/* <Grid item xs={4}>
              <TextField
                className="w-100"
                disabled
                id="outlined-basic"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                label="Role"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
          </Grid>
        </div>

        <div className="d-flex justify-content-end mt-5">
          {action === "Add" && (
            <>
              <Button
                variant="outlined"
                onClick={HandleAddClient}
                className="btn-secondary"
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="ml-8 btn-primary"
              >
                ADD USER
              </Button>
            </>
          )}
          {action === "Edit" && (
            <>
              <Button
                variant="outlined"
                onClick={HandleAddClient}
                className="btn-secondary"
              >
                BACK
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                className="ml-8 btn-primary"
              >
                UPDATE
              </Button>
            </>
          )}
          {action === "View" && (
            <Button
              variant="outlined"
              onClick={HandleAddClient}
              className="btn-secondary"
            >
              BACK
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default AddBiller;
