import React, { useEffect, useState, useRef } from "react";
import "../AllClients/AllClients.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import Iconify from "../iconify";
import AddClient from "../AllClients/AddClient";
import Addoutward from "./addoutward";
import { getData, patchData, postData } from "src/webService/webService";
import { deleteData } from "src/webService/webService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { setALLOUTWARDLIST } from "src/redux/Slices/AllOutwardSlices";
import OUTWARDAPI from "src/API_Routes/OutwardAPI/OutwardAPI";
import html2pdf from "html2pdf.js";
import Deliverynotechallantemplate from "../templates/deliverynotechallan";
import Invoice from "../Invoice/Invoice";
import OutwardFilter from "../filter/outwardfilter";
import Feedback from "../Qualityreports/qualityReport";
import useAllClients from "../CommonFunction/GetallClients";
import ReportTemplate from "../templates/deliveryChallan";
// import {  } from 'react';
import jsPDF from "jspdf";
import QUALITYREPORTAPI from "../../API_Routes/QualityReport/QualityReportAPI";

import outwardLogo from "../logo/outbound.png";
import MultipleInvoice from "../Invoice/MultipleInvoice";

const Outward = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [TotalItems, setTotalItems] = useState();
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [action, setAction] = useState("");
  const [addClient, setAddClient] = useState(true);
  const [qualityReport, setQualityReport] = useState(true);
  const accessToken = sessionStorage.getItem("Token");
  const [outwardDetails, SetOutwardDetails] = useState();
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState();
  const [totalPages, setTotalPages] = useState(1);
  const [billId, setBillId] = useState();
  const [clientId, setClientId] = useState();
  const [index, setIndex] = useState();
  const [outwardList, setOutwardList] = useState();
  const [outwardTable, setOutwardTable] = useState([]);
  const [basicClientDetail, setBasicClientDetail] = useState();
  const [specificOutwardList, setSpecificOutwardList] = useState();
  const [outwardListMultiple, setOutwardListMultiple] = useState([]);
  const [isEnabledBulkInvoice, setIsEnabledBulkInvoice] = useState(false);
  //For Search Filter
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [companyName, setCompanyName] = useState();
  const [branch, setBranch] = useState();
  const [company, setCompany] = useState();
  const [reset, setReset] = useState(false);
  //For Search Filter
  const SPECIFICOUTWARD = useSelector(
    (state) => state?.ALLINWARDLIST?.specificInwardList
  );
  const SPECIFICOUTWARDLIST = useSelector(
    (state) =>
      state?.ALLINWARDLIST?.specificInwardList?.outwardDeliveryNoteChallan
  );
  const ALLOUTWARDLIST = useSelector(
    (state) => state?.ALLOUTWARDLIST?.AllOutwardList?.[0]?.paginatedResults
  );
  console.log(ALLOUTWARDLIST, "specificoutward");
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
    unselectAllCheckboxes();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const [specificOutwards, setSpecificOutward] = useState([]);
  const [isSpecificOutwardsEmpty, setIsSpecificOutwardsEmpty] = useState(false);

  const specificOutward = async (billId) => {
    try {
      const res = await getData(
        QUALITYREPORTAPI.GETONEQUALITYREPORT(billId),
        null,
        accessToken
      );
      if (res.status === 200) {
        setSpecificOutward(res.data.results);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setIsSpecificOutwardsEmpty(specificOutwards.length === 0);
  }, [specificOutwards]);

  const handleOpenMenu = (event, billId, clientId, index) => {
    setOpen(event.currentTarget);
    setBillId(billId);
    setClientId(clientId);
    setIndex(index);
    GenerateOutward(billId);
    specificBillerData(clientId);
    specificOutward(billId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const specificBillerData = async (userId) => {
    try {
      const res = await getData(`clients/client/${userId}`, null, accessToken);
      if (res.status === 200) {
        setBasicClientDetail(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const GenerateOutward = async (id) => {
    try {
      const res = await getData(`outward/outward/${id}`, null, accessToken);
      if (res.status === 200) {
        setOutwardList(res.data.outwardDeliveryNoteChallan[0]);
        setOutwardTable(res.data.outwardDeliveryNoteChallan[0].outwardTable);
        setSpecificOutwardList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  const [outwardIds, setOutwardIds] = useState();

  const validateBulkInvoice = () => {
    const outwardIds = Object.keys(isChecked).filter((id) => isChecked[id]);
    setOutwardIds(outwardIds);
    const clientIds = ALLOUTWARDLIST?.filter((item) =>
      outwardIds.includes(item._id)
    ).map((item) => item.clientId);
    const validQuantity = ALLOUTWARDLIST?.filter((item) =>
      outwardIds.includes(item._id)
    )
      .flatMap((item) => item.outwardDeliveryNoteChallan)
      .every((row) => row.remainingValueOfGoods > 0);
    const uniqueClients = new Set(clientIds);
    setIsEnabledBulkInvoice(
      uniqueClients.size == 1 && outwardIds.length > 1 && validQuantity
        ? true
        : false
    );
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};
    ALLOUTWARDLIST.forEach((row) => {
      updatedIsChecked[row?._id] = checked;
    });
    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  const HandleOutward = () => {
    unselectAllCheckboxes();
    setAddClient(!addClient);
  };

  useEffect(() => {
    if (addClient) {
      AllOutwardList(currentPage);
    }
  }, [addClient]);

  const handleQuality = () => {
    setQualityReport(!qualityReport);
  };

  const AllOutwardList = async (page) => {
    if (startDate && !endDate) {
      toast.error("End date is required when start date is selected");
      return;
    }
    setReset(false);
    const params = {
      page: page,
      limit: limit,
      companyName: companyName ? companyName : "",
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      branch: branch ? branch : "",
    };
    try {
      const res = await getData(OUTWARDAPI.ALLGETOUTWARD, params, accessToken);
      if (res.status === 200) {
        dispatch(setALLOUTWARDLIST(res.data));
        setTotalPages(res.data[0].totalPages);
        if (res.data[0].paginatedResults.length == 0) {
          setTotalItems(0);
        } else {
          setTotalItems(res.data[0].totalCount.totalItems);
        }

        console.log(SPECIFICOUTWARDLIST, "Allclientslist");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    AllOutwardList(currentPage);
  }, [currentPage, limit, SPECIFICOUTWARD]);

  // useEffect(() => { // For Temp
  //   setBillId( '66b202aba7e674fa1fb2b196');
  //   setClientId('66b1ef29c694c581cd79ea0a');
  //   setIndex(0);
  //   GenerateOutward( '66b202aba7e674fa1fb2b196');
  //   specificBillerData('66b1ef29c694c581cd79ea0a');
  //   setTimeout(
  //     () => setAddClient(false),
  //     500
  //   );
  // }, []);

  const formatDate = (isoString) => {
    return isoString.split("T")[0];
  };

  const handleBulkInvoiceGenerate = () => {
    const outwardIds = Object.keys(isChecked).filter((id) => isChecked[id]);
    console.log(outwardIds);
    postData(OUTWARDAPI.GETBULKOUTWARD, { outwardids: outwardIds }, accessToken)
      .then((response) => {
        console.log(response.data, "response");
        if (response.data.length > 0) {
          specificBillerData(response.data[0].clientId);
          setBillId(response.data[0]._id);
          setOutwardListMultiple(response.data);
          HandleOutward();
        } else {
          toast.error("Invoice not available");
          return;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleExport = () => {
    const hasSelected = Object.values(isChecked).some((checked) => checked);
    if (!hasSelected) {
      toast.error("Select Outward Check Box");
      return;
    }
    let headers = [
      "Company Name",
      "Customer DC No",
      "DC Date",
      "Assessment Start Year",
      "Assessment End Year",
      "Consignee",
      "Your Order No",
      "USF DC No",
      "Value Of Goods",
      "Vehicle No",
      "Part Name",
      "Part No",
      "Description",
      "Outward Quantity",
    ];

    const checkedItems = ALLOUTWARDLIST.filter((item) => isChecked[item._id]);

    let data = [];

    checkedItems.forEach((item) => {
      const getYear = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear();
      };

      const commonData = [
        item?.outwardDeliveryNoteChallan[0]?.companyName,
        item?.outwardDeliveryNoteChallan[0]?.dcNo,
        item?.outwardDeliveryNoteChallan[0]?.date
          ? formatDate(item?.outwardDeliveryNoteChallan[0]?.date)
          : "",
        getYear(item?.outwardDeliveryNoteChallan[0]?.assessmentStartYear),
        getYear(item?.outwardDeliveryNoteChallan[0]?.assessmentEndYear),
        item?.outwardDeliveryNoteChallan[0]?.consignee,
        item?.outwardDeliveryNoteChallan[0]?.yourOrderNo,
        item?.outwardDeliveryNoteChallan[0]?.yourDcNo,
        item?.outwardDeliveryNoteChallan[0]?.valueOfGoods,
        item?.outwardDeliveryNoteChallan[0]?.vehicleNo,
      ];

      item?.outwardDeliveryNoteChallan[0]?.outwardTable.forEach((outward) => {
        data.push([
          ...commonData,
          outward.partName,
          outward.partNo,
          outward.description,
          outward.outwardQuantity,
        ]);
      });
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Outward Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "Outward_list_data.xlsx"
    );
    unselectAllCheckboxes();
  };

  // const handleExport = () => {
  //   const hasSelected = Object.values(isChecked).some((checked) => checked);
  //   if (!hasSelected) {
  //     toast.error("Select Check Box");
  //     return;
  //   }
  //   const headers = [
  //     "Company Name",
  //     "Customer DC No",
  //     "DC Date",
  //     "Assessment Start Year",
  //     "Assessment End Year",
  //     "Consignee",
  //     "Your Order No",
  //     "USF DC No",
  //     "Value Of Goods",
  //     "Vehicle No",
  //   ].filter(Boolean);
  //   console.log(ALLOUTWARDLIST, "ALLOUTWARDLIST");

  //   // Filter checked items
  //   const checkedItems = ALLOUTWARDLIST.filter((item) => isChecked[item._id]);
  //   let data;
  //   data = checkedItems.map((item) => {
  //     const getYear = (dateString) => {
  //       const date = new Date(dateString);
  //       return date.getFullYear();
  //     };
  //     return [
  //       item?.outwardDeliveryNoteChallan[0]?.companyName,
  //       item?.outwardDeliveryNoteChallan[0]?.dcNo,
  //       item?.outwardDeliveryNoteChallan[0]?.date
  //         ? formatDate(item?.outwardDeliveryNoteChallan[0]?.date)
  //         : "",
  //       getYear(item?.outwardDeliveryNoteChallan[0]?.assessmentStartYear),
  //       getYear(item?.outwardDeliveryNoteChallan[0]?.assessmentEndYear),
  //       item?.outwardDeliveryNoteChallan[0]?.consignee,
  //       item?.outwardDeliveryNoteChallan[0]?.yourOrderNo,
  //       item?.outwardDeliveryNoteChallan[0]?.yourDcNo,
  //       item?.outwardDeliveryNoteChallan[0]?.valueOfGoods,
  //       item?.outwardDeliveryNoteChallan[0]?.vehicleNo,
  //     ];
  //   });
  //   const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Outward Data");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   saveAs(
  //     new Blob([excelBuffer], { type: "application/octet-stream" }),
  //     "Outward_list_data.xlsx"
  //   );
  //   unselectAllCheckboxes();
  // };

  //   search Filter
  const { clientDetails, totalItems, fetchAllClients } = useAllClients();
  useEffect(() => {
    if (showFilter) {
      fetchAllClients();
    }
  }, [showFilter]);

  // Function to handle company selection
  const handleCompanyChanges = (event) => {
    const companyName = event.target.value;
    const company = clientDetails?.find((c) => c._id === companyName);
    setCompanyName(company.companyname);
    setCompany(company._id);
    setBranch(company?.branch);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    AllOutwardList(1);
  };

  const removeData = () => {
    setCompanyName();
    setCompany();
    setStartDate();
    setEndDate();
    setReset(true);
    setBranch();
  };

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
      AllOutwardList(1);
    }
  }, [reset]);

  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  const unselectAllCheckboxes = () => {
    const updatedIsChecked = {};
    ALLOUTWARDLIST?.forEach((row) => {
      updatedIsChecked[row._id] = false;
    });
    setIsChecked(updatedIsChecked);
    setSelectAllChecked(false);
  };

  useEffect(() => {
    validateBulkInvoice();
  }, [isChecked]);

  useEffect(() => {
    if (showFilter) {
      unselectAllCheckboxes();
    } else {
      unselectAllCheckboxes();
    }
  }, [showFilter]);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "landscape",
    });

    // Adding the fonts.
    doc.setFont("sans-serif", "normal");

    const content = reportTemplateRef.current;
    const scale = 0.8; // Adjust this value to fit your content properly

    doc.html(content, {
      html2canvas: {
        scale: scale, // Use a higher scale factor for better quality
        width: 695, // Width of A4 in px (jsPDF uses px units)
      },
      callback: function (doc) {
        doc.save("Outward delivery Challan.pdf");
      },
      x: 15,
      y: 15,
      width: 1075, // Adjust this value as necessary
    });
  };

  const handlePrint = () => {
    const printContent = reportTemplateRef.current;
    const originalContent = document.body.innerHTML;
    // Set the document's body to only include the printContent
    document.body.innerHTML = printContent.innerHTML;
    // Trigger the print dialog
    window.print();
    // Restore the original document content
    document.body.innerHTML = originalContent;
    // Reattach the React component to the DOM
    window.location.reload();
  };

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />

      {qualityReport ? (
        <>
          {addClient ? (
            <>
              <div className="pt-3 pb-4 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center col-4">
                  <img src={outwardLogo} width="6%" className="mr-2" />
                  <p className="fw-bold mb-0 color-dark mt-2">
                    Total Outwards : {TotalItems}
                  </p>
                </div>
                <div className="col-8 d-flex justify-content-end align-items-center">
                  {showFilter && (
                    <span
                      className="me-2 danger"
                      style={{
                        color: "red",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        toggleFilter();
                        removeData();
                      }}
                    >
                      Clear Filter
                    </span>
                  )}
                  <IconButton
                    className="p-12 ftrbkgd rounded"
                    onClick={(e) => {
                      toggleFilter();
                      removeData();
                    }}
                  >
                    {showFilter ? (
                      <Iconify
                        icon="ri:filter-off-fill"
                        className="color-dark"
                      />
                    ) : (
                      <Iconify
                        icon="material-symbols:filter-alt-sharp"
                        className="color-dark"
                      />
                    )}
                  </IconButton>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleExport}
                    className="ml-8 btn-secondary"
                  >
                    EXPORT
                  </Button>
                  <Tooltip
                    placement="top"
                    title={
                      !isEnabledBulkInvoice
                        ? outwardIds?.length < 1
                          ? "Please select the atleast two outwards"
                          : "Please select outward with same company name and with available quantity"
                        : ""
                    }
                  >
                    <span>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ cursor: "pointer" }}
                        onClick={
                          isEnabledBulkInvoice
                            ? handleBulkInvoiceGenerate
                            : null
                        }
                        disabled={!isEnabledBulkInvoice}
                        className="ml-8 btn-secondary cursor-pointer"
                      >
                        BULK INVOICE GENERATE
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div>
                {showFilter && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ minWidth: 500, margin: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              className="w-100"
                              type="date"
                              label="DC Start Date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              className="w-100"
                              type="date"
                              label="DC End Date"
                              value={endDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                min: startDate,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>

                      <Grid item xs={3}>
                        <FormControl
                          sx={{ margin: 1, minWidth: 278 }}
                          size="large"
                          className="select"
                        >
                          <InputLabel id="company-name-label">
                            Company Name
                          </InputLabel>
                          <Select
                            labelId="company-name-label"
                            id="company-name-select"
                            label="Company Name"
                            value={company}
                            onChange={handleCompanyChanges}
                          >
                            {clientDetails &&
                              clientDetails?.map((company) => (
                                <MenuItem
                                  key={company._id}
                                  value={company._id}
                                >
                                  {company.companyname}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <div>
                        <Button
                          variant="outlined"
                          className="ml-8 btn-secondary"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <TableContainer style={{ height: "366px", overflowY: "scroll" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="table-cl color-dark"
                        style={{ padding: "8px 16px" }}
                      >
                        <Checkbox
                          className="color-white"
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                      <TableCell className="table-cl" align="center">
                        S.No
                      </TableCell>
                      <TableCell className="table-cl">Company Name</TableCell>
                      <TableCell className="table-cl">Customer DC No</TableCell>
                      <TableCell className="table-cl">DC Date</TableCell>
                      <TableCell className="table-cl">USF Order No</TableCell>
                      <TableCell className="table-cl">USF DC No</TableCell>
                      <TableCell className="table-cl">Outward Qty</TableCell>
                      <TableCell className="table-cl">
                        Invoice Pending Qty
                      </TableCell>
                      <TableCell className="table-cl">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ALLOUTWARDLIST &&
                      ALLOUTWARDLIST.map((row, index) => (
                        <TableRow key={index} className="padding-8">
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isChecked[row._id] || false}
                              onChange={(event) =>
                                handleCheckboxChange(event, row._id)
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {calculateIndex(index)}
                          </TableCell>
                          <TableCell
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "200px",
                            }}
                          >
                            {row?.outwardDeliveryNoteChallan[0]?.companyName}
                          </TableCell>
                          <TableCell>
                            {row?.outwardDeliveryNoteChallan?.[0]?.dcNo || "-"}
                          </TableCell>
                          <TableCell>
                            {row?.outwardDeliveryNoteChallan[0]?.date
                              ?.split("T")[0]
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")}
                          </TableCell>
                          <TableCell align="center">
                            {row?.outwardDeliveryNoteChallan?.[0]
                              ?.yourOrderNo || "-"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.outwardDeliveryNoteChallan[0]?.yourDcNo}
                          </TableCell>
                          <TableCell align="center">
                            {row?.outwardDeliveryNoteChallan[0]?.valueOfGoods}
                          </TableCell>
                          <TableCell align="center">
                            {
                              row?.outwardDeliveryNoteChallan[0]
                                ?.remainingValueOfGoods
                            }
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) =>
                                handleOpenMenu(
                                  event,
                                  row._id,
                                  row.clientId,
                                  index
                                )
                              }
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}

                    {(!ALLOUTWARDLIST || ALLOUTWARDLIST.length === 0) && (
                      <TableRow>
                        <TableCell colSpan={10} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "5%" }}
                >
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={TotalItems}>All</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 230,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenDialog(null);
                    handleOpen();
                  }}
                >
                  <Iconify icon={"carbon:download"} sx={{ mr: 2 }} />
                  Generate Outward PDF
                </MenuItem>

                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  maxWidth="lg"
                  fullWidth
                >
                  <DialogTitle>Outward Delivery Challan</DialogTitle>
                  <DialogContent>
                    <div ref={reportTemplateRef} style={{ width: "80.7%" }}>
                      <ReportTemplate
                        outwardList={outwardList}
                        outwardTable={outwardTable}
                        basicClientDetail={basicClientDetail}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      size="small"
                      className="ml-8 btn-secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      size="small"
                      className="ml-8 btn-primary"
                      onClick={() => {
                        handlePrint();
                      }}
                      variant="contained"
                    >
                      Print
                    </Button>
                    {/* <Button
                      size="small"
                      className="ml-8 btn-primary"
                      onClick={() => {
                        handleGeneratePdf();
                        handleClose();
                        setOpen();
                      }}
                      variant="contained"
                    >
                      Download Generate PDF
                    </Button> */}
                  </DialogActions>
                </Dialog>
                {isSpecificOutwardsEmpty ? (
                  <MenuItem
                    onClick={() => {
                      setOpen(null);
                      handleQuality();
                    }}
                  >
                    <Iconify
                      icon={"icon-park-outline:table-report"}
                      sx={{ mr: 2 }}
                    />
                    Generate Quality Report
                  </MenuItem>
                ) : (
                  ""
                )}
                {index != null &&
                ALLOUTWARDLIST[index]?.outwardDeliveryNoteChallan[0]
                  ?.remainingValueOfGoods > 0 ? (
                  <MenuItem
                    onClick={() => {
                      setOutwardListMultiple([]);
                      setOpen(null);
                      HandleOutward();
                    }}
                  >
                    <Iconify icon={"uil:invoice"} sx={{ mr: 2 }} />
                    Generate Invoice
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Popover>
            </>
          ) : outwardListMultiple.length > 0 ? (
            <MultipleInvoice
              HandleOutward={HandleOutward}
              basicClientDetail={basicClientDetail}
              outwardList={outwardListMultiple}
              billId
            />
          ) : (
            <Invoice
              HandleOutward={HandleOutward}
              basicClientDetail={basicClientDetail}
              outwardTable={outwardTable}
              outwardList={[outwardList]}
              specificOutwardList={specificOutwardList}
              action={action}
              billId={billId}
            />
          )}
        </>
      ) : (
        <Feedback
          handleQuality={handleQuality}
          basicClientDetail={basicClientDetail}
          outwardTable={outwardTable}
          outwardList={outwardList}
        />
      )}
    </Paper>
  );
};

export default Outward;
