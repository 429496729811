import { createSlice } from "@reduxjs/toolkit";

const ALLINWARDLIST = createSlice({
  name: "ALLINWARDLIST",
  initialState: {
    AllInwardList: "",
    specificInwardList: ""
  },
  reducers: {
    setALLINWARDLIST: (state, action) => {
      state.AllInwardList = action.payload;
    },
    setSpecificInwardDetails: (state, action) => {
      state.specificInwardList = action.payload;
    },
  },
});

export const { setALLINWARDLIST, setSpecificInwardDetails } = ALLINWARDLIST.actions;
export default ALLINWARDLIST.reducer;
