import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  TextField,
  MenuItem,
  Card,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
// sections
import {
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from "../sections/@dashboard/app";
import { useEffect, useState } from "react";
import CLIENTAPI from "src/API_Routes/ClientAPI/ClientAPI";
import { getData } from "src/webService/webService";
import { toast, ToastContainer } from "react-toastify";
import INWARDAPI from "src/API_Routes/InwardAPI/InwardAPI";
import OUTWARDAPI from "src/API_Routes/OutwardAPI/OutwardAPI";
import PurchaseInventoryAPI from "src/API_Routes/PurchaseInventoryAPI/PurchaseInventoryAPI";
import DASHBOARDAPI from "../API_Routes/DashboardAPI/DashboardAPI";
import useFetchData from "../components/GetAllData/GetAllData";
import ColumnChart from "../components/Dashboard/InvoiceChart";
import clientLogo from "../../src/components/logo/rating.png";
import inwardLogo from "../../src/components/logo/inbound (1).png";
import outwardLogo from "../../src/components/logo/outbound.png";
import purchaseLogo from "../../src/components/logo/purchaseIcon.png";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const accessToken = sessionStorage.getItem("Token");
  const [clientCount, setClientCount] = useState();
  const [inwardCount, setInwardCount] = useState();
  const [outwardCount, setOutwardCount] = useState();
  const [data, setData] = useState();
  const [purchaseInventory, setPurchaseInventory] = useState();
  const [groupedData, setGroupedData] = useState();
  const [clientGraph, setClientGraph] = useState();
  const [outwardGraph, setOutwardGraph] = useState();
  const [outward, setOutward] = useState();
  const [firstapi, setFirstAPI] = useState(true);
  const [selectedcompanyDetails, setselectedCompanyDetails] = useState();
  const [partName, setPartName] = useState("");
  const [partNo, setPartNo] = useState("");
  const [process, setProcess] = useState("");

  // getallclient
  const [AllClient, allClientTotal, setAllClientTotal] = useFetchData(
    CLIENTAPI.GETCLIENTS,
    accessToken
  );

  useEffect(() => {
    setSelectedCompany(AllClient?.[0]?.companyname);
    setselectedCompanyDetails(AllClient?.[0]);
    setPartName(AllClient?.[0]?.product?.[0]?.partName);
    setPartNo(AllClient?.[0]?.product?.[0]?.partNo);
    setProcess(AllClient?.[0]?.product?.[0]?.productName);
  }, [AllClient]);

  useEffect(() => {
    if (allClientTotal > 1000) setAllClientTotal(allClientTotal);
  }, [allClientTotal]);

  const theme = useTheme();

  const AllClientsList = async (page) => {
    const params = {
      page: page,
      limit: null,
    };
    try {
      const res = await getData(CLIENTAPI.GETCLIENTS, params, accessToken);
      if (res.status === 200) {
        setClientCount(res.data.totalItems);
        // setData(res.data.results)
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {});
    }
  };

  const AllInwardList = async (page) => {
    const params = {
      page: 1,
      limit: 1000000000,
    };
    try {
      const res = await getData(INWARDAPI.GETALLBILL, params, accessToken);
      if (res.status === 200) {
        setInwardCount(res.data[0].totalCount.totalItems);
        setData(res.data[0].paginatedResults);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const AllOutwardList = async (page) => {
    const params = {
      page: 1,
      limit: 10000,
    };
    try {
      const res = await getData(OUTWARDAPI.ALLGETOUTWARD, params, accessToken);
      if (res.status === 200) {
        setOutwardCount(res.data[0].totalCount.totalItems);
        setOutwardGraph(res.data[0].paginatedResults);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {});
    }
  };

  const AllpurchaseInventoryList = async () => {
    try {
      const res = await getData(
        PurchaseInventoryAPI.GETPURCHASE,
        {},
        accessToken
      );
      if (res.status === 200) {
        setPurchaseInventory(res.data.results);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    const groupByMonth = (data) => {
      const grouped = Array.from({ length: 12 }, (_, index) => ({
        month: index + 1,
        items: [],
      }));

      data?.forEach((item) => {
        const month = new Date(item.updatedAt.split("T")[0]).getMonth(); // getMonth() returns 0-11
        grouped[month].items.push(item);
      });

      return grouped;
    };

    if (data) {
      const groupedInward = groupByMonth(data);
      setGroupedData(groupedInward);

      // Log the counts to the console as an array
      const inwardCounts = groupedInward.map(({ items }) => items.length);
      setClientGraph(inwardCounts);
    }

    if (outwardGraph) {
      const groupedOutward = groupByMonth(outwardGraph);
      setGroupedData(groupedOutward);

      // Log the counts to the console as an array
      const outwardCounts = groupedOutward.map(({ items }) => items.length);
      setOutward(outwardCounts);
    }
  }, [data, outwardGraph]);

  const totalPrice = purchaseInventory?.reduce(
    (acc, item) => acc + item.price,
    0
  );

  const [totalcount, setTotalCount] = useState();
  // const [InwardCountYears, setInwardCountYears] = useState();
  // const [OutwardCountYears, setOutwardCountYears] = useState();
  // const generateMonthLabels = (year) => {
  //   const labels = [];
  //   for (let month = 1; month <= 12; month++) {
  //     labels.push(`${String(month).padStart(2, "0")}/01/${year}`);
  //   }
  //   console.log("labels", labels);
  //   return labels;
  // };
  const generateMonthLabels = (year) => {
    const labels = [];
    for (let month = 1; month <= 12; month++) {
      // Format date as YYYY-MM-DD
      labels.push(`${year}-${String(month).padStart(2, "0")}-01`);
    }
    return labels;
  };

  const generateYears = (startYear, numberOfYears) => {
    const years = [];
    for (let year = startYear; year <= startYear + numberOfYears; year++) {
      console.log(`Adding year: ${year}`);
      years.push(year);
    }
    return years;
  };

  const [year, setYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  useEffect(() => {
    const startYear = 2000;
    const numberOfYears = 100;
    setYears(generateYears(startYear, numberOfYears));
  }, []);

  const [inwardData, setInwardData] = useState(Array(12).fill(0));
  const [inwardTotal, setInwardTotal] = useState();
  const [outwardTotal, setOutWardTotal] = useState();
  const [outwardData, setOutwardData] = useState(Array(12).fill(0));
  const [chartLabels, setChartLabels] = useState(generateMonthLabels(2024));
  const [selectedCompany, setSelectedCompany] = useState("");

  console.log(AllClient, "AllClient");

  console.log("Inward Data:", inwardData);
  console.log("Outward Data:", outwardData);

  const fetchTotalcount = async () => {
    try {
      const res = await getData(DASHBOARDAPI.TOTALCOUNT, {}, accessToken);
      if (res.status === 200) {
        setTotalCount(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchInWardCount = async (
    selectedYear,
    company,
    partnamedata,
    partnodata,
    processdata
  ) => {
    const body = {
      year: selectedYear,
      companyName: company,
      partName: partnamedata,
      partNo: partnodata,
      process: processdata,
    };
    try {
      setFirstAPI(false);
      const res = await getData(DASHBOARDAPI.INWARDCOUNT, body, accessToken);
      setInwardTotal(res.data);
      if (res.status === 200 && res.data.months && res.data.months.length > 0) {
        const monthlyData = Array(12).fill(0);
        res.data.months.forEach((monthData) => {
          monthlyData[monthData.month - 1] = monthData.quantity;
        });
        setInwardData(monthlyData);
      } else {
        setInwardData([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setInwardData([]);
    }
  };

  const fetchOutWardCount = async (
    selectedYear,
    company,
    partnamedata,
    partnodata,
    processdata
  ) => {
    const body = {
      year: selectedYear,
      companyName: company,
      partName: partnamedata,
      partNo: partnodata,
      process: processdata,
    };
    try {
      setFirstAPI(false);
      const res = await getData(DASHBOARDAPI.OUTWARDCOUNT, body, accessToken);
      setOutWardTotal(res.data);
      if (res.status === 200 && res.data.months && res.data.months.length > 0) {
        const monthlyData = Array(12).fill(0);
        res.data.months.forEach((monthData) => {
          monthlyData[monthData.month - 1] = monthData.outwardQuantity;
        });
        setOutwardData(monthlyData);
      } else {
        setOutwardData([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setOutwardData([]);
    }
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
    setChartLabels(generateMonthLabels(selectedYear));
    fetchInWardCount(selectedYear, selectedCompany, partName, partNo, process);
    fetchOutWardCount(selectedYear, selectedCompany, partName, partNo, process);
  };

  useEffect(() => {
    if (year && selectedCompany && partName && partNo && process && firstapi) {
      fetchInWardCount(year, selectedCompany, partName, partNo, process);
      fetchOutWardCount(year, selectedCompany, partName, partNo, process);
    }
  }, [year, selectedCompany, partName, partNo, process]);

  const handleSelectionChange = (event) => {
    const selectedPartName = event.target.value;
    const selectedProduct = selectedcompanyDetails.product.find(
      (product) => product.partName === selectedPartName
    );

    if (selectedProduct) {
      setPartName(selectedPartName);
      setPartNo(selectedProduct.partNo);
      setProcess(selectedProduct.productName);
    }
    fetchInWardCount(
      year,
      selectedCompany,
      selectedPartName,
      selectedProduct.partNo,
      selectedProduct.productName
    );
    fetchOutWardCount(
      year,
      selectedCompany,
      selectedPartName,
      selectedProduct.partNo,
      selectedProduct.productName
    );
  };

  // useEffect(() => {
  //   if (selectedCompany) {
  //     fetchInWardCount(year, selectedCompany);
  //     fetchOutWardCount(year, selectedCompany);
  //   }
  // }, [selectedCompany]);

  useEffect(() => {
    fetchTotalcount();
  }, []);

  const menuStyles = {
    marginTop: 10,
    maxHeight: 200,
  };

  const handleCompanyChange = (event) => {
    const selectedCompanyName = event.target.value;
    setSelectedCompany(selectedCompanyName);
    const company = AllClient?.find(
      (c) => c.companyname === selectedCompanyName
    );
    setselectedCompanyDetails(company);
    toast.warning("Select the part details");

    // Call the APIs with the selected year and company
    // fetchInWardCount(year, selectedCompanyName, "", "", "");
    // fetchOutWardCount(year, selectedCompanyName, "", "", "");
  };

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <ToastContainer position="top-right" />

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Customer"
              total={totalcount?.clientCount || 0}
              icon={clientLogo}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Inward"
              total={totalcount?.inwardCount || 0}
              color="info"
              icon={inwardLogo}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Outward"
              total={totalcount?.outwardCount || 0}
              color="warning"
              icon={outwardLogo}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Purchase Price"
              total={totalcount?.totalAmount || 0}
              color="error"
              icon={purchaseLogo}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card className="p-4">
              <h5 className="fw-bold">Inward / Outward </h5>
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <p className="mb-0 fw-bold me-5">
                    Total Inward : {inwardTotal?.total || "0"}
                  </p>

                  <p className="mb-0 fw-bold">
                    Total Outward : {outwardTotal?.total || "0"}
                  </p>
                </div>
                <div className="d-flex flex-wrap align-item-center">
                  <TextField
                    select
                    label="Company Name"
                    value={selectedCompany}
                    className="me-3"
                    onChange={handleCompanyChange}
                    size="small"
                    style={{ width: "200px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: menuStyles,
                        },
                      },
                    }}
                  >
                    {AllClient &&
                      AllClient.map((company) => (
                        <MenuItem key={company._id} value={company.companyname}>
                          {company.companyname}
                        </MenuItem>
                      ))}
                  </TextField>

                  <Grid>
                    <FormControl
                      sx={{ width: "280px" }}
                      style={{ marginRight: "4px" }}
                      size="large"
                      className="select"
                    >
                      <InputLabel id="product-details">
                        Part Name / Part No / Process
                      </InputLabel>
                      <Select
                        labelId="product-details"
                        id="product-details"
                        label="Part Name / Part No / Process"
                        fullWidth
                        variant="outlined"
                        name="partName"
                        className="w-100"
                        value={partName}
                        onChange={handleSelectionChange}
                      >
                        {selectedcompanyDetails?.product?.map((product, i) => (
                          <MenuItem key={i} value={product?.partName}>
                            {product?.partName} / {product?.partNo} /{" "}
                            {product?.productName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <TextField
                    select
                    label="Year"
                    value={year}
                    onChange={handleYearChange}
                    size="small"
                    style={{ width: "130px" }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: menuStyles,
                        },
                      },
                    }}
                  >
                    {years.map((yearOption) => (
                      <MenuItem key={yearOption} value={yearOption}>
                        {yearOption}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              <AppWebsiteVisits
                title="Project Tracker"
                chartLabels={chartLabels}
                chartData={[
                  {
                    name: "Inward",
                    type: "area",
                    fill: "gradient",
                    data: inwardData,
                  },
                  {
                    name: "Outward",
                    type: "line",
                    fill: "solid",
                    data: outwardData,
                  },
                ]}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <ColumnChart AllClient={AllClient} years={years} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
