import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// ----------------------------------------------------------------------

const NAV_WIDTH = 250;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
    background: "#fff",
    borderBottom: "1px solid #5555",
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav, Role, onLogout }) {
  const [title, setTitle] = useState("");
  const [subTitle, setSubtitle] = useState("");
  const location = useLocation();

  const AppTitle = [
    {
      title: "All Users",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "users",
    },
    {
      title: "Dashboard",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "app",
    },
    {
      title: "All Customers",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "allcustomers",
    },
    {
      title: "Delivery Challan",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "challan",
    },
    {
      title: "Outward List",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "Outward",
    },
    {
      title: "Purchase inventory",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "purchase_inventory",
    },
    {
      title: "Quality Reports",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "quality-report",
    },
    {
      title: "Stock Reports",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "stockReport",
    },
    {
      title: "Invoices Report",
      subTitle: "ULTRA SURFACE FINISHERS",
      path: "invoices",
    },
  ];

  useEffect(() => {
    const currentUrl = location.pathname;
    const segments = currentUrl.split("/");
    const endPath = segments[segments.length - 1];

    AppTitle.forEach((item) => {
      if (endPath === item.path) {
        console.log(
          `Title: ${item.title}, Subtitle: ${item.subTitle}, End Path: ${endPath}`
        );
        setTitle(item.title);
        setSubtitle(item.subTitle);
      }
    });
  }, [location]);

  return (
    <>
      <ToastContainer position="top-right" />
      <StyledRoot>
        <StyledToolbar>
          <div>
            <h5 className="color-dark">{title}</h5>
            <p className="mb-0 gray f-12">{subTitle}</p>
          </div>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: "text.primary",
              display: { lg: "none" },
            }}
          >
            <Iconify icon="arcticons:cbc" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <AccountPopover onLogout={onLogout} />
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </>
  );
}
