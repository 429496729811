import { createSlice } from "@reduxjs/toolkit";

const ALLBILLERLIST = createSlice({
  name: "ALLBILLERLIST",
  initialState: {
    AllBillerList: "",
    specificBillerList: ""
  },
  reducers: {
    setALLBILLERLIST: (state, action) => {
      state.AllBillerList = action.payload;
    },
    setSpecificBillerDetails: (state, action) => {
      state.specificBillerList = action.payload;
    },
  },
});

export const { setALLBILLERLIST, setSpecificBillerDetails } = ALLBILLERLIST.actions;
export default ALLBILLERLIST.reducer;
