import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  TableHead,
  TableRow,
  TableCell,
  Table,
  IconButton,
  MenuItem,
  Popover,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  TableContainer,
  InputAdornment,
} from "@mui/material";
import { getData, patchData, postData } from "src/webService/webService";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import PRODUCTAPI from "src/API_Routes/ProductAPI/product-API";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../iconify";
import {
  setALLPRODUCTLIST,
  setGetPRODUCTLIST,
} from "src/redux/Slices/AllProductSclices";
import CLIENTAPI from "src/API_Routes/ClientAPI/ClientAPI";
import { setSpecificClientsDetails } from "src/redux/Slices/AllClientSlices";
import { ReConfirmationDialog } from "../../utils/sweetalertconfirmUtils";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
// import { useDispatch, useSelector } from 'react-redux';

export default function AddProduct({
  HandleAddProduct,
  ALLPRODUCTLIST,
  nagesh,
  setNagesh,
}) {
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [action, setAction] = useState("");
  const [partNoArray, setPartNoArray] = useState([]);
  const specificClientsList = useSelector(
    (state) => state?.ALLCLIENTSLIST?.specificClientsList
  );
  const [productName, setProductName] = useState("");
  const [productNameArray, setProductNameArray] = useState([]);
  const [partName, setPartName] = useState("");
  const [partNo, setPartNo] = useState("");
  const [errors, setErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [getpartName, setGetpartName] = useState();
  const existingProductList = specificClientsList?.product;
  const [productTableList, setProductTableList] = useState();

  console.log(nagesh, "nagesh");

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedId(id);
  };

  const updatePartNo = () => {
    if (partNo.trim() !== "") {
      setPartNoArray((prevArray) => [...prevArray, partNo]);
      setPartNo("");
    }
  };
  const removePartNo = (index) => {
    setPartNoArray((prevArray) => prevArray.filter((_, i) => i !== index));
  };

  const updateProductName = () => {
    if (productName.trim() !== "") {
      setProductNameArray((prevArray) => [...prevArray, productName]);
      setProductName("");
    }
  };
  const removeProductName = (index) => {
    setProductNameArray((prevArray) => prevArray.filter((_, i) => i !== index));
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !partName ||
      // !productNameArray.length ||
      // !partNoArray.length ||
      !productName ||
      !partNo
    ) {
      toast.error("All fields are required");
      return;
    }

    // const isConfirmed = await ReConfirmationDialog();
    // if (isConfirmed) {
    const params = {
      partName: partName,
      productName: productName,
      partNo: partNo,
    };
    try {
      const updatedProductList = [...nagesh.product, params];

      const updatedData = {
        product: updatedProductList,
      };

      setNagesh((prevDetails) => ({
        ...prevDetails,
        product: updatedProductList,
      }));

      const res = await patchData(
        `clients/client/${specificClientsList._id}`,
        updatedData,
        accessToken
      );

      if (res.status === 200) {
        toast.success("Product added successfully");
        setProductName("");
        setPartName("");
        setPartNoArray("");
        setProductName("");
        setProductNameArray("");
        setPartNo("");
        dispatch(setALLPRODUCTLIST(res.data)); // Assuming this updates the Redux state correctly
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setPartNoArray("");
    setProductName("");
    setProductNameArray("");
    GetProductsList();
    setPartName("");
    setProductName("");
    setPartNo("");
    // }
  };

  const GetProductsList = async () => {
    try {
      const res = await getData(
        `clients/client/${specificClientsList._id}`,
        null,
        accessToken
      );
      if (res.status === 200) {
        setGetpartName(res.data.product);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    GetProductsList();
  }, []);

  useEffect(() => {
    // Update local state when Redux state changes
    setProductTableList(specificClientsList.product);
  }, [specificClientsList.product]);

  const handleEdit = (index) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const productToEdit = nagesh.product[index];
    setPartName(productToEdit.partName);
    setPartNo(productToEdit.partNo);
    setProductName(productToEdit.productName);
    setSelectedId(index);
    setAction("Edit");
  };

  const handleUpdate = async () => {
    if (partName && partNo && productName) {
      const updatedProduct = {
        partName: partName,
        partNo: partNo,
        productName: productName,
      };

      const updatedProductList = [...nagesh.product];
      updatedProductList[selectedId] = updatedProduct;

      setNagesh((prevDetails) => ({
        ...prevDetails,
        product: updatedProductList,
      }));

      const res = await patchData(
        `clients/client/${specificClientsList._id}`,
        { product: updatedProductList },
        accessToken
      );

      toast.success("Product updated successfully");

      // Reset the fields
      setPartName("");
      setPartNo("");
      setProductName("");
      setSelectedId(null);
      setAction("");
    } else {
      toast.error("All fields are required");
    }
  };

  const handleDelete = async (index) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      const updatedProductList = nagesh.product.filter((_, i) => i !== index);
      setNagesh((prevDetails) => ({
        ...prevDetails,
        product: updatedProductList,
      }));
      patchData(
        `clients/client/${specificClientsList._id}`,
        { product: updatedProductList },
        accessToken
      );
      setPartName("");
      setProductName("");
      setPartNo("");
      setAction("");
    }
  };

  // Function to filter API data based on the search term
  const filteredData = nagesh?.product.filter(
    (item) =>
      item.partName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.partNo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-5 card">
      <div className="component-title">
        <h5 className="mb-3">Add Part</h5>
      </div>
      <div className="productDetails mb-4">
        <Grid container>
          <Grid item xs={4}>
            <h2>{specificClientsList?.companyname}</h2>
            <h6>
              <b>Email:</b>
              {specificClientsList?.email}
            </h6>
            <h6>
              <b>Phone Number:</b>
              {specificClientsList?.phoneNumber}
            </h6>
            <h6>
              <b>GST NO:</b>
              {specificClientsList?.gstNo}
            </h6>
          </Grid>
        </Grid>
      </div>
      <div className="mb-3">
        <Grid container>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Part Name"
              value={partName}
              variant="outlined"
              onChange={(e) => setPartName(e.target.value)}
              sx={{ borderRadius: "15px", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2} className="position-relative px-3">
            <TextField
              className="w-100"
              id="outlined-basic"
              name="Part No"
              label="Part No"
              value={partNo}
              onChange={(e) => setPartNo(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* {partNo !== "" && (
              <div className="verify-button" onClick={updatePartNo}>
                <Iconify className="" icon={"ic:twotone-plus"} />
              </div>
            )}
            {partNoArray.length > 0 && (
              <ul className="d-flex flex-wrap list-language">
                {partNoArray.map((partno, index) => (
                  <li key={index} className="me-2">
                    {partno}
                    {action !== "View" && (
                      <Iconify
                        className="delete-button"
                        onClick={() => removePartNo(index)}
                        icon={"streamline:delete-1-solid"}
                      />
                    )}
                  </li>
                ))}
              </ul>
            )} */}
          </Grid>
          <Grid item xs={4} className="position-relative ">
            <TextField
              className="w-100"
              id="outlined-basic"
              name="ProductName"
              label="Process Name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* {productName !== "" && (
              <div className="verify-button-1" onClick={updateProductName}>
                <Iconify className="" icon="ic:twotone-plus" />
              </div>
            )}
            {productNameArray.length > 0 && (
              <ul className="d-flex flex-wrap list-language">
                {productNameArray.map((process, index) => (
                  <li key={index} className="me-2">
                    {process}
                    {action !== "View" && (
                      <Iconify
                        className="delete-button"
                        onClick={() => removeProductName(index)}
                        icon="streamline:delete-1-solid"
                      />
                    )}
                  </li>
                ))}
              </ul>
            )} */}
          </Grid>
          <Grid item xs={2} className="d-flex justify-content-end">
            <Button
              variant="contained"
              onClick={action === "Edit" ? handleUpdate : handleSubmit}
              className="ml-8 btn-primary"
            >
              {action === "Edit" ? "UPDATE" : "SUBMIT"}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="d-flex justify-content-end">
        <TextField
          style={{ borderRadius: "10px 10px 0px 0px" }}
          label="Search Part"
          variant="outlined"
          className="col-3 mb-0"
          margin="normal"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={"ic:baseline-search"} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableContainer
        style={{ height: "300px", overflowY: "scroll" }}
        className="mb-3"
      >
        <Table stickyHeader>
          <TableHead className="table">
            <TableRow>
              <TableCell className="table-cl color-dark">S.NO</TableCell>
              <TableCell className="table-cl color-dark">Part Name</TableCell>
              <TableCell className="table-cl color-dark">Part No</TableCell>
              <TableCell className="table-cl color-dark">
                Process Name
              </TableCell>
              {isAuthenticated === "Admin" ? (
                <>
                  <TableCell className="table-cl color-dark" align="center">
                    Edit
                  </TableCell>
                  <TableCell className="table-cl color-dark" align="center">
                    Delete
                  </TableCell>
                </>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row?.partName}</TableCell>
                  <TableCell>
                    {/* {row?.partNo?.join(", ")}  */}
                    {row?.partNo}
                  </TableCell>
                  <TableCell>
                    {/* {row?.productName?.join(", ")}  */}
                    {row?.productName}
                  </TableCell>
                  {isAuthenticated === "Admin" ? (
                    <>
                      <TableCell align="center">
                        <IconButton onClick={() => handleEdit(index)}>
                          <Iconify icon={"eva:edit-fill"} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleDelete(index)}>
                          <Iconify icon={"eva:trash-2-outline"} />
                        </IconButton>
                      </TableCell>
                    </>
                  ) : (
                    ""
                  )}
                </TableRow>
              ))}
            {(!nagesh?.product || nagesh?.product?.length === 0) && (
              <TableRow>
                <TableCell colSpan={10} className="text-center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-end">
        <Button
          variant="outlined"
          onClick={HandleAddProduct}
          className="btn-secondary"
        >
          BACK
        </Button>
      </div>
    </div>
  );
}
