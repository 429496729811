import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tab,
  Tabs,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import "../../../sections/auth/login/login.css";
import { postData } from "src/webService/webService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// ----------------------------------------------------------------------

export default function LoginForm({ onLogin }) {
  const navigate = useNavigate();

  const [mail, setMail] = useState("");
  const [pwd, setPwd] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [role, setRole] = useState("Admin");
  const [username, setUsername] = useState("");

  const extractUsername = () => {
    const parts = mail.split("@"); // Split the email address by '@'
    if (parts.length === 2) {
      setUsername(parts[0]); // Set the username state to the first part of the split array
    } else {
      setUsername(""); // Set username to empty if email is invalid
    }
  };
  useEffect(() => {
    extractUsername(mail);
  }, [mail]);

  const handleClick = () => {
    const LoginParams = {
      email: mail.trim(),
      password: pwd.trim(),
      role: role,
    };
    if (mail === "" && pwd === "" && role === "None") {
      toast.error("All The Fields Are Required");
    } else {
      postData("users/login", LoginParams, null)
        .then((res) => {
          switch (res.status) {
            case 200:
              sessionStorage.setItem("UserMail", mail);
              sessionStorage.setItem("UserName", username);
              sessionStorage.setItem("Token", res?.data?.sessionToken);
              sessionStorage.setItem("UserId", res?.data?.userId);
              // dispatch(setToken(res?.data?.sessionToken));
              toast.success("User logged in successfully");
              onLogin(role);
              switch (role) {
                case "Admin":
                  navigate("/app", { replace: true });
                  break;
                case "Sales":
                  navigate("/app", { replace: true });
                  break;
              }
              break;
            case 500:
              toast.error("Error while logging in: Server error");
              break;
            case 400:
              toast.error("Incorrect Password");
              break;
            case 404:
              toast.error("User Not Found");
              break;
            default:
              toast.error("Unexpected Error occurred");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // navigate('/dashboard/app', { replace: true });
  };

  const handleTabChange = (event, newValue) => {
    setRole(newValue);
    setMail("");
    setPwd("");
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <Stack spacing={3}>
          <h4 className="text-center fw-bold mb-0 pb-0">WELCOME TO</h4>
          <h6
            className="text-center fw-bold mt-3"
            style={{
              whiteSpace: "nowrap",
              background:
                "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
              fontSize: "27px",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            ULTRA SURFACE FINISHERS
          </h6>
          <Tabs
            value={role}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className="my-3"
            sx={{
              ".MuiTab-root": { color: "gray" },
              ".Mui-selected": { color: "blue" },
            }}
          >
            <Tab label="Admin" value="Admin" />
            <Tab label="Biller" value="Biller" />
          </Tabs>
          <TextField
            className="textfield"
            name="email"
            label="Email address"
            value={mail}
            size="small"
            onChange={(e) => setMail(e.target.value)}
            autoComplete="off"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className="textfield"
            size="small"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            className="mt-4"
          >
            <InputLabel id="demo-select-small-label">Role</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={role}
              label="role"
              onChange={handleChange}
            >
              <MenuItem value={"Admin"}>Admin</MenuItem>
              <MenuItem value={"Biller"}>Biller</MenuItem>
            </Select>
          </FormControl> */}
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ my: 3 }}
          // onClick={handleClick}
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
