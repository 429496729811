import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Button } from '@mui/material';
import React from 'react';


function PurchaseInventoryFilter() {
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
    };
    return (

        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item xs={3}>
                <TextField
                    className="w-100"
                    type="date"
                    name="date"
                    label="start date"
                    min={getTodayDate()}
                    size="medium"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: getTodayDate(),
                    }}
                />

            </Grid>


            <Grid item xs={3}>

                <TextField
                    className="w-100"
                    type="date"
                    name="Enddate"
                    label="Enddate"
                    min={getTodayDate()}
                    size="medium"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: getTodayDate(),
                    }}
                />

            </Grid>
            <Grid item xs={3}>
                <FormControl sx={{ m: 1, minWidth: 278 }} size="large" className='m-0'>
                    <InputLabel id="demo-select-small-label">Company Name</InputLabel>
                    <Select
                        labelId="demo-select-large-label"
                        id="demo-select-large"
                        label="Company Name"

                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>

                        <MenuItem value="hello">hello</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField id="outlined-basic" label="Limit" variant="outlined" sx={{ borderRadius: '15px', width: "100%" }} />
            </Grid>
            <div>
                <Button variant="outlined" className=' ml-8 btn-secondary'>
                    search
                </Button>
            </div>

        </div >
    )

}
export default PurchaseInventoryFilter;