import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import QualityReportTemplate from "../templates/qualityReport";
import jsPDF from "jspdf";
import { toast, ToastContainer } from "react-toastify";
import { FileUpload, getData, postData } from "../../webService/webService";
import QUALITYREPORTAPI from "../../API_Routes/QualityReport/QualityReportAPI";
import FILEROUTES from "../../API_Routes/File/file";
import { useDispatch } from "react-redux";
import QualityReportSpl from "../templates/qualityReportSpl";

function Feedback({
  handleQuality,
  outwardTable,
  outwardList,
  basicClientDetail,
}) {
  console.log(outwardList, "basicClientDetail");
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  console.log(outwardList, "outwardList");
  console.log(outwardTable, "outwardTable");
  const [companyName, setCompanyName] = useState(
    outwardList?.companyName || ""
  );
  const [ourDcNo, setOurDcNo] = useState(outwardList?.yourDcNo || "");
  const [qualityReport, setQualityReport] = useState();
  const [finalData, setFinalData] = useState();
  const [processDate, setProcessDate] = useState(
    outwardList?.date?.split("T")[0] || ""
  );
  const [outwardFields, setOutwardFields] = useState([]);
  const [answers, setAnswers] = useState({
    sootRemoval: "",
    fineFinish: "",
    burrRemoval: "",
    rpOilApplication: "",
    packing: "",
  });

  console.log(outwardFields, "outwardFields");

  const specificQualityReport = async () => {
    try {
      const res = await getData(
        `qualityReport/${outwardList._id}`,
        null,
        accessToken
      );
      if (res.status === 200) {
        setQualityReport(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {});
    }
  };

  useEffect(() => {
    if (qualityReport) {
      groupData();
    }
  }, [qualityReport]);

  console.log(qualityReport, "qualityReport");

  const groupData = () => {
    console.log("resultttt");
    const groupedData = new Map();
    qualityReport?.results?.forEach((result) => {
      console.log(result, "result");

      result?.qualityReport?.[0]?.report.forEach((report) => {
        const key = `${report.partName}_${report.partNO}`;

        if (!groupedData.has(key)) {
          // Store the first occurrence with Quantity as totalQuantity
          groupedData.set(key, {
            partName: report.partName,
            partNO: report.partNO,
            totalQuantity: report.Quantity,
            totalBatchQuantity: 0,
          });
        }

        const group = groupedData.get(key);
        group.totalBatchQuantity += Number(report.BatchQuantity);
      });
    });

    // Calculate Quantity - BatchQuantity for each group
    const finalData = Array.from(groupedData.values()).map((group) => ({
      partName: group.partName,
      partNO: group.partNO,
      totalQuantity: group.totalQuantity,
      totalBatchQuantity: group.totalBatchQuantity,
      remainingQuantity: group.totalQuantity - group.totalBatchQuantity,
    }));

    console.log(finalData, "finalData");
    setFinalData(finalData);
  };





  useEffect(() => {
    if (finalData && outwardTable && outwardTable.length > 0) {
      setOutwardFields(
        outwardTable.map((item, index) => ({
          partName: item.partName || "",
          partNo: item.partNo || "",
          quantity: item.outwardQuantity || "",
          description: item.description || "",
          dcNo: item.dcNo || "",
          units: item.units || "",
          processHrs: "00",
          processMins: "00",
          processSec: "00",
          BatchQuantity:
            finalData.length === 0
              ? item.outwardQuantity || ""
              : finalData?.[index]?.remainingQuantity,
          sendingBatchQuantity: "",
          Airpressure: "",
          DW902RPOIL: "Applied",
          concAccepted: "",
          sootRemoval: "",
          fineFinish: "",
          burrRemoval: "",
          rpOilApplication: "",
          packing: "",
          remarks: "",
          ShotsSize: "0.6 mm",
          CoverageArea: "200%",
          AmmeterReading: "12 - 14 amps",
          FixedRotation: "16 RPM",
          ShotsSeivingFrequency: "Daily",
          ShotsHardness: "40 - 50 hrc",
          AlmenIntensity: "",
          StemRZValue: "",
          BallRZValue: "",
          CupRZValue: "",
          inspectedBy: ""
        }))
      );
    }
  }, [finalData]);

  useEffect(() => {
    specificQualityReport();
  }, []);

  const handleOutwardFieldChange = (index, field, value) => {
    setOutwardFields((prevFields) => {
      const newFields = [...prevFields];
      newFields[index] = { ...newFields[index], [field]: value };
      return newFields;
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value,
    }));
  };

  // var dataArray = outwardFields.map((field) => ({
  //   companyName: companyName,
  //   partName: field.partName,
  //   partNO: field.partNo,
  //   processDate: processDate,
  //   description: field.description,
  //   Quantity: field.quantity,
  //   ourDcNo: ourDcNo,
  //   processTime: `${field.processHrs} : ${field.processMins} : ${field.processSec}Hrs`,
  //   BatchQuantity: field.sendingBatchQuantity,
  //   Airpressure: field.Airpressure,
  //   DW902RPOIL: field.DW902RPOIL,
  //   Accepted: field.Accepted,
  //   Rejected: field.Rejected,
  //   Disposition: field.Disposition,
  //   concAccepted: field.concAccepted,
  //   sootRemoval: field.sootRemoval,
  //   fineFinish: field.fineFinish,
  //   burrRemoval: field.burrRemoval,
  //   rpOilApplication: field.rpOilApplication,
  //   packing: field.packing,
  //   remarks: field.remarks,
  //   ShotsSize: field.ShotsSize,
  //   CoverageArea: field.CoverageArea,
  //   AmmeterReading: field.AmmeterReading,
  //   FixedRotation: field.FixedRotation,
  //   ShotsSeivingFrequency: field.ShotsSeivingFrequency,
  //   ShotsHardness: field.ShotsHardness,
  //   AlmenIntensity: field.AlmenIntensity,
  //   StemRZValue: field.StemRZValue,
  //   BallRZValue: field.BallRZValue,
  //   CupRZValue: field.CupRZValue,
  // }));

  var dataArray = outwardFields
    ?.filter((row) => row.BatchQuantity > 0)
    .map((field) => ({
      companyName: companyName,
      branch: outwardList?.branch,
      partName: field.partName,
      partNO: field.partNo,
      processDate: processDate,
      description: field.description,
      Quantity: field.quantity,
      ourDcNo: ourDcNo,
      processTime: `${field.processHrs} : ${field.processMins} : ${field.processSec}Hrs`,
      BatchQuantity: field.sendingBatchQuantity,
      Airpressure: field.Airpressure,
      DW902RPOIL: field.DW902RPOIL,
      Accepted: field.Accepted,
      Rejected: field.Rejected,
      Disposition: field.Disposition,
      concAccepted: field.concAccepted,
      sootRemoval: field.sootRemoval,
      fineFinish: field.fineFinish,
      burrRemoval: field.burrRemoval,
      rpOilApplication: field.rpOilApplication,
      packing: field.packing,
      remarks: field.remarks,
      ShotsSize: field.ShotsSize,
      CoverageArea: field.CoverageArea,
      AmmeterReading: field.AmmeterReading,
      FixedRotation: field.FixedRotation,
      ShotsSeivingFrequency: field.ShotsSeivingFrequency,
      ShotsHardness: field.ShotsHardness,
      AlmenIntensity: field.AlmenIntensity,
      StemRZValue: field.StemRZValue,
      BallRZValue: field.BallRZValue,
      CupRZValue: field.CupRZValue,
      inspectedBy: field.inspectedBy
    }));

  console.log(dataArray, "dataArray9090");


  const partList = [
    { name: "SCANIA PUSH ROD", number: "1943845" },
    { name: "SCANIA PUSH ROD", number: "2071482" },
    { name: "SCANIA PUSH ROD", number: "1946083" },
    { name: "DAIMLER PUSH ROD", number: "A9060540605" },
    { name: "DAIMLER PUSH ROD", number: "A4570540405" },
    { name: "DAIMLER PUSH ROD", number: "A5410540705" },
    { name: "JOHN DEERE PUSH ROD", number: "R515095" },
    { name: "JOHN DEERE PUSH ROD", number: "R549354" },
    { name: "MAHINDRA PUSH ROD", number: "000022020RD" }
  ];


  // const convertToPDFAndDownload = async () => {
  //   debugger;
  //   try {
  //     const dataArray = outwardFields.map((field) => ({
  //       companyName: companyName,
  //       partName: field.partName,
  //       partNO: field.partNo,
  //       processDate: processDate,
  //       description: field.description,
  //       Quantity: field.quantity,
  //       ourDcNo: ourDcNo,
  //       processTime: field.processTime,
  //       BatchQuantity: field.BatchQuantity,
  //       Airpressure: field.Airpressure,
  //       DW902RPOIL: field.DW902RPOIL,
  //       Accepted: field.Accepted,
  //       Rejected: field.Rejected,
  //       Disposition: field.Disposition,
  //       concAccepted: field.concAccepted,

  //       sootRemoval: field.sootRemoval,
  //       fineFinish: field.fineFinish,
  //       burrRemoval: field.burrRemoval,
  //       rpOilApplication: field.rpOilApplication,
  //       packing: field.packing,
  //     }));

  //     console.log(dataArray, "dataArray");

  //     const htmlContent = Qualityreporttemplate(dataArray);
  //     const fileName = "quality_report.pdf";

  //     const element = document.createElement("div");
  //     element.innerHTML = htmlContent;
  //     const opt = {
  //       margin: [8, 12],
  //       filename: fileName,
  //       image: { type: "jpeg", quality: 0.98 },
  //     };
  //     await html2pdf().from(element).set(opt).save();
  //     handleQuality();
  //   } catch (error) {
  //     console.error("Error during PDF download:", error);
  //     handleQuality();
  //   }
  // };

  const [openDialog, setOpenDialog] = useState(false);

  const handleopen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      // orientation: "landscape",
    });

    // Adding the fonts.
    doc.setFont("sans-serif", "normal");

    const content = reportTemplateRef.current;
    const scale = 0.8; // Adjust this value to fit your content properly

    doc.html(content, {
      html2canvas: {
        scale: scale, // Use a higher scale factor for better quality
        width: 695, // Width of A4 in px (jsPDF uses px units)
      },
      callback: async function (doc) {
        // doc.save("Outward delivery Challan.pdf");
        // Save the PDF file
        const pdfOutput = doc.output("blob");
        // const formData = new FormData();
        // formData.append(
        //   "file",
        //   pdfOutput,
        //   "Final_inspection_quality_report.pdf"
        // );

        // const uploadRes = await FileUpload(
        //   `${FILEROUTES.UPLOAD}${basicClientDetail._id}`,
        //   formData,
        //   dispatch
        // );

        const body = {
          report: dataArray,
          // qualityReportFileObject: uploadRes.data,
        };
        const newval = {
          outwardId: outwardList._id,
          qualityReport: body,
        };

        console.log(newval, "newval");

        try {
          // Send the PDF and other data to the API
          const response = await postData(
            QUALITYREPORTAPI.POSTQUALITYREPORT(outwardList._id),
            newval,
            accessToken
          );
          console.log("API Response:", response.data);
          handleQuality();
        } catch (error) {
          handleQuality();
          console.error("API Error:", error);
        }
      },
      x: 15,
      y: 15,
      width: 1075, // Adjust this value as necessary
    });
  };

  const validateFields = () => {
    for (const field of outwardFields) {
      if (field.BatchQuantity !== 0) {
        if (
          !field.partName ||
          !field.partNo ||
          !field.quantity ||
          !field.description ||
          // !field.processTime ||
          !field.BatchQuantity ||
          // !field.Accepted ||
          // !field.Rejected ||
          // !field.Airpressure ||
          !field.DW902RPOIL ||
          // !field.Disposition ||
          // !field.concAccepted ||
          !field.sootRemoval ||
          !field.fineFinish ||
          !field.burrRemoval ||
          !field.rpOilApplication ||
          !field.packing
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const handleVerifyClick = () => {
    if (validateFields()) {
      handleopen();
    } else {
      toast.error("Please fill out all required fields.");
    }
  };

  const handlePrint = () => {
    const printContent = reportTemplateRef.current;
    const originalContent = document.body.innerHTML;
    // Set the document's body to only include the printContent
    document.body.innerHTML = printContent.innerHTML;
    // Trigger the print dialog
    window.print();
    // Restore the original document content
    document.body.innerHTML = originalContent;
    // Reattach the React component to the DOM
    window.location.reload();
  };

  return (
    <Paper>
      <ToastContainer position="top-right" />

      <div className="card p-4">
        <div className="component-title">
          <h5>Report Generate</h5>
        </div>
        <div className="fields">
          <Grid container spacing={4} pt={4}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Our DC.NO"
                value={ourDcNo}
                onChange={(e) => setOurDcNo(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Process Date"
                value={processDate}
                onChange={(e) => setProcessDate(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        {dataArray?.length === 0 && (
          <h5 className="fw-bold text-center my-4">
            All Quality Report Completed
          </h5>
        )}
        {outwardFields &&
          outwardFields.map((row, index) => {
            const isPartMatch = partList.some(
              (part) => part.name === row.partName && part.number === row.partNo
            );

            return (

              <div
                className={row.BatchQuantity === 0 ? "d-none fields" : "fields"}
                key={index}
              >
                <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
                  <DialogTitle>Quality Report</DialogTitle>
                  <DialogContent>
                    <div ref={reportTemplateRef} style={{ width: "67.5%" }}>
                      {companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                        <QualityReportSpl data={dataArray} />
                      ) : (
                        <QualityReportTemplate data={dataArray} />
                      )}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      size="small"
                      className="ml-8 btn-secondary"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      size="small"
                      className="ml-8 btn-primary"
                      onClick={() => {
                        handleGeneratePdf();
                        handleClose();
                      }}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid container spacing={4} pt={4}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Part Name"
                      value={row.partName}
                      onChange={(e) =>
                        handleOutwardFieldChange(
                          index,
                          "partName",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      style={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Part No"
                      value={row.partNo}
                      onChange={(e) =>
                        handleOutwardFieldChange(index, "partNo", e.target.value)
                      }
                      variant="outlined"
                      style={{ width: "100%" }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label={`Quantity`}
                      value={`${row.quantity}`}
                      onChange={(e) =>
                        handleOutwardFieldChange(
                          index,
                          "outwardQuantity",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Process"
                      value={row.description}
                      onChange={(e) =>
                        handleOutwardFieldChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} className="mt-3">
                  <Grid
                    item
                    xs={
                      companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch
                        ? 4
                        : 6
                    }
                  >
                    <h6 className="mb-4">VISUAL INSPECTION PARAMETER</h6>
                    <div className="d-flex justify-content-center align-items-center">
                      <FormLabel component="legend">Soot Removal</FormLabel>
                      <RadioGroup
                        name="sootRemoval"
                        value={row.sootRemoval}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "sootRemoval",
                            e.target.value
                          )
                        }
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <FormLabel component="legend" className="m-0">
                        Fine Finish
                      </FormLabel>
                      <RadioGroup
                        name="fineFinish"
                        value={row.fineFinish}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "fineFinish",
                            e.target.value
                          )
                        }
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <FormLabel component="legend" className="m-0">
                        Burr Removal
                      </FormLabel>
                      <RadioGroup
                        name="burrRemoval"
                        value={row.burrRemoval}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "burrRemoval",
                            e.target.value
                          )
                        }
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <FormLabel component="legend" className="m-0">
                        R.P Oil Application
                      </FormLabel>
                      <RadioGroup
                        name="rpOilApplication"
                        value={row.rpOilApplication}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "rpOilApplication",
                            e.target.value
                          )
                        }
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <FormLabel component="legend" className="m-0">
                        Packing (Trays)
                      </FormLabel>
                      <RadioGroup
                        name="packing"
                        value={row.packing}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "packing",
                            e.target.value
                          )
                        }
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <TextField
                      className="mt-3"
                      label="Remarks"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      onChange={(e) =>
                        handleOutwardFieldChange(index, "remarks", e.target.value)
                      }
                      rows={4}
                      fullWidth
                    />
                  </Grid>

                  <Grid
                    item
                    xs={
                      companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch
                        ? 4
                        : 6
                    }
                  >
                    <h6 className="mb-2">OTHER DETAILS</h6>
                    <p className="f-12">Process Time</p>
                    <Grid sx={4} className="d-flex justify-content-between">
                      <Grid>
                        <TextField
                          id="outlined-basic"
                          label="HH"
                          value={row.processHrs}
                          type="number"
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "processHrs",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          size="small"
                          style={{ width: "90%" }}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label="MM"
                          value={row.processMins}
                          type="number"
                          onChange={(e) => {
                            const value = e.target.value;

                            // Ensure the value is numeric and does not exceed 60
                            if (!isNaN(value) && value >= 0 && value <= 59) {
                              handleOutwardFieldChange(
                                index,
                                "processMins",
                                value
                              );
                            } else if (value === "") {
                              // Allow empty input to clear the field
                              handleOutwardFieldChange(
                                index,
                                "processMins",
                                value
                              );
                            } else {
                              // Show a toast notification if the value exceeds 60
                              toast.warning("Minutes less than 60!");
                            }
                          }}
                          size="small"
                          variant="outlined"
                          style={{ width: "90%" }}
                          inputProps={{ maxLength: 2 }}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          id="outlined-basic"
                          label="SS"
                          type="number"
                          value={row.processSec}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Ensure the value is numeric and does not exceed 60
                            if (!isNaN(value) && value >= 0 && value <= 59) {
                              handleOutwardFieldChange(
                                index,
                                "processSec",
                                value
                              );
                            } else if (value === "") {
                              // Allow empty input to clear the field
                              handleOutwardFieldChange(
                                index,
                                "processSec",
                                value
                              );
                            } else {
                              // Show a toast notification if the value exceeds 60
                              toast.warning("Seconds less than 60!");
                            }
                          }}
                          size="small"
                          variant="outlined"
                          style={{ width: "90%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid pt={4} className="d-flex justify-content-between">
                      <Grid width="47%">
                        <TextField
                          id="outlined-basic"
                          label="Pending Batch Quantity"
                          value={row.BatchQuantity}
                          type="number"
                          variant="outlined"
                          style={{ width: "100%" }}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid width="47%">
                        <TextField
                          id="outlined-basic"
                          label="Batch Quantity"
                          value={row.sendingBatchQuantity}
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Ensure the value is numeric and does not exceed 60
                            if (
                              !isNaN(value) &&
                              value >= 0 &&
                              value <= row.BatchQuantity
                            ) {
                              handleOutwardFieldChange(
                                index,
                                "sendingBatchQuantity",
                                value
                              );
                            } else if (value === "") {
                              // Allow empty input to clear the field
                              handleOutwardFieldChange(
                                index,
                                "sendingBatchQuantity",
                                value
                              );
                            } else {
                              // Show a toast notification if the value exceeds 60
                              toast.warning(
                                "Value must be between BatchQuantity!"
                              );
                            }
                          }}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    {companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                      <Grid pt={4}>
                        <TextField
                          id="outlined-basic"
                          label="Shots Size"
                          value={row.ShotsSize}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ readOnly: true }}
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "ShotsSize",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    ) : (
                      <Grid pt={4}>
                        <TextField
                          id="outlined-basic"
                          label="Air Pressure"
                          value={row.Airpressure}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "Airpressure",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    )}
                    <Grid pt={4}>
                      <TextField
                        id="outlined-basic"
                        label="DW 902 R.P. Oil"
                        value={row.DW902RPOIL}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ readOnly: true }}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "DW902RPOIL",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  {companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                    <Grid
                      item
                      xs={4}
                      className="mt-6"
                      style={{ marginTop: "3rem" }}
                    >
                      <Grid>
                        <TextField
                          id="outlined-basic"
                          label="Coverage Area"
                          value={row.CoverageArea}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ readOnly: true }}
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "CoverageArea",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid pt={4}>
                        <TextField
                          id="outlined-basic"
                          label="Ammeter Reading"
                          value={row.AmmeterReading}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ readOnly: true }}
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "AmmeterReading",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid pt={4}>
                        <TextField
                          id="outlined-basic"
                          label="Fixed Rotation"
                          value={row.FixedRotation}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "FixedRotation",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid pt={4}>
                        <TextField
                          id="outlined-basic"
                          label="Shots Seiving Frequency"
                          value={row.ShotsSeivingFrequency}
                          InputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            handleOutwardFieldChange(
                              index,
                              "ShotsSeivingFrequency",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                {companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                  <Grid container spacing={4} pt={4}>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Shots Hardness"
                        value={row.ShotsHardness}
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "ShotsHardness",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Almen Intensity"
                        value={row.AlmenIntensity}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "AlmenIntensity",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Stem RZ Value"
                        value={row.StemRZValue}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "StemRZValue",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Ball RZ Value"
                        value={row.BallRZValue}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "BallRZValue",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Cup RZ Value"
                        value={row.CupRZValue}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleOutwardFieldChange(
                            index,
                            "CupRZValue",
                            e.target.value
                          )
                        }
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid container spacing={4} pt={4}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="Inspected By"
                      value={row.inspectedBy}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        handleOutwardFieldChange(
                          index,
                          "inspectedBy",
                          e.target.value
                        )
                      }
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <hr className="my-5"></hr>
              </div>
            );
          })}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "4px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="outlined"
            className="btn-secondary"
            onClick={handleQuality}
          >
            BACK
          </Button>
          {dataArray?.length !== 0 && (
            <Button
              style={{ width: "124px" }}
              variant="contained"
              className="ml-8 btn-primary"
              onClick={handleVerifyClick}
            >
              VERIFY
            </Button>
          )}
        </div>
      </div>
    </Paper>
  );
}
export default Feedback;
