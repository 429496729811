import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import PRODUCTAPI from 'src/API_Routes/ProductAPI/product-API';

import { deleteData, getData, postData } from "src/webService/webService";
import Iconify from "../iconify";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";

function BulkProduct() {
    const accessToken = sessionStorage.getItem("Token");
    const [limit, setLimit] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(null);
    const [isChecked, setIsChecked] = useState({});
    const [selectedId, setSelectedId] = useState();
    const [Uploadopen, setUploadopen] = useState(false);
    const [partName, setPartName] = useState();
    const [productName, setProductName] = useState();
    const [productDetails, setProductDetails] = useState()
    const [items, setItems] = useState([]);
    const [bulkproduct, setBulkProduct] = useState();
    const [totalPages, setTotalPages] = useState(1);
    const [selectAllChecked, setSelectAllChecked] = useState(false);


    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        setSelectedId(id);
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleLimitChange = (event) => {
        setCurrentPage(1);
        setLimit(event.target.value);
    };

    const handleCheckboxChange = (event, id) => {
        setIsChecked({ ...isChecked, [id]: event.target.checked });
    };

    const handleSelectAllCheckboxChange = (event) => {
        const { checked } = event.target;
        const updatedIsChecked = {};

        // Update isChecked object with all checkboxes' states
        bulkproduct.forEach((row) => {
            updatedIsChecked[row._id] = checked;
        });

        setIsChecked(updatedIsChecked);
        setSelectAllChecked(checked);
    };

    // const handleCloseMenu = () => {
    //     setOpen(null);
    // };

    const handleClickOpen = () => {
        setUploadopen(true);
    };

    const handleClose = () => {
        setUploadopen(false);
    }

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };



    const BulkProductList = async (page) => {
        const params = {
            page: page,
            limit: limit
        }

        try {
            const res = await getData(
                `products/products`, params, accessToken
            );
            if (res.status === 200) {
                setBulkProduct(res.data.results);
                setTotalPages(res.data.totalPages)
                toast.success(res.message)
                console.log(bulkproduct)
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }


    const handleSubmit = (async (e) => {

        const params = {
            partName: partName,
            productName: productName,
        };

        postData(PRODUCTAPI.ADDPRODUCT, params, accessToken)
            .then((res) => {
                setProductDetails(res.data);
                console.log(productDetails);
            })
            .catch((error) => {
                console.error("Error:", error);
            });


        setPartName('');
        setProductName('');

        console.log(items, "items");


    });

    const deleteBulkProduct = async (id) => {
        const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
        await deleteData(`products/product/${id}`, accessToken)
            .then((res) => {
                setOpen(null)
                BulkProductList(currentPage);
            })
            .catch((error) => {
                setOpen(null)
                console.error("Error:", error);
                toast.error("Biller Deleted Failed")
            })
    }
        
    }


    useEffect(() => {
        BulkProductList(currentPage)
    }, [currentPage, limit])


    return (
        <div>
            <div className="pt-3 d-flex justify-content-end">
                <Button variant="contained" size="small" className='ml-8 btn-primary'
                    onClick={() => {
                        handleClickOpen()
                    }}
                >
                    + ADD BULK PRODUCT
                </Button>
            </div>
            <Dialog open={Uploadopen} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Add Bulk Product</DialogTitle>
                <DialogContent className='overflow-hidden'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={partName}
                                onChange={(e) => setPartName(e.target.value)}
                                label="Part Name"
                                variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                label="Product Name"
                                variant="outlined" />
                        </Grid>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <Button variant="contained" size="small" className='btn-primary'
                                onClick={handleSubmit}
                            >
                                SUBMIT
                            </Button>
                        </div>
                    </Grid>

                </DialogContent>
            </Dialog>
            <div className="p-5">
                <ToastContainer position='top-right' />
                <div className="productDetails mb-5">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className="table-cl color-dark"
                                        style={{ padding: "8px" }}

                                    >
                                        <Checkbox
                                            checked={selectAllChecked}
                                            onChange={handleSelectAllCheckboxChange}
                                        />
                                    </TableCell>
                                    <TableCell className='table-cl color-dark'>S.No</TableCell>
                                    <TableCell className='table-cl color-dark'>Part Name</TableCell>
                                    <TableCell className='table-cl color-dark'>Process Name</TableCell>
                                    <TableCell className='table-cl color-dark'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bulkproduct && bulkproduct.map((row, index) => (
                                    <TableRow>
                                        <TableCell style={{ padding: "8px" }}>
                                            <Checkbox
                                                checked={isChecked[row._id] || false}
                                                onChange={(event) =>
                                                    handleCheckboxChange(event, row._id)
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{row.partName}</TableCell>
                                        <TableCell>{row.productName}</TableCell>
                                        <TableCell>
                                            <Iconify
                                                className="pointer"
                                                icon={"eva:trash-2-outline"}
                                                sx={{ mr: 2, color: "error.main" }}
                                                onClick={(e) => deleteBulkProduct(row._id)}
                                            />
                                        </TableCell>
                                    </TableRow>

                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="d-flex flex-wrap justify-content-end m-3">
                        <FormControl variant="outlined" size="small">
                            <InputLabel id="limit-label">limit</InputLabel>
                            <Select
                                labelId="limit-label"
                                id="limit-select"
                                value={limit}
                                onChange={handleLimitChange}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                        >
                            <Iconify icon={"grommet-icons:previous"} />
                        </Button>
                        <Button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <Iconify icon={"grommet-icons:next"} />
                        </Button>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default BulkProduct;
