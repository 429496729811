import React, { useEffect, useRef, useState } from "react";
import { getData } from "../../webService/webService";
import INVOICEAPI from "../../API_Routes/InvoiceAPI/InvoiceAPI";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Iconify from "../iconify/Iconify";
import useAllClients from "../CommonFunction/GetallClients";
import { toast, ToastContainer } from "react-toastify";
import QUALITYREPORTAPI from "../../API_Routes/QualityReport/QualityReportAPI";
import QualityLogo from "../logo/quality.png";
import QualityReportSpl from "../templates/qualityReportSpl";
import QualityReportTemplate from "../templates/qualityReport";
import jsPDF from "jspdf";

function QualityReportTable() {
  const accessToken = sessionStorage.getItem("Token");
  const [invoiceData, setinvoiceData] = useState();
  const [TotalItems, setTotalItems] = useState();
  const [selectedInvoiceData, setselectedInvoiceData] = useState();
  const [openInvoice, setOpenInvoice] = useState(false);

  //For Search Filter
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reset, setReset] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [branch, setBranch] = useState();
  const [company, setCompany] = useState();
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  //For Search Filter

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handleopen = () => {
    setOpenDialog(true);
  };

  const handleCloses = () => {
    setOpenDialog(false);
  };

  const reportTemplateRefs = useRef([]);



  // const handlePrint = () => {
  //   const printContent = reportTemplateRef.current;
  //   const originalContent = document.body.innerHTML;
  //   // Set the document's body to only include the printContent
  //   document.body.innerHTML = printContent.innerHTML;
  //   // Trigger the print dialog
  //   window.print();
  //   // Restore the original document content
  //   document.body.innerHTML = originalContent;
  //   // Reattach the React component to the DOM
  //   window.location.reload();
  // };

  const handlePrint = () => {
    // Get the combined HTML content from all refs
    const printContent = reportTemplateRefs.current
      .map((el) => el.innerHTML)
      .join("");

    const originalContent = document.body.innerHTML;

    // Create a temporary container for the print content
    document.body.innerHTML = printContent;

    // Trigger the print dialog
    window.print();

    // Restore the original content after printing
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const AllInvoiceList = async (page) => {
    if (startDate && !endDate) {
      toast.error("End date is required when start date is selected");
      return;
    }
    setReset(false);
    try {
      const params = {
        page: page,
        limit: limit,
        companyName: companyName ? companyName : "",
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        branch: branch ? branch : "",
      };
      const res = await getData(
        QUALITYREPORTAPI.GETQUALITYREPORT,
        params,
        accessToken
      );
      if (res.status === 200) {
        setTotalPages(res.data[0].totalPages);
        if (res.data?.[0]?.totalCount?.totalItems) {
          setTotalItems(res.data[0].totalCount.totalItems);
        } else {
          setTotalItems(0);
        }

        setinvoiceData(res.data[0].paginatedResults);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    AllInvoiceList(currentPage);
  }, [currentPage, limit]);

  const handleSearch = () => {
    setCurrentPage(1);
    AllInvoiceList(1);
  };

  const removeData = () => {
    setStartDate();
    setEndDate();
    setCompanyName();
    setReset(true);
    setBranch();
    setCompany();
  };

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
      AllInvoiceList(1);
    }
  }, [reset]);

  // index based on currentPage
  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  //   search Filter
  const { clientDetails, totalItems, fetchAllClients } = useAllClients();
  useEffect(() => {
    if (showFilter) {
      fetchAllClients();
    }
  }, [showFilter]);

  // Function to handle company selection
  const handleCompanyChanges = (event) => {
    const companyName = event.target.value;
    const company = clientDetails?.find((c) => c._id === companyName);
    setCompanyName(company.companyname);
    setCompany(company._id);
    setBranch(company?.branch);
  };

  const handleviewInvoice = (data, companyName) => {
    // setCompanyName(companyName);
    const selectedinvoice = invoiceData?.find((i) => i._id === data);
    setselectedInvoiceData(selectedinvoice?.qualityReport[0].report);
    setOpenInvoice(true);
  };

  const handleviewInvoices = (data, companyName) => {
    // setCompanyName(companyName);
    const selectedinvoice = invoiceData?.find((i) => i._id === data);
    setselectedInvoiceData(selectedinvoice?.qualityReport[0].report);
    handleopen(true);
  };

  const handleClose = () => {
    setOpenInvoice(false);
    setselectedInvoiceData();
  };

  console.log(selectedInvoiceData, "selectedInvoiceData");

  const partList = [
    { name: "SCANIA PUSH ROD", number: "1943845" },
    { name: "SCANIA PUSH ROD", number: "2071482" },
    { name: "SCANIA PUSH ROD", number: "1946083" },
    { name: "DAIMLER PUSH ROD", number: "A9060540605" },
    { name: "DAIMLER PUSH ROD", number: "A4570540405" },
    { name: "DAIMLER PUSH ROD", number: "A5410540705" },
    { name: "JOHN DEERE PUSH ROD", number: "R515095" },
    { name: "JOHN DEERE PUSH ROD", number: "R549354" },
    { name: "MAHINDRA PUSH ROD", number: "000022020RD" }
  ];

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Quality Report</DialogTitle>
        <DialogContent>
          {selectedInvoiceData &&
            selectedInvoiceData?.map((row, index) => {
              const isPartMatch = partList?.some(
                (part) => part?.name === row?.partName && part?.number === row?.partNO
              );
              console.log(row, "rowwwwwww");

              return (
                <div ref={(el) => (reportTemplateRefs.current[index] = el)} index={index} style={{ width: "67.5%" }} key={index}>
                  {row.companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                    <QualityReportSpl index={index} data={[row]} />
                  ) : (
                    <QualityReportTemplate index={index} data={[row]} />
                  )}
                </div>
              );
            })}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            className="ml-8 btn-secondary"
            onClick={handleCloses}
          >
            Close
          </Button>
          <Button
            size="small"
            className="ml-8 btn-primary"
            onClick={() => {
              handlePrint();
            }}
            variant="contained"
          >
            Print
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInvoice} onClose={handleClose} maxWidth={"xl"}>
        <DialogTitle>Quality Report Details</DialogTitle>
        <DialogContent>
          {selectedInvoiceData &&
            selectedInvoiceData?.map((row, index) => {
              const isPartMatch = partList.some(
                (part) => part.name === row.partName && part.number === row.partNO
              );
              return (
                <div className="fields" key={index}>
                  <Grid container spacing={4} pt={4}>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Part Name"
                        value={row.partName}
                        variant="outlined"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Part No"
                        value={row.partNO}
                        variant="outlined"
                        style={{ width: "100%" }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label={`Quantity`}
                        value={`${row.Quantity}`}
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Process"
                        value={row.description}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} className="mt-3">
                    <Grid
                      item
                      xs={
                        row.companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch
                          ? 4
                          : 6
                      }
                    >
                      <h6 className="mb-4">VISUAL INSPECTION PARAMETER</h6>
                      <div className="d-flex justify-content-center align-items-center">
                        <FormLabel component="legend">Soot Removal</FormLabel>
                        <RadioGroup
                          name="sootRemoval"
                          value={row.sootRemoval}
                          InputProps={{ readOnly: true }}
                        >
                          <div className="d-flex">
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                        <FormLabel component="legend" className="m-0">
                          Fine Finish
                        </FormLabel>
                        <RadioGroup name="fineFinish" value={row.fineFinish}>
                          <div className="d-flex">
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                        <FormLabel component="legend" className="m-0">
                          Burr Removal
                        </FormLabel>
                        <RadioGroup name="burrRemoval" value={row.burrRemoval}>
                          <div className="d-flex">
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                        <FormLabel component="legend" className="m-0">
                          R.P Oil Application
                        </FormLabel>
                        <RadioGroup
                          name="rpOilApplication"
                          value={row.rpOilApplication}
                        >
                          <div className="d-flex">
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>

                      <div className="d-flex justify-content-center align-items-center">
                        <FormLabel component="legend" className="m-0">
                          Packing (Trays)
                        </FormLabel>
                        <RadioGroup name="packing" value={row.packing}>
                          <div className="d-flex">
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>

                      <TextField
                        className="mt-3"
                        label="Remarks"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={row.remarks}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={
                        row.companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch
                          ? 4
                          : 6
                      }
                    >
                      <h6 className="mb-2">OTHER DETAILS</h6>
                      <Grid sx={4} className="d-flex justify-content-between">
                        <Grid width="100%">
                          <TextField
                            id="outlined-basic"
                            label="ProcessTime"
                            value={row.processTime}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputProps={{ readOnly: true }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        {/* <Grid>
                        <TextField
                          label="MM"
                          value={row.processMins}
                          
                          size="small"
                          variant="outlined"
                          style={{ width: "90%" }}
                          inputProps={{ maxLength: 2 }}
                        />

                      </Grid>
                      <Grid>
                        <TextField
                          id="outlined-basic"
                          label="SS"
                          value={row.processSec}
                          size="small"
                          variant="outlined"
                          style={{ width: "90%" }}
                        />

                      </Grid> */}
                      </Grid>
                      <Grid pt={4} className="d-flex justify-content-between">
                        <Grid width="100%">
                          <TextField
                            id="outlined-basic"
                            label="Batch Quantity"
                            value={row.BatchQuantity}
                            type="number"
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </Grid>
                      {row.companyName ===
                        "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                        <Grid pt={4}>
                          <TextField
                            id="outlined-basic"
                            label="Shots Size"
                            value={row.ShotsSize}
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      ) : (
                        <Grid pt={4}>
                          <TextField
                            id="outlined-basic"
                            label="Air Pressure"
                            value={row.Airpressure}
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      )}
                      <Grid pt={4}>
                        <TextField
                          id="outlined-basic"
                          label="DW 902 R.P. Oil"
                          value={row.DW902RPOIL}
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    {row.companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                      <Grid
                        item
                        xs={4}
                        className="mt-6"
                        style={{ marginTop: "1.7rem" }}
                      >
                        <Grid>
                          <TextField
                            id="outlined-basic"
                            label="Coverage Area"
                            value={row.CoverageArea}
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid pt={4}>
                          <TextField
                            id="outlined-basic"
                            label="Ammeter Reading"
                            value={row.AmmeterReading}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid pt={4}>
                          <TextField
                            id="outlined-basic"
                            label="Fixed Rotation"
                            value={row.FixedRotation}
                            variant="outlined"
                            style={{ width: "100%" }}
                            InputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid pt={4}>
                          <TextField
                            id="outlined-basic"
                            label="Shots Seiving Frequency"
                            value={row.ShotsSeivingFrequency}
                            InputProps={{ readOnly: true }}
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {row.companyName === "USUI SUSIRA INTERNATIONAL PVT LTD" && isPartMatch ? (
                    <Grid container spacing={4} pt={4}>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-basic"
                          label="Shots Hardness"
                          value={row.ShotsHardness}
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-basic"
                          label="Almen Intensity"
                          value={row.AlmenIntensity}
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-basic"
                          label="Stem RZ Value"
                          value={row.StemRZValue}
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-basic"
                          label="Ball RZ Value"
                          value={row.BallRZValue}
                          InputProps={{ readOnly: true }}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-basic"
                          label="Cup RZ Value"
                          InputProps={{ readOnly: true }}
                          value={row.CupRZValue}
                          variant="outlined"
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid container spacing={4} pt={4}>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Inspected By"
                        value={row.inspectedBy}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <hr className="my-5"></hr>

                </div>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="btn-secondary"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <div className="d-flex flex-wrap justify-content-between align-items-center pt-3 pb-4">
        <div className="d-flex align-items-center">
          <img src={QualityLogo} width="5%" className="mr-2" />
          <p className="fw-bold mb-0 color-dark mt-1">
            Total Quality Reports : {TotalItems}
          </p>
        </div>
        <div>
          {showFilter && (
            <span
              className="me-2 danger"
              style={{ color: "red", fontSize: "13px", cursor: "pointer" }}
              onClick={(e) => {
                toggleFilter();
                removeData();
              }}
            >
              Clear Filter
            </span>
          )}
          <IconButton
            className="p-12 ftrbkgd rounded"
            onClick={(e) => {
              toggleFilter();
              removeData();
            }}
          >
            {showFilter ? (
              <Iconify icon="ri:filter-off-fill" className="color-dark" />
            ) : (
              <Iconify
                icon="material-symbols:filter-alt-sharp"
                className="color-dark"
              />
            )}
          </IconButton>
        </div>
      </div>

      {showFilter && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div style={{ minWidth: 500, margin: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className="w-100"
                    type="date"
                    label="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="w-100"
                    type="date"
                    label="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: startDate,
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <Grid item xs={3}>
              <FormControl sx={{ margin: 1, width: 278 }} size="large" className="select">
                <InputLabel id="company-name-label">Company Name</InputLabel>
                <Select
                  labelId="company-name-label"
                  id="company-name-select"
                  label="Company Name"
                  value={company}
                  onChange={handleCompanyChanges}
                >
                  {clientDetails &&
                    clientDetails?.map((company) => (
                      <MenuItem key={company._id} value={company._id}>
                        {company.companyname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <div>
              <Button
                style={{ padding: "14px 40px !important" }}
                variant="outlined"
                className="ml-4 btn-secondary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
        </>
      )}
      <TableContainer style={{ height: "340px", overflowY: "scroll" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="table-cl color-dark">S.No</TableCell>
              <TableCell className="table-cl color-dark">
                Company Name
              </TableCell>
              <TableCell className="table-cl color-dark">DC No</TableCell>
              <TableCell className="table-cl color-dark">
                Process Date
              </TableCell>
              <TableCell
                className="table-cl color-dark text-center"
                align="center"
              >
                Quality Report View
              </TableCell>
              <TableCell
                className="table-cl color-dark text-center"
                align="center"
              >
                Quality Report PDF
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData &&
              invoiceData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell> {calculateIndex(index)}</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px" }}>
                    {row?.qualityReport?.[0]?.report?.[0]?.companyName}
                  </TableCell>
                  <TableCell>
                    {row?.qualityReport?.[0]?.report?.[0]?.ourDcNo}
                  </TableCell>
                  <TableCell>
                    {row?.qualityReport?.[0]?.report?.[0]?.processDate
                      ?.split("T")[0]
                      ?.split("-")
                      ?.reverse()
                      ?.join("-")}
                  </TableCell>
                  <TableCell align="center">
                    <Iconify
                      icon={"mdi:eye"}
                      sx={{ mr: 2 }}
                      style={{ cursor: "pointer", color: "#637381" }}
                      onClick={() =>
                        handleviewInvoice(
                          row._id,
                          row?.qualityReport?.[0]?.report?.[0]?.companyName
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Iconify icon={"bxs:file-pdf"}
                      onClick={() =>
                        handleviewInvoices(
                          row._id,
                          row?.qualityReport?.[0]?.report?.[0]?.companyName
                        )
                      }
                    />
                  </TableCell>
                  {/* <TableCell align="center">
                    <Iconify
                      icon={"material-symbols:download"}
                      sx={{ mr: 2 }}
                      style={{ cursor: "pointer", color: "#637381" }}
                      onClick={() => handleviewInvoice(row._id)}
                    />
                  </TableCell> */}
                </TableRow>
              ))}

            {invoiceData && invoiceData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="d-flex flex-wrap justify-content-end m-3">
        <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
          <Select
            labelId="limit-label"
            id="limit-select"
            value={limit}
            onChange={handleLimitChange}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={TotalItems}>All</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handlePrevPage} disabled={currentPage === 1}>
          <Iconify icon={"grommet-icons:previous"} />
        </Button>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <Iconify icon={"grommet-icons:next"} />
        </Button>
      </div>
    </Paper>
  );
}

export default QualityReportTable;
