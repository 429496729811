import { useEffect, useState } from "react";
import { getData } from "../../webService/webService";
import CLIENTAPI from "../../API_Routes/ClientAPI/ClientAPI";

const useAllClients = () => {
  const [clientDetails, setClientDetails] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const accessToken = sessionStorage.getItem("Token");

  const fetchAllClients = async () => {
    let page = 1;
    let allClients = [];
    const params = {
      page: page,
      limit: totalItems,
    };
    try {
      const res = await getData(CLIENTAPI.GETCLIENTS, params, accessToken);
      if (res.status === 200) {
        const newClients = res.data.results;
        allClients = [...allClients, ...newClients];
        if(res?.data?.totalItems){
          setTotalItems(res.data.totalItems);
        }
        else{
          setTotalItems(0);
        }
        
      } else {
        console.error("Failed to fetch data:", res.status);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    }

    setClientDetails(allClients);
    return allClients;
  };

  useEffect(() => {
    if (totalItems > 10) {
      fetchAllClients();
    }
  }, [totalItems]);

  return { clientDetails, totalItems, fetchAllClients };
};

export default useAllClients;
