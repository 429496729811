import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import "../src/css/common.css";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { getData } from './webService/webService';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  const accessToken = sessionStorage.getItem("Token");

  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") || ""
  );

  useEffect(() => {
    if (!sessionStorage.getItem("isAuthenticated")) {
      sessionStorage.setItem("isAuthenticated", isAuthenticated);
    }
  }, [isAuthenticated]);
  const handleLogin = (data) => {
    setIsAuthenticated(data);
    sessionStorage.setItem("isAuthenticated", data);
  };

  const handleLogout = () => {
    getData("users/signout", null, accessToken)
      .then((res) => {
        console.log("Logout successfully");
        setIsAuthenticated(false);
        sessionStorage.clear();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setIsAuthenticated(false);
    sessionStorage.clear();
  };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router
            isAuthenticated={isAuthenticated}
            onLogin={handleLogin}
            onLogout={handleLogout}
          />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
