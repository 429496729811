import { createSlice } from "@reduxjs/toolkit";

const ALLPRODUCTLIST = createSlice({
  name: "ALLPRODUCTLIST",
  initialState: {
    AllProductsList: "",
    GetProductsList: ""
  },
  reducers: {
    setALLPRODUCTLIST: (state, action) => {
      state.AllProductsList = action.payload;
    },
    setGetPRODUCTLIST: (state, action) => {
      state.GetProductsList = action.payload;
    },
  },
});

export const { setALLPRODUCTLIST, setGetPRODUCTLIST} = ALLPRODUCTLIST.actions;
export default ALLPRODUCTLIST.reducer;
