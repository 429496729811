import React from "react";
import logo from "../logo/usf_logo.jpeg";

const QualityReportTemplate = ({ data }) => {
  const Name = sessionStorage.getItem("UserName");
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = `${dd}/${mm}/${yyyy}`;

  return (
    <div>
      <style>
        {`
          table, th, td {
            border: 0.3px solid #272727;
            border-collapse: collapse;
            padding: 6px !important;
            font-size: 10px !important;
          }

          td {
            vertical-align: middle;
          }

          .bt {
            border-top: none !important;
          }

          .bb {
            border-bottom: none !important;
          }

          .bl {
            border-left: none !important;
          }

          .br {
            border-right: none !important;
          }
        `}
      </style>
      {data?.map((item, index) => (
        <div key={index} style={{ pageBreakInside: "avoid", height: "783px", overflow: "hidden"  }}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{ width: "30%" }}
                  rowSpan="2"
                  className="br bt bl bb"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={logo} width="30%" />
                  </div>
                </td>
                <td style={{ textAlign: "center" }} className="bt bb br">
                  <strong style={{ fontSize: "14px" }}>
                    FINAL INSPECTION QUALITY REPORT
                  </strong>
                  <br />
                  (In accordance with ISO 9001:2015)
                </td>
                <td
                  style={{ textAlign: "justify", fontWeight: "400" }}
                  rowSpan="2"
                  className="bt bb br"
                >
                  {/* Doc No.:USF/R/QC/04<br /> */}
                  {/* Issue: C<br /> */}
                  {/* Rev. No.: 00<br /> */}
                  Date: {formattedToday}
                  <br />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bb br">
                  <strong>QUALITY REPORT</strong>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  CUSTOMER NAME
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.companyName}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  PART NAME
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.partName}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  PART NO.
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.partNO}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  PROCESS DATE
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.processDate.split("T")[0]}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  QUANTITY (NO.S/ KGS)
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.Quantity}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  OUR DC NO.
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.ourDcNo}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} className="bl bb br">
                  PROCESS
                </td>
                <td
                  style={{ textAlign: "center" }}
                  colSpan="2"
                  className="bb br"
                >
                  {item.description}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "48%" }}>
              <table style={{ width: "100%", height: "100%" }}>
                <tbody>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      colSpan="3"
                      className="bt bb br bl"
                    >
                      VISUAL INSPECTION PARAMETERS
                    </th>
                  </tr>
                  <tr>
                    <th className="bb bl">PARAMETERS</th>
                    <th className="bb bl">YES</th>
                    <th className="bb br bl">NO</th>
                  </tr>
                  <tr>
                    <td className="bb bl">SOOT REMOVAL</td>
                    <td className="bb bl">
                      {item.sootRemoval === "yes" ? "YES" : ""}
                    </td>
                    <td className="bb br bl">
                      {item.sootRemoval !== "yes" ? "NO" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="bb bl">FINE FINISH</td>
                    <td className="bb bl">
                      {item.fineFinish === "yes" ? "YES" : ""}
                    </td>
                    <td className="bb br bl">
                      {item.fineFinish !== "yes" ? "NO" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="bb bl">BURR REMOVAL</td>
                    <td className="bb bl">
                      {item.burrRemoval === "yes" ? "YES" : ""}
                    </td>
                    <td className="bb br bl">
                      {item.burrRemoval !== "yes" ? "NO" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="bb bl">R.P OIL APPLICATION</td>
                    <td className="bb bl">
                      {item.rpOilApplication === "yes" ? "YES" : ""}
                    </td>
                    <td className="bb br bl">
                      {item.rpOilApplication !== "yes" ? "NO" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="bb bl">PACKING (TRAYS)</td>
                    <td className="bb bl">
                      {item.packing === "yes" ? "YES" : ""}
                    </td>
                    <td className="bb br bl">
                      {item.packing !== "yes" ? "NO" : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="bl bb br" colSpan="3">
                      REMARKS
                    </td>
                  </tr>
                  <tr>
                    <td className="bl bt bb br" colSpan="3">
                      {item.remarks}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ width: "48%" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      colSpan="2"
                      className="bt bb br bl"
                    >
                      OTHER DETAILS
                    </th>
                  </tr>
                  <tr>
                    <td className="bb bl">PROCESS TIME</td>
                    <td className="bb bl">{item.processTime}</td>
                  </tr>
                  <tr>
                    <td className="bb bl">BATCH QUANTITY</td>
                    <td className="bb bl">{item.BatchQuantity}</td>
                  </tr>
                  <tr>
                    <td className="bb bl">AIR PRESSURE</td>
                    <td className="bb bl">{item.Airpressure}</td>
                  </tr>
                  <tr>
                    <td className="bb bl">DW 902 R.P. OIL</td>
                    <td className="bb bl">{item.DW902RPOIL}</td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "100%", marginTop: "20px" }}>
                <tbody>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      colSpan="2"
                      className="bt bb br bl"
                    >
                      FOR CUSTOMER
                    </th>
                  </tr>
                  <tr>
                    <td className="bb bl">ACCEPTED</td>
                    <td className="bb bl">{item.Accepted}</td>
                  </tr>
                  <tr>
                    <td className="bb bl">REJECTED</td>
                    <td className="bb bl">{item.Rejected}</td>
                  </tr>
                  <tr>
                    <td className="bb bl">DISPOSITION</td>
                    <td className="bb bl">{item.Disposition}</td>
                  </tr>
                  <tr>
                    <td className="bb bl">CONC. ACCEPTED</td>
                    <td className="bb bl">{item.concAccepted}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <table style={{ width: "100%", marginTop: "20px" }}>
              <tbody>
                <tr>
                  <td className="br" style={{ width: "50%" }}>
                    INSPECTED BY: {item.inspectedBy}
                  </td>
                  <td style={{ width: "50%" }}>
                    INSPECTED DATE: {formattedToday}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "80px",
              marginBottom: "44px",
            }}
          >
            <h6 style={{fontSize: "12px"}}>FOR ULTRA SURFACE FINISHERS</h6>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QualityReportTemplate;
