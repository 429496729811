import React from 'react';
import logo from "../logo/usf_logo.jpeg"

const ReportTemplate = ({ outwardList, outwardTable, basicClientDetail }) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;


    return (
        <div>
            <style>
                {`
          table, th, td {
            border: 0.3px solid #272727;
            border-collapse: collapse;
            padding: 6px !important;
            font-size: 8px !important;
          }
        .bt {
            border-top: "none" !important;
        };
        .bb {
            border-bottom: "none" !important;
        };
        .bl {
            border-left: "none" !important;     
        };
        .br {
            border-right: "none" !important;
        }
        `}
            </style>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} width="9%" />
            </div> */}
            <table style={{ width: '100%'}}>
                <tbody>
                    <tr style={{ borderTop: "none" }}>
                        <td rowSpan="2" style={{ textAlign: "center", borderBottom: "none", borderRight: "none", borderTop: "none", borderLeft: "none" }} className='br bb'>
                            {/* <strong style={{ fontSize: "large" }}><span>ULTRA </span> <span style={{ marginLeft: "3px" }}> SERVICE </span> <span style={{ marginLeft: "6px" }}> FINISHERS</span> </strong><br /> */}
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                <img src={logo} width="15%" />
                                <div style={{borderLeft: "1px solid #272727"}}></div>
                                <div>
                                    New No.3, Welfare School Street,<br />
                                    Ayanambakkam, Chennai-600095<br />
                                    <span style={{ fontWeight: "bold" }}>Mob: 9884565380</span><br />
                                    <span style={{ fontWeight: "bold" }}>GSTIN No: 33AJWPP0088Q1Z9</span>
                                </div>
                            </div>
                        </td>
                        <td rowSpan="2" style={{ textAlign: 'center', borderBottom: "none", borderRight: "none", borderTop: "none" }}>
                            <strong style={{ fontSize: "large" }}>DELIVERY <span style={{ marginLeft: "6px" }}> NOTE</span> <br /> CHALLAN</strong><br />
                            JOB&ensp;WORK / NOT&ensp;FOR&ensp;SALE / ONLY&ensp;LABOUR&ensp;WORKS
                        </td>
                        <td style={{ textAlign: 'center', borderBottom: "none", borderRight: "none", borderTop: "none" }}>
                            CHALLAN NO : {outwardList?.yourDcNo}<br />
                            DATE&ensp;: {formattedToday}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'center', borderBottom: "none", borderRight: "none", }}>ASSESSMENT<span style={{ marginLeft: "6px" }}> YEAR</span><br />{outwardList?.assessmentStartYear?.split('-')[0]} - {outwardList?.assessmentEndYear?.split('-')[0]}</td>
                    </tr>
                    <tr>
                        <td rowSpan="4" style={{ borderRight: "none", borderLeft: "none", borderBottom: "none" }}>
                            CONSIGNEE<br />
                            {basicClientDetail?.companyname},<br/>
                            {basicClientDetail?.doorNo},<br/>
                            {basicClientDetail?.streetName},<br/>
                            {basicClientDetail?.city},
                            {basicClientDetail?.pinCode}<br/>
                            <span style={{fontWeight: "bold"}}>GST NO:{basicClientDetail?.gstNo}</span>
                        </td>
                        <td style={{ borderBottom: "none", borderRight: "none" }}>
                            <span>PURCHASE&ensp;ORDER&ensp;NO: {outwardList?.yourOrderNo}</span>
                        </td>
                        <td style={{ textAlign: 'center', borderBottom: "none", borderRight: "none" }}>PURPOSE &ensp; OF&ensp;TRANSPORT<br />FOR&ensp;LABOUR&ensp;WORK / PROCESSING</td>
                    </tr>
                    <tr style={{ borderBottom: "none", borderRight: "none" }}>
                        <td style={{ borderBottom: "none", borderRight: "none" }}>CUSTOMER&ensp;DC&ensp;NO: {outwardList?.dcNo}</td>
                        <td style={{ borderBottom: "none", borderRight: "none", textAlign: 'center' }} rowSpan="2">VALUE &ensp;OF&ensp;GOODS:&ensp;Rs. {outwardList?.totalAmount || ""}  </td>
                    </tr>
                    <tr>
                        <td style={{ borderBottom: "none", borderRight: "none" }}>CUSTOMER INWARD DATE&ensp;:&ensp;{outwardList?.date?.split('T')[0]}</td>
                    </tr>
                    {/* <tr>
                        <td style={{ borderBottom: "none", borderRight: "none" }} colSpan="2">GSTIN&ensp;: {basicClientDetail?.gstNo}</td>
                    </tr> */}
                </tbody>
            </table>
            <table style={{ width: '100%', marginTop: '1%' }}>
                <tbody>
                    <tr style={{ fontWeight: "bold", borderBottom: "none", borderRight: "none" }}>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none", borderLeft: "none" }}>S.NO.</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>DESCRIPTION</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>PROCESS</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>QTY.</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>UNITS</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>TOTAL&ensp;WT.</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>UNIT/&ensp;KG</td>
                        <td style={{ borderBottom: "none", borderRight: "none", borderTop: "none" }}>TOTAL &ensp; AMOUNT</td>
                    </tr>
                    {outwardTable && outwardTable?.map((row, index) => (
                        <tr key={index}>
                            <td style={{ borderBottom: "none", borderRight: "none", borderLeft: "none" }}>{index + 1}</td>
                            <td style={{ borderBottom: "none", borderRight: "none" }}>{row?.partName} - {row?.partNo} </td>
                            <td style={{ borderBottom: "none", borderRight: "none" }}>{row?.description}</td>
                            {row?.units === "NOS" ? (<td style={{ borderBottom: "none", borderRight: "none" }}>{row?.outwardQuantity}</td>) : (<td style={{ borderBottom: "none", borderRight: "none" }}></td>)}
                            <td style={{ borderBottom: "none", borderRight: "none" }}>{row?.rate}</td>
                            {row?.units === "KG" ? (<td style={{ borderBottom: "none", borderRight: "none" }}>{row?.outwardQuantity}</td>) : (<td style={{ borderBottom: "none", borderRight: "none" }}></td>)}
                            <td style={{ borderBottom: "none", borderRight: "none" }}>{row?.units}</td>
                            <td style={{ borderBottom: "none", borderRight: "none" }}>{row?.totalAmount || ""}</td>
                        </tr>

                    ))}
                    <tr>
                        <td style={{ textAlign: 'center', fontWeight: "bold", borderBottom: "none", borderRight: "none", borderLeft: "none" }} colSpan="8">
                            E.& O.E., (PARTY'S&ensp;MATERIAL&ensp;BEING&ensp;RETURNED&ensp;AFTER&ensp;PROCESSING&ensp;/&ensp;LABOUR ONLY)
                        </td>
                    </tr>
                    <tr>
                        <td style={{ borderBottom: "none", borderRight: "none", borderLeft: "none" }} colSpan="3">MODE OF TRANSPORT</td>
                        <td style={{ borderBottom: "none", borderRight: "none" }} rowSpan="2" colSpan="3">RECEIVER'S &ensp; SIGNATURE &ensp; AND&ensp;STAMP&ensp;WITH&ensp;DATE</td>
                        <td style={{ borderBottom: "none", borderRight: "none" }} rowSpan="2" colSpan="3">FOR&ensp;ULTRA&ensp;SURFACE &ensp; FINISHERS</td>
                    </tr>
                    <tr>
                        <td style={{ borderBottom: "none", borderRight: "none", borderLeft: "none" }} colSpan="3">VEHICLE &ensp;NO: {outwardList?.vehicleNo}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ReportTemplate;
