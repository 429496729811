import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  getData,
  postData,
  deleteData,
  patchData,
} from "src/webService/webService"; // Assuming deleteData function is exported
import PurchaseInventoryAPI from "src/API_Routes/PurchaseInventoryAPI/PurchaseInventoryAPI";
import { toast, ToastContainer } from "react-toastify";
import Iconify from "../iconify";
import PurchaseInventoryFilter from "../filter/purchaseinventoryfilter";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import purchaseLogo from "../logo/physical-Inventory.webp";
import purchasePriceLogo from "../logo/purchaseIcon.png";

function PurchaseInventory() {
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [units, setUnits] = useState("");
  const [purchaseInventory, setPurchaseInventory] = useState([]);
  const [AllPurchaseInventorylist, setAllPurchaseInventorylist] = useState([]);
  const [open, setOpen] = useState(false);
  const [TotalItems, setTotalItems] = useState();
  const [TotalPurchaseAmount, setTotalpurchaseAmount] = useState(0);
  const [action, setAction] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [specificData, setSpecificData] = useState();

  //For Search Filter
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchdescription, setsearchdescription] = useState();
  const [searchcompanyname, setsearchcompanyname] = useState();
  const [reset, setReset] = useState(false);
  //For Search Filter

  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  console.log(specificData, "specificData");

  const accessToken = sessionStorage.getItem("Token");

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      companyName,
      description,
      quantity,
      rate,
      amount,
      units,
      date,
    };
    if (action === "Edit") {
      patchData(`purchaseInventory/${specificData._id}`, params, accessToken)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            handleClose();
            AllpurchaseInventoryList(currentPage); // Refresh data after successful submission
            setCompanyName("");
            setQuantity("");
            setDescription("");
            setDate("");
            setUnits("");
            setAmount("");
            setRate("");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postData(PurchaseInventoryAPI.POSTPURCHASE, params, accessToken)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            handleClose();
            AllpurchaseInventoryList(currentPage); // Refresh data after successful submission
            setCompanyName("");
            setQuantity("");
            setDescription("");
            setDate("");
            setUnits("");
            setAmount("");
            setRate("");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const AllpurchaseInventoryList = async (page) => {
    if (startDate && !endDate) {
      toast.error("End date is required when start date is selected");
      return;
    }
    setReset(false);
    try {
      const params = {
        page: page,
        limit: limit,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
        description: searchdescription ? searchdescription : "",
        companyName: searchcompanyname ? searchcompanyname : "",
      };
      const res = await getData(
        PurchaseInventoryAPI.GETPURCHASE,
        params,
        accessToken
      );
      if (res.status === 200) {
        setTotalPages(res.data[0].totalPages);
        setPurchaseInventory(res.data[0].paginatedResults);
        setTotalpurchaseAmount(
          res?.data[0]?.totalAmount ? res?.data[0]?.totalAmount : "0"
        );
        if (res) {
          setTotalItems(res.data[0].totalCount.totalItems);
        } else {
          setTotalItems(0);
        }

        setAllPurchaseInventorylist(res.data[0].results); // Assuming this is where all data should be stored
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    AllpurchaseInventoryList(currentPage);
  }, [currentPage, limit]);

  const handleDelete = async (id) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        await deleteData(`purchaseInventory/${id}`, accessToken);
        AllpurchaseInventoryList(currentPage);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to delete purchase");
      }
    }
  };

  const handleClickOpen = (row, action) => {
    setOpen(true);
    setSpecificData(row);
    setAction(action);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnitchanges = (event) => {
    setUnits(event.target.value);
  };

  // index based on currentPage
  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  const handleSearch = () => {
    setCurrentPage(1);
    AllpurchaseInventoryList(1);
  };

  const removeData = () => {
    setStartDate();
    setEndDate();
    setsearchdescription();
    setsearchcompanyname();
    setReset(true);
  };

  useEffect(() => {
    if (reset) {
      setCurrentPage(1);
      AllpurchaseInventoryList(1);
    }
  }, [reset]);

  useEffect(() => {
    if (action === "Edit") {
      if (specificData) {
        setCompanyName(specificData?.companyName);
        setQuantity(specificData?.quantity);
        setDescription(specificData?.description);
        setDate(specificData?.date?.split("T")[0]);
        setUnits(specificData?.units);
        setAmount(specificData?.amount);
        setRate(specificData?.rate);
      }
    } else {
      setCompanyName("");
      setQuantity("");
      setDescription("");
      setDate("");
      setUnits("");
      setAmount("");
      setRate("");
    }
  }, [action, specificData]);

  useEffect(() => {
    // Calculate the amount whenever quantity or rate changes
    const calculatedAmount =
      quantity && rate
        ? (parseFloat(quantity) * parseFloat(rate)).toFixed(2)
        : "";
    setAmount(calculatedAmount);
  }, [quantity, rate]);

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />
      <div className="d-flex align-items-center justify-content-center pt-3 pb-4">
        <div className="d-flex col-6">
          <img src={purchaseLogo} width="7%" className="mr-2" />
          <p className="fw-bold mb-0 me-4 mt-2 color-dark">
            Total Purchase Inventory : {TotalItems}
          </p>
          <img src={purchasePriceLogo} width="5%" className="mr-2" />
          <p className="fw-bold mb-0 mt-2 color-dark">
            Total Purchase Amount : {TotalPurchaseAmount}
          </p>
        </div>

        <div className="d-flex align-items-center col-6 justify-content-end">
          {showFilter && (
            <span
              className="me-2 danger"
              style={{ color: "red", fontSize: "13px", cursor: "pointer" }}
              onClick={(e) => {
                toggleFilter();
                removeData();
              }}
            >
              Clear Filter
            </span>
          )}
          <IconButton
            className="p-12 ftrbkgd rounded"
            onClick={(e) => {
              toggleFilter();
              removeData();
            }}
          >
            {showFilter ? (
              <Iconify icon="ri:filter-off-fill" className="color-dark" />
            ) : (
              <Iconify
                icon="material-symbols:filter-alt-sharp"
                className="color-dark"
              />
            )}
          </IconButton>
          <Button
            variant="contained"
            size="small"
            className="ml-8 btn-primary maxWidth"
            onClick={handleClickOpen}
          >
            + ADD PURCHASE
          </Button>
        </div>
      </div>
      <div>
        {showFilter && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div style={{ minWidth: 500, margin: 1 }}>
                <Grid
                  container
                  spacing={2}
                  className="d-flex flex-warp justify-content-between align-items-center"
                >
                  <TextField
                    type="date"
                    className="mx-1"
                    label="Purchase Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type="date"
                    className="mx-1"
                    label="Purchase End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: startDate,
                    }}
                  />
                  <TextField
                    className="my-0 mx-1"
                    label="Search Company Name"
                    value={searchcompanyname}
                    onChange={(e) => setsearchcompanyname(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                  <TextField
                    className="my-0 mx-1"
                    label="Search Description"
                    value={searchdescription}
                    onChange={(e) => setsearchdescription(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />

                  <Button
                    variant="outlined"
                    className="mx-1 btn-secondary"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>
              </div>
            </div>
          </>
        )}
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>Purchase Inventory</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4 px-1 my-2">
                <TextField
                  id="companyName"
                  label="Company Name"
                  className="w-100"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  variant="outlined"
                  sx={{ borderRadius: "25px" }}
                />
              </div>
              <div className="col-4 px-1 my-2">
                <TextField
                  className="w-100"
                  type="date"
                  name="date"
                  label="Date"
                  value={date}
                  min={getTodayDate()}
                  size="medium"
                  onChange={(event) => setDate(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-4 px-1 my-2">
                <TextField
                  id="description"
                  label="Description"
                  className="w-100"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  sx={{ borderRadius: "30px" }}
                />
              </div>
              <div className="col-4 px-1 my-2">
                <TextField
                  label="Quantity"
                  className="w-100"
                  id="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  variant="outlined"
                  sx={{ borderRadius: "55px" }}
                />
              </div>
              <div className="col-4 px-1 my-2">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="units-label">Units</InputLabel>
                  <Select
                    labelId="units-label"
                    id="units"
                    value={units}
                    onChange={(event) => handleUnitchanges(event)}
                    label="Units"
                  >
                    <MenuItem value="No's">No's</MenuItem>
                    <MenuItem value="Kg">Kg</MenuItem>
                    <MenuItem value="ltr">ltr</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-4 px-1 my-2">
                <TextField
                  className="w-100"
                  label="Rate"
                  id="rate"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  variant="outlined"
                  sx={{ borderRadius: "75px" }}
                />
              </div>
              <div className="col-4 px-1 my-2">
                <TextField
                  className="w-100"
                  label="Amount"
                  id="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="outlined"
                  sx={{ borderRadius: "75px" }}
                  InputProps={{ readOnly: true }}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
              className="mt-3"
            >
              <Button
                variant="outlined"
                className="btn-secondary"
                onClick={handleClose}
              >
                CLOSE
              </Button>
              <Button
                style={{ width: "133px" }}
                type="submit"
                variant="contained"
                className="ml-8 btn-primary"
              >
                ADD
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <TableContainer style={{ height: "320px", overflowY: "scroll" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="table-cl color-dark">S.No</TableCell>
              <TableCell className="table-cl color-dark">
                Company Name
              </TableCell>
              <TableCell className="table-cl color-dark">Date</TableCell>
              <TableCell className="table-cl color-dark">Description</TableCell>
              <TableCell className="table-cl color-dark">Quantity</TableCell>
              <TableCell className="table-cl color-dark">Units</TableCell>
              <TableCell className="table-cl color-dark">Rate</TableCell>
              <TableCell className="table-cl color-dark">Amount</TableCell>
              <TableCell className="table-cl color-dark text-center">
                Edit / Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseInventory?.map((row, index) => (
              <TableRow key={index}>
                <TableCell> {calculateIndex(index)}</TableCell>
                <TableCell>{row.companyName}</TableCell>
                <TableCell>
                  {row.date?.split("T")[0]?.split("-")?.reverse()?.join("-")}
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{row.units}</TableCell>
                <TableCell>{row.rate}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell align="center">
                  <Iconify
                    icon={"eva:edit-fill"}
                    sx={{ mr: 2 }}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClickOpen(row, "Edit")}
                  />
                  <Iconify
                    icon={"eva:trash-2-outline"}
                    sx={{ mr: 2 }}
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => handleDelete(row._id)}
                  />
                </TableCell>
              </TableRow>
            ))}

            {purchaseInventory?.length === 0 && (
              <TableRow>
                <TableCell colSpan={9} className="text-center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="d-flex flex-wrap justify-content-end m-3">
        <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
          <Select
            labelId="limit-label"
            id="limit-select"
            value={limit}
            onChange={handleLimitChange}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={TotalItems}>All</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handlePrevPage} disabled={currentPage === 1}>
          <Iconify icon={"grommet-icons:previous"} />
        </Button>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <Iconify icon={"grommet-icons:next"} />
        </Button>
      </div>
    </Paper>
  );
}

export default PurchaseInventory;
