import { createSlice } from "@reduxjs/toolkit";

const ALLCLIENTLIST = createSlice({
  name: "ALLCLIENTLIST",
  initialState: {
    AllClientsList: "",
    specificClientsList: ""
  },
  reducers: {
    setALLCLIENTSLIST: (state, action) => {
      state.AllClientsList = action.payload;
    },
    setSpecificClientsDetails: (state, action) => {
      state.specificClientsList = action.payload;
    },
  },
});

export const { setALLCLIENTSLIST, setSpecificClientsDetails } = ALLCLIENTLIST.actions;
export default ALLCLIENTLIST.reducer;
