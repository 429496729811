import React, { useEffect, useState } from "react";
import "../AllClients/AllClients.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Iconify from "../iconify";
import AddBiller from "./BillerField";
import BILLERAPI from "src/API_Routes/BillerAPI/BillerAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  setALLBILLERLIST,
  setSpecificBillerDetails,
} from "src/redux/Slices/AllBillerSlices";
import { deleteData, getData } from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import userLogo from "../logo/programmer.png"

const Billertable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [TotalItems, setTotalItems] = useState();
  const [action, setAction] = useState("");
  const [addClient, setAddClient] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const accessToken = sessionStorage.getItem("Token");
  const ALLBILLERLIST = useSelector(
    (state) => state?.ALLBILLERLIST?.AllBillerList?.results
  );
  const [selectedId, setSelectedId] = useState();
  const [sNo, setSNo] = useState([]);
  console.log(action, "action");

  console.log(sNo, "sno");

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const updateSNo = (newValues) => {
    setSNo((prevSNo) => [...prevSNo, ...newValues]);
  };

  const AllBillerList = async (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(BILLERAPI.GETBILLER, params, accessToken);
      if (res.status === 200) {
        dispatch(setALLBILLERLIST(res.data));
        setTotalPages(res.data.totalPages);
        if (res?.data?.totalItems) {
          setTotalItems(res.data.totalItems);
        } else {
          setTotalItems(0);
        }

        console.log(ALLBILLERLIST, "Allbillerlist");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteBiller = async (userId) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      await deleteData(`users/user/${userId}`, accessToken)
        .then((res) => {
          setOpen(null);
          AllBillerList(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    AllBillerList(currentPage);
  }, [currentPage, limit, addClient]);

  const specificBillerData = async (userId) => {
    try {
      const res = await getData(`users/user/${userId}`, null, accessToken);
      if (res.status === 200) {
        dispatch(setSpecificBillerDetails(res.data));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    // specificBillerData();
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const HandleAddClient = () => {
    setAddClient(!addClient);
  };

  // index based on currentPage
  const calculateIndex = (index) => {
    return (currentPage - 1) * limit + index + 1;
  };

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />

      {addClient ? (
        <>
          <div className="pt-3 pb-4 d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <img src={userLogo} width="5%" className="mr-2" />
              <p className="fw-bold mb-0 color-dark mt-1">Total Users : {TotalItems}</p>
            </div>
            <Button
              variant="contained"
              size="small"
              className="ml-8 btn-primary cursor-pointer"
              onClick={() => {
                setOpen(null);
                setAction("Add");
                HandleAddClient();
              }}
            >
              + ADD USER
            </Button>
          </div>
          <TableContainer style={{ height: "361px", overflowY: "scroll" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table-cl color-dark text-center">
                    S.No
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    First Name
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Email ID
                  </TableCell>
                  <TableCell className="table-cl color-dark">
                    Mobile No
                  </TableCell>
                  <TableCell className="table-cl color-dark">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ALLBILLERLIST &&
                  ALLBILLERLIST.map((row, index) => (
                    <TableRow key={index} className="padding-8">
                      <TableCell className="text-center">
                        {calculateIndex(index)}
                      </TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {row.phoneNumber || row.mobileNumber}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(event) => handleOpenMenu(event, row._id)}
                        >
                          <Iconify icon={"eva:more-vertical-fill"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {!ALLBILLERLIST && (
                  <TableRow>
                    <TableCell colSpan={5} className="text-center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small" style={{width: "5%"}}>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={TotalItems}>All</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 160,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                specificBillerData(selectedId);
                setOpen(null);
                HandleAddClient();
                setAction("Edit");
              }}
            >
              <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
              Edit User
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(null);
                setAction("View");
                HandleAddClient();
                specificBillerData(selectedId);
              }}
            >
              <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
              View User
            </MenuItem>
            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                setOpen(null);
                deleteBiller(selectedId);
              }}
            >
              <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
              Delete User
            </MenuItem>
          </Popover>
        </>
      ) : (
        <AddBiller
          HandleAddClient={HandleAddClient}
          action={action}
          AllBillerList={AllBillerList}
          setTotalItems={setTotalItems}
        />
      )}
    </Paper>
  );
};

export default Billertable;
