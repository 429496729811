import React, { useEffect, useState } from 'react';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, DialogTitle, FormControl, Select, MenuItem, DialogContent, Typography } from '@mui/material';
import AddInvoice from '../Invoice/AddInvoice';
import INVOICEAPI from 'src/API_Routes/InvoiceAPI/InvoiceAPI';
import { getData } from 'src/webService/webService';

function MultipleInvoice({ HandleOutward, basicClientDetail, outwardList, billId, }) {
    const accessToken = sessionStorage.getItem("Token");
    const [rows, setRows] = useState([]);
    const [partNames, setPartNames] = useState([]);
    const [selectedPartName, setSelectedPartName] = useState('all');
    const [addInvoiceOpen, setAddInvoiceOpen] = useState(false);
    const [companyname, setCompanyName] = useState('');
    const [doorno, setDoorNo] = useState('');
    const [streetname, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [vendorcode, setVendorCode] = useState();
    const [gstinno, setGstinNo] = useState('');
    const [updatedOutwardList, setUpdatedOutwardList] = useState(outwardList);

    const [orderno, setOrderNo] = useState(outwardList?.yourOrderNo);
    const [invoice, setInvoice] = useState();
    const [date, setDate] = useState(outwardList?.date?.split('T')[0]);
    const [saccode, setSacCode] = useState('998898');
    const [count, setCount] = useState();
    const [invoiceNo, setInvoiceNo] = useState();

    const data = {
        companyname,
        doorno,
        streetname,
        city,
        pincode,
        gstinno,
        orderno,
        date,
        vendorcode,
        invoice,
    };

    useEffect(() => {
        const partnames = outwardList.flatMap(outwardData =>
            outwardData.outwardDeliveryNoteChallan.flatMap(outwardDc =>
                outwardDc.outwardTable.map(part => `${part.partName}/${part.partNo}/${part.description}`)
            )
        );
        setPartNames(Array.from(new Set(partnames)));
    }, []);

    useEffect(() => {
        if (selectedPartName != 'all') {
            const newList = outwardList.map(outwardData => ({
                ...outwardData,
                outwardDeliveryNoteChallan: outwardData.outwardDeliveryNoteChallan.map(
                    outwardDc => ({
                        ...outwardDc,
                        outwardTable: outwardDc.outwardTable.filter(part =>
                            `${part.partName}/${part.partNo}/${part.description}` === selectedPartName
                        )
                    }))
            }));
            setUpdatedOutwardList(newList);
        } else {
            setUpdatedOutwardList(outwardList);
        }
    }, [selectedPartName]);

    const handleAddInvoice = () => {
        const allOutwardTables = updatedOutwardList.flatMap(outwardData =>
            outwardData.outwardDeliveryNoteChallan.flatMap(data =>
                data.outwardTable.map(tableItem => ({
                    ...tableItem,
                    outwardId: outwardData._id,
                    orderNo: data.yourOrderNo,
                    usfDCNo: data.yourDcNo,
                    usfDcDate: data.date,
                    customerDCNo: data.dcNo
                }))
            )
        );
        setRows(allOutwardTables);
        setAddInvoiceOpen(true);
    };

    const handleCloseInvoiceOpen = () => {
        setAddInvoiceOpen(false);
    };

    const AllInvoiceList = async (page) => {
        try {
            const params = {
                page: 1,
                limit: 5,
            };
            const res = await getData(INVOICEAPI.GETALLINVOICE, params, accessToken);
            if (res.status === 200) {
                if (res?.data?.[0]?.totalCount?.totalItems) {
                    setCount(res.data?.[0]?.totalCount?.totalItems);
                } else {
                    setCount(1);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        AllInvoiceList();
    }, []);

    useEffect(() => {
        if (count) {
            console.log(count, "count");
            if (count < 1) {
                setInvoiceNo(1)
            }
            else {
                setInvoiceNo(count + 1);
            }
        }
    }, [count]);

    return (
        <div className='p-5 pt-0'>
            <div>
                <div className="app">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="caption" >Company</Typography>
                            <p className="mb-0" >{basicClientDetail?.companyname}</p>
                            <Typography variant="caption" >{basicClientDetail?.doorNo}, {basicClientDetail?.streetName}, {basicClientDetail?.city}, {basicClientDetail?.pinCode}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" >GSTIN No</Typography>
                            <p className="mb-0" >{basicClientDetail?.gstNo}</p>
                            <Typography variant="caption" >SAC Code</Typography>
                            <p className="mb-0" >{saccode}</p>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" >Filter by Part Name / Part No / Process</Typography>
                            <FormControl
                                sx={{ width: "100%" }}
                                size="large"
                            >
                                <Select
                                    labelId="process-details"
                                    id="process-details"
                                    fullWidth
                                    variant="outlined"
                                    name="partName"
                                    className="w-100"
                                    value={selectedPartName}
                                    onChange={(e) => setSelectedPartName(e.target.value)}
                                >
                                    <MenuItem key={'all'} value={'all'}>All</MenuItem>
                                    {partNames?.map((part, i) => (
                                        <MenuItem key={i} value={part}>{part}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>

            {updatedOutwardList.map((outwardData, outwardIndex) =>
                (outwardData.outwardDeliveryNoteChallan[0].outwardTable.length > 0) ?
                    <Table className="ml-0" key={outwardIndex}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-cl'>S.NO</TableCell>
                                <TableCell className='table-cl'>USF DCNO</TableCell>
                                <TableCell className='table-cl'>Date</TableCell>
                                <TableCell className='table-cl'>Part Name</TableCell>
                                <TableCell className='table-cl'>Part No</TableCell>
                                <TableCell className='table-cl'>Process</TableCell>
                                <TableCell className='table-cl'>Customer DCNO</TableCell>
                                <TableCell className='table-cl'>Quantity</TableCell>
                                <TableCell className='table-cl'>Outward Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {outwardData.outwardDeliveryNoteChallan[0].outwardTable.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{outwardData.outwardDeliveryNoteChallan[0].yourDcNo}</TableCell>
                                    <TableCell>{outwardData.outwardDeliveryNoteChallan[0]?.date?.split('T')[0]}</TableCell>
                                    <TableCell>{row.partName}</TableCell>
                                    <TableCell>{row.partNo}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{outwardData.outwardDeliveryNoteChallan[0].dcNo}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
                                    <TableCell>{row.outwardQuantity}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table> : <></>
            )}

            <div className='d-flex justify-content-end mt-3'>
                <div>
                    <Button variant="outlined" onClick={HandleOutward} className='btn-secondary'>
                        CANCEL
                    </Button>
                    <Button variant="contained" className='ml-8 btn-primary' onClick={handleAddInvoice}>
                        GENERATE INVOICE
                    </Button>
                </div>
            </div>

            <Dialog fullWidth open={addInvoiceOpen} onClose={() => setAddInvoiceOpen(false)} maxWidth={"md"}>
                <DialogTitle>New Invoice</DialogTitle>
                <DialogContent>
                    <AddInvoice
                        basicClientDetail={basicClientDetail}
                        outwardTable={rows}
                        outwardList={updatedOutwardList}
                        invData={data}
                        billId={billId}
                        handleCloseInvoiceOpen={handleCloseInvoiceOpen}
                        HandleOutward={HandleOutward}
                        invoiceNo={invoiceNo}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default MultipleInvoice;