import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const ReConfirmationDialog = async () => {
  const result = await MySwal.fire({
    title: "Are you sure you want to add this data?",
    text: "Once added, it will be not possible to edit or delete",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  });

  return result.isConfirmed;
};
