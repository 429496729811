import { createSlice } from "@reduxjs/toolkit";

const ALLOUTWARDLIST = createSlice({
  name: "ALLOUTWARDLIST",
  initialState: {
    AllOutwardList: "",
  },
  reducers: {
    setALLOUTWARDLIST: (state, action) => {
      state.AllOutwardList = action.payload;
    },
  },
});

export const { setALLOUTWARDLIST } = ALLOUTWARDLIST.actions;
export default ALLOUTWARDLIST.reducer;
